export default {
  lang1: 'Đăng nhập',
  lang2: 'Bạn cũng có thể sử dụng',
  lang3: 'Email',
  lang4: 'Điện thoại di động',
  lang5: 'Điện thoại',
  lang6: 'Vui lòng nhập số điện thoại di động của bạn',
  lang7: 'Email',
  lang8: 'Vui lòng nhập tài khoản email của bạn',
  lang9: 'Mật khẩu đăng nhập',
  lang9a: 'Vui lòng nhập mật khẩu đăng nhập',
  lang10: 'Quên mật khẩu',
  lang11: 'Đăng ký ngay',
  lang12: 'Tìm kiếm quốc gia/khu vực',
  lang13: 'Khu vực thường sử dụng',
  lang14: 'Email',
  lang15: 'Điện thoại di động',
  lang16: 'Đặt mật khẩu đăng nhập',
  lang17: '6-20 ký tự, bao gồm chữ cái và số',
  lang18: 'Xác nhận mật khẩu đăng nhập',
  lang19: 'Vui lòng nhập lại mật khẩu',
  lang20: 'Mã',
  lang21: 'Vui lòng nhập mã xác minh',
  lang22: 'Gửi',
  lang23: 'Mã mời (tùy chọn)',
  lang23a: 'Vui lòng nhập mã mời',
  lang24: 'Tôi đã đọc và đồng ý',
  lang25: 'Thỏa thuận người dùng',
  lang26: 'Chính sách bảo mật',
  lang27: 'Vui lòng điền địa chỉ email chính xác',
  lang28: 'Vui lòng điền mật khẩu đăng nhập gồm 6-20 chữ số và chữ cái',
  lang29: 'Hai mật khẩu nhập không khớp',
  lang30: 'Tải xuống Android',
  lang31: 'Tải xuống iOS',
  lang32: 'Sau khi lấy lại mật khẩu, tiền tệ không thể rút trong vòng 24 giờ',
  lang33: 'Đặt lại mật khẩu',
  lang34: 'Email',
  lang35: 'Điện thoại di động',
  lang36: 'Nhấp để đăng nhập',
  lang37: 'Chào mừng đến với FLASHOP',
  lang38: 'Chào mừng,',
  lang39: 'Xác thực danh tính',
  lang40: 'Đã xác thực',
  lang41: 'Đi đến xác thực',
  lang42: 'Xem xét chứng nhận',
  lang43: 'Xác thực không thành công',
  lang44: 'Thông tin nhận dạng',
  lang45: 'Họ tên',
  lang46: 'Vui lòng nhập tên của bạn',
  lang47: 'số CMND',
  lang48: 'Vui lòng nhập số ID',
  lang49: 'Tải lên hình ảnh ID (mặt trước và mặt sau)',
  lang50: 'Xác thực thành công',
  lang51: 'Vui lòng tải lên hình ảnh ID',
  lang52: 'Trung tâm bảo mật',
  lang53: 'mật khẩu đăng nhập',
  lang54: 'Mật khẩu quỹ',
  lang55: 'Chỉnh sửa',
  lang56: 'Cài đặt',
  lang57: 'Sau khi thay đổi mật khẩu, việc rút tiền và gửi tiền fiat bị cấm trong vòng 24 giờ',
  lang58: 'Mật khẩu cũ',
  lang59: 'Vui lòng nhập mật khẩu cũ',
  lang60: 'Mật khẩu mới',
  lang61: 'Vui lòng nhập mật khẩu mới',
  lang62: 'Xác nhận mật khẩu',
  lang63: 'Vui lòng nhập mật khẩu xác nhận',
  lang64: 'Mật khẩu phải từ 6-20 số và chữ cái',
  lang65: 'Quên mật khẩu?',
  lang66: 'Vui lòng điền mật khẩu cũ gồm 6-20 chữ số và chữ cái!',
  lang67: 'Vui lòng điền mật khẩu mới gồm 6-20 chữ số và chữ cái!',
  lang68: 'Email',
  lang69: 'SMS',
  lang70: 'Vui lòng nhập mật khẩu quỹ',
  lang71: 'Xác nhận mật khẩu quỹ',
  lang72: 'Vui lòng điền mật khẩu quỹ gồm 6-20 chữ số và chữ cái!',
  lang73: 'Liên kết',
  lang74: 'Thay đổi số điện thoại di động',
  lang75: 'Liên kết số điện thoại di động',
  lang76: 'Đặt số điện thoại',
  lang77: 'Vui lòng nhập số điện thoại bạn muốn liên kết và xác minh số',
  lang78: 'Để bảo vệ an ninh tài khoản, bạn cần xác minh tài khoản của mình',
  lang79: 'Gửi mã xác minh đến {tel} để xác minh an ninh',
  lang80: 'Xác minh an ninh tài khoản',
  lang81: 'Tiếp theo',
  lang82: 'Thay đổi email',
  lang83: 'Liên kết email',
  lang84: 'Đặt email liên kết tài khoản',
  lang85: 'Vui lòng nhập địa chỉ email bạn muốn liên kết và xác minh địa chỉ email',
  lang86: 'Nhóm của tôi',
  lang87: 'Tổng số thành viên trong nhóm',
  lang88: 'Số lượng người được mời',
  lang89: 'Danh sách nhóm',
  lang90: 'ID người dùng',
  lang91: 'Thời gian mời',
  lang92: 'thời gian',
  lang93: 'Doanh thu',
  lang94: 'Chi tiết doanh thu',
  lang95: 'Mời bạn bè',
  lang96: 'Giới thiệu một người bạn đến FLASHOP',
  lang97: 'Tối đa 10% hoàn tiền phí giao dịch',
  lang98: 'Mời bạn bè đăng ký cùng nhau',
  lang99: 'Gửi lời mời',
  lang100: 'Cho bạn bè',
  lang101: 'Đăng ký bạn bè',
  lang102: 'Thực hiện giao dịch',
  lang103: 'Nhận tương ứng',
  lang104: 'Hoàn tiền tỷ lệ',
  lang105: 'Mã của tôi',
  lang106: 'Liên kết của tôi',
  lang107: 'Xem Poster',
  lang108: 'Quy tắc thưởng',
  lang109: '1. Người dùng hoàn thành đăng ký bằng cách chia sẻ mã khuyến mãi hoặc liên kết khuyến mãi. Mỗi khi một người bạn tạo ra một giao dịch tiền tệ thực, giao dịch ETF và giao dịch hợp đồng, người chia sẻ có thể nhận được tỷ lệ tương ứng của phần thưởng phí xử lý. Từ ngày 18 tháng 12 năm 2021 trở đi, những người bạn mời bạn bè mở tài khoản thông qua người chia sẻ cũng có thể tận hưởng phần thưởng hoa hồng 10% riêng.',
  lang110: "2. Tỷ lệ thưởng: tỷ lệ thưởng giao dịch tiền tệ, ETF, hợp đồng: Tỷ lệ thưởng liên quan đến số lượng CTT Token mà người chia sẻ nắm giữ hàng ngày/trung bình. Các quy tắc cụ thể như sau:",
  lang111: '(1) CTT Token ít hơn 500, tỷ lệ thưởng là 10%',
  lang112: '(2) Nắm giữ 500-5000 CTT Token, tỷ lệ thưởng là 20%',
  lang113: '(3) Nếu nắm giữ hơn 5.000 CTT Token, tỷ lệ thưởng là 30%',
  lang114: '(4) Đối tác toàn cầu FLASHOP, vui lòng liên hệ nhân viên nền tảng để nhận phần thưởng cao hơn.',
  lang115: '3. Hình thức thưởng: Phần thưởng thực tế mà người chia sẻ nhận được sẽ được hoàn trả bằng đồng tiền thực tế mà bạn bè sử dụng để thanh toán phí giao dịch. Ví dụ: khi bạn bè sử dụng ETH để thanh toán phí giao dịch, phần thưởng thực tế mà người chia sẻ nhận được sẽ được hoàn trả bằng ETH. Khi phí giao dịch của bạn bè được thanh toán bằng khấu trừ CTT, phần thưởng thực tế mà người chia sẻ và bạn bè nhận được sẽ được hoàn trả bằng CTT.',
  lang116: 'Lưu hình ảnh',
  lang117: 'Lưu hình ảnh thành công',
  lang118: 'Lưu hình ảnh thất bại',
  lang119: 'Trung tâm trợ giúp',
  lang120: 'Đội ngũ sản phẩm FLASHOP',
  lang121: 'Chào mừng trao đổi với chúng tôi, mọi phản hồi của bạn sẽ được đánh giá cao',
  lang122: 'Chi tiết vấn đề',
  lang123: 'Gửi phiếu hỗ trợ',
  lang124: 'Tiêu đề phiếu hỗ trợ',
  lang125: 'Vui lòng nhập tiêu đề của phiếu hỗ trợ',
  lang126: 'Nội dung phiếu hỗ trợ',
  lang127: 'Vui lòng mô tả chi tiết vấn đề của bạn, điều này sẽ giúp chúng tôi hỗ trợ bạn hiệu quả hơn!',
  lang128: 'Danh sách phiếu hỗ trợ',
  lang129: 'Trả lời:',
  lang130: 'Đang xử lý, vui lòng kiên nhẫn...',
  lang131: 'Chưa có phiếu hỗ trợ',
  lang132: 'Đang chờ',
  lang133: 'Đã phản hồi',
  lang134: 'Đã đóng',
  lang135: 'Kích thước hình ảnh không được vượt quá 5MB',
  lang136: 'Vui lòng nhập nội dung phiếu hỗ trợ',
  lang137: 'Số từ trong phiếu hỗ trợ phải từ 10-200',
  lang138: 'Về chúng tôi',
  lang139: 'Nền tảng giao dịch FLASHOP',
  lang140: 'Điện thoại dịch vụ khách hàng',
  lang141: 'Twitter chính thức',
  lang142: 'Telegram chính thức',
  lang143: 'Hộp thư công ty',
  lang144: 'Cài đặt',
  lang145: 'Ngôn ngữ',
  lang146: 'Phiên bản',
  lang147: 'Đăng xuất',
  lang148: 'Bạn có chắc chắn muốn đăng xuất không?',
  lang149: 'Hàng đầu thế giới',
  lang150: 'Nền tảng giao dịch tài sản kỹ thuật số',
  lang151: 'Chào mừng tải xuống FLASHOP',
  lang152: 'Đăng ký',
  lang153: 'Sàn giao dịch tiền điện tử đa chức năng và dân chủ. Là người đầu tiên trên thị trường, chúng tôi mang lại lợi ích tài chính cho người dùng tích cực. FLASHOP là một dự án toàn diện, có nghĩa là triển khai giải pháp trên một số lớp kinh doanh liên quan đến giải pháp tiền điện tử để làm cho việc sử dụng dễ dàng hơn cho người dùng của chúng tôi.',
  lang154: 'Tầm nhìn của chúng tôi',
  lang155: 'Chúng tôi tin tưởng vào việc ứng dụng rộng rãi công nghệ blockchain và lợi ích của tiền điện tử trong tương lai gần. Bằng cách giới thiệu các giải pháp toàn cầu không chỉ nhờ vào đổi mới công nghệ mà còn nhờ vào giáo dục, hệ sinh thái thế giới tiền điện tử trở nên thân thiện hơn với mọi người.',
  lang156: 'Tải xuống APP',
  lang157: 'Sách trắng',
  lang158: 'Tín dụng'
};