import "core-js/modules/es.object.to-string";
export default [{
  path: '/coinRecharge',
  name: 'coinRecharge',
  component: function component() {
    return import('@/views/wallet/coinRecharge');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/coinWithdraw',
  name: 'coinWithdraw',
  component: function component() {
    return import('@/views/wallet/coinWithdraw');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/coinTransfer',
  name: 'coinTransfer',
  component: function component() {
    return import('@/views/wallet/coinTransfer');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/coinConvert',
  name: 'coinConvert',
  component: function component() {
    return import('@/views/wallet/coinConvert');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/balanceRecord',
  name: 'balanceRecord',
  component: function component() {
    return import('@/views/wallet/balanceRecord');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/coinAddress',
  name: 'coinAddress',
  component: function component() {
    return import('@/views/wallet/coinAddress');
  },
  meta: {
    level: 4,
    requiresAuth: true
  }
}];