export default {
  lang1: 'Cancel',
  lang2: 'Submit',
  lang3: 'Confirm',
  lang4: 'No data yet',
  lang5: 'No record yet',
  lang6: 'Copy',
  lang7: 'Copy successfully',
  lang8: 'Copy failed',
  lang9: 'Confirm',
  lang10: 'Spot',
  lang11: 'Assets',
  lang12: 'Request failed! Please check network connection',
  lang13: 'Cycle',
  lang14: 'Trade',
  lang15: 'Market',
  lang16: 'Contact Customer Service!',
  "theme": "Theme skin"
};