export default {
  lang1: "Prelievo",
  lang2: "Dettagli account",
  lang3: "Registrazione",
  lang4: "ordini",
  lang5: "Livello di appartenenza",
  lang6: "Importo richiesto",
  lang7: "Commissione per ordine",
  lang8: "Numero di ordini",
  lang9: "Unisciti",
  lang10: "Saldo disponibile",
  lang11: "Saldo congelato",
  lang12: "In sospeso",
  lang13: "Pagato",
  lang14: "Regolato",
  lang15: "Nessun dato ancora",
  lang16: "Totale ordine",
  lang17: "commissione",
  lang18: "Invia ordine",
  lang19: "Numero ordine",
  lang20: "Rendimento del prodotto",
  lang21: "Saldo attuale",
  lang22: "Formula di calcolo",
  lang23: "Profitto da questo ordine",
  lang24: "invia",
  lang25: "Prendi l'ordine ora",
  lang26: "Ottieni commissione",
  lang27: "Compiti di oggi",
  lang28: "Completato oggi",
  lang29: "Procedura",
  lang30: "Fai clic sul pulsante \"Avvia attività\" e segui le istruzioni per completare l'attività.",
  lang31: "Dopo aver completato l'attività, puoi regolare la commissione sul saldo",
  lang32: "prezzo unitario",
  lang33: "Sconto",
  lang34: "Ordine in corso",
  lang35: "Annulla coda",
  lang36: "A causa del gran numero di utenti in competizione per gli ordini al livello attuale, il sistema sta lavorando duramente per assegnare gli ordini. Attualmente è classificato 11°. Si prega di attendere pazientemente.",
  lang37: "Suggerimento: l'aggiornamento del tuo livello VIP può aiutarti a ottenere ordini più velocemente",
  lang38: "Se hai domande o hai bisogno di aiuto, contatta il servizio clienti online",
  lang39: "Clicca qui per contattare il tuo servizio clienti esclusivo⭣⭣⭣",
  lang40: "FAQ",
  lang41: "Come ricaricare e quali sono i metodi di pagamento?",
  lang42: "Questo sistema è aperto a più paesi. Forniamo metodi di pagamento internazionali e supportiamo valute locali per diversi paesi.",
  lang43: "Come posso investire e guadagnare reddito?",
  lang44: "Puoi prendere ordini nel sistema e ottenere entrate stabili automatiche di posizionamento degli ordini e interessi ogni giorno. Tutti i guadagni possono essere prelevati sulla tua carta bancaria.",
  lang45: "Come registrarsi come membro?",
  lang46: "I nuovi membri vengono registrati su invito. Per garantire i benefici dei membri registrati ed evitare che la registrazione eccessiva riduca i benefici, i nuovi membri possono registrarsi solo su invito dei membri esistenti.",
  lang47: "C'è un limite sull'importo dell'acquisto?",
  lang48: "Sì, per consentire a più membri di avere l'opportunità di ottenere benefici, ogni account nel sistema ha un limite minimo di importo di acquisto.",
  lang49: "Cosa devo fare se riscontro problemi durante il funzionamento?",
  lang50: "Puoi contattare il servizio clienti online in qualsiasi momento per aiutarti a completare tutte le operazioni.",
  lang51: "Contatta il servizio clienti",
  lang52: "benvenuto",
  lang53: "ricarica",
  lang54: "Registri di deposito e prelievo",
  lang55: "Il mio team",
  lang56: "Invita amici",
  lang57: "Carta bancaria di prelievo",
  lang58: "Centro sicurezza",
  lang59: "impostare",
  lang60: "Nome della banca",
  lang61: "aiuto",
  lang62: "mio",
  lang63: "Cronologia ordini",
  lang64: "Servizio Clienti",
  lang65: "Lowe's",
  lang66: 'Attività di coppia',
  lang67: 'Collega coppia',
  lang68: 'A raggiunge importo ricarica',
  lang69: 'B raggiunge importo ricarica',
  lang70: 'Può essere richiesto',
  lang71: 'Partecipa',
  lang72: "Regole dell'attività",
  lang73: "Le coppie devono fornire l'ID dell'altro per il collegamento. Dopo un collegamento riuscito, entrambe le parti possono richiedere le ricompense corrispondenti sui propri account. Ogni account può essere collegato una sola volta e una volta collegato non può essere collegato di nuovo. Attraverso l'attività di collegamento delle coppie, puoi goderti le attività di ricompensa con il tuo partner e approfondire la vostra relazione reciproca. Vi auguriamo una piacevole esperienza nell'attività!",
  lang74: 'Descrizione del processo',
  lang75: "L'utente A inserisce l'ID dell'utente B, ottiene il consenso, il sistema verifica le informazioni di collegamento e controlla lo stato di collegamento di entrambi gli utenti, dopo che entrambe le parti sono d'accordo, il collegamento ha successo.",
  lang76: "Inserisci l'ID della coppia",
  lang77: 'Annulla',
  lang78: 'Conferma',
  lang79: 'Per sempre',
  lang80: 'Eternamente',
  lang81: 'Amo solo te',
  lang82: 'Mai separati',
  lang83: 'Insieme per sempre',
  lang84: 'Dettagli asset',
  lang85: 'Regole degli ordini',
  lang86: 'Introduzione alla piattaforma',
  lang87: 'Dettagli asset',
  lang88: 'Dettagli asset',
  lang89: 'Non hai ancora un account?',
  lang90: 'Numero di ordini acquisiti',
  lang91: 'Account',
  lang92: 'Risultati di oggi',
  lang93: 'Commissione',
  lang94: 'Regole operative della piattaforma',
  lang95: '1. Questa piattaforma utilizza un sistema di IA per un servizio più conveniente e veloce per commercianti e clienti degli ordini;',
  lang96: '2. Questa piattaforma serve oltre 100.000 commercianti e-commerce in tutto il mondo che collaborano con FLANNELS. Per aumentare l\'esposizione dei prodotti, i commercianti espongono i loro prodotti in vendita su questa piattaforma. Dopo aver completato l\'ordine, i commercianti rimborsano immediatamente l\'importo dell\'ordine e la commissione. La piattaforma abbina casualmente i clienti degli ordini con gli ordini dei commercianti. La commissione è correlata alla dimensione dell\'ordine e al livello VIP;',
  lang97: 'Il numero di ordini dipende dal livello VIP. Più alto è il livello VIP, più ordini e profitti più elevati;',
  lang98: '3. Il sistema della piattaforma determina il livello VIP in base al saldo del conto e aggiorna/declassa i clienti degli ordini;',
  lang99: '4. I clienti degli ordini possono completare la quantità di ordini del livello VIP corrispondente solo una volta al giorno. I clienti degli ordini possono prelevare denaro solo dopo aver completato la quantità di ordini del livello VIP corrispondente. Dopo aver completato l\'ordine giornaliero, se gli utenti vogliono continuare a guadagnare commissioni, possono aumentare il loro livello VIP o attendere il reset del sistema degli ordini il giorno successivo;',
  lang100: '5. Per motivare i clienti degli ordini, la piattaforma offre ordini fortunati che vengono abbinati casualmente;',
  lang101: 'Ogni cliente ha la possibilità di ricevere tre ordini premio, con il premio che va dal 20% al 35% dell\'importo dell\'ordine',
  lang102: '① Il primo ordine fortunato premia il 20% dell\'importo dell\'ordine.',
  lang103: '② Il secondo ordine fortunato premia il 30% dell\'importo dell\'ordine.',
  lang104: '③ Il terzo ordine fortunato premia il 35% dell\'importo dell\'ordine.',
  lang105: '6. Politica sulla privacy: La piattaforma FLANNELS rispetta la privacy di ogni cliente degli ordini e pone particolare enfasi sulla protezione della privacy dei clienti degli ordini;',
  lang106: 'La piattaforma FLANNELS si riserva il diritto di interpretazione finale, gli utenti sono tenuti a rispettare le regole',
  lang107: 'FLANNELS è stata fondata nel 1976 nel nord dell\'Inghilterra ed è ora uno dei player più entusiasmanti del settore. Il marchio ha ora oltre 50 negozi nel Regno Unito e vende abbigliamento prêt-à-porter, accessori, calzature e collezioni di giovani e bellezza appena lanciate. FLANNELS Beauty è stata lanciata nel 2021 e si impegna a rivoluzionare il retail della bellezza con il suo approccio "consumer first".',
  lang108: 'Dai FLANNELS Beauty Bars ai FLANNELS Beauty Changing Rooms, porta la bellezza di lusso nelle regioni con negozi all\'avanguardia ed esperienze di prima classe. Supportato dalla prossima generazione di leader del retail, il CEO entrante Michael Murray sta portando FLANNELS a un nuovo livello e portando la sua offerta senza pari nelle città regionali. Come Group Head of Elevation, Murray ha scosso il settore - "C\'è una domanda senza precedenti per il tipo di lusso che facciamo meglio.',
  lang109: 'FLANNELS continua a rivoluzionare e ripensare il retail, dimostrando in modo impressionante la nostra visione e fornendo destinazioni shopping di classe mondiale per le principali città britanniche." FLANNELS è un rivenditore super inclusivo e multi-categoria con marchi affermati ed emergenti nel lusso della moda e della bellezza, impegnato a far rivivere l\'esperienza in negozio e rendere il lusso accessibile a tutti.'
};