import "core-js/modules/es.array.for-each";
import "core-js/modules/es.function.name";
import "core-js/modules/web.dom-collections.for-each";
import { selectRate, myAccountCount, selectExchangeAssets, selectFuturesAssets, selectFaitAssets, rechargeCoins, ieoList } from '@api/wallet';
var state = {
  assetsCount: {},
  exchangeCoinList: [],
  exchangeAmount: {},
  futuresCoinList: [],
  futuresAmount: {},
  faitCoinList: [],
  faitAmount: {},
  rechargeCoinArr: [],
  ieoList: [],
  rateData: {}
};
var mutations = {
  ASSETS_COUNT: function ASSETS_COUNT(state, val) {
    state.assetsCount = val;
    state.assetsCount.usdt = Math.max(state.assetsCount.usdt, 0);
  },
  EXCHANGE_ASSETS: function EXCHANGE_ASSETS(state, val) {
    state.exchangeCoinList = val.assets || [];
    state.exchangeAmount = val.assets_amount || {};
    state.exchangeAmount.usdt = Math.max(state.exchangeAmount.usdt, 0);
  },
  FUTURES_ASSETS: function FUTURES_ASSETS(state, val) {
    val.contract_assets.forEach(function (data) {
      data.name = data.coin;
    });
    state.futuresCoinList = val.contract_assets;
    state.futuresAmount = val.contract_assets_amount || {};
    state.futuresAmount.usdt = Math.max(state.futuresAmount.usdt, 0);
  },
  FAIT_ASSETS: function FAIT_ASSETS(state, val) {
    val.c2c_assets.forEach(function (data) {
      data.name = data.coin;
    });
    state.faitCoinList = val.c2c_assets;
    state.faitAmount = val.c2c_assets_amount || {};
  },
  RECHARGE_COIN_ARR: function RECHARGE_COIN_ARR(state, val) {
    state.rechargeCoinArr = val;
  },
  IEO_LIST: function IEO_LIST(state, val) {
    state.ieoList = val;
  },
  RATE_DATA: function RATE_DATA(state, val) {
    state.rateData = val;
  }
};
var actions = {
  getAssetsCount: function getAssetsCount(_ref) {
    var commit = _ref.commit;
    myAccountCount().then(function (res) {
      commit('ASSETS_COUNT', res.data);
    });
  },
  getExchangeAssets: function getExchangeAssets(_ref2) {
    var commit = _ref2.commit;
    selectExchangeAssets().then(function (res) {
      commit('EXCHANGE_ASSETS', res.data);
    });
  },
  getFuturesAssets: function getFuturesAssets(_ref3) {
    var commit = _ref3.commit;
    selectFuturesAssets().then(function (res) {
      commit('FUTURES_ASSETS', res.data);
    });
  },
  getFaitAssets: function getFaitAssets(_ref4) {
    var commit = _ref4.commit;
    selectFaitAssets().then(function (res) {
      commit('FAIT_ASSETS', res.data);
    });
  },
  getRechargeCoins: function getRechargeCoins(_ref5) {
    var commit = _ref5.commit;

    if (!state.rechargeCoinArr.length) {
      // 判断空数组
      rechargeCoins().then(function (res) {
        commit('RECHARGE_COIN_ARR', res.data);
      });
    }
  },
  getIeoList: function getIeoList(_ref6) {
    var commit = _ref6.commit;
    ieoList().then(function (res) {
      commit('IEO_LIST', res.data);
    });
  },
  getRate: function getRate(_ref7, unit) {
    var commit = _ref7.commit;
    selectRate({
      coin: unit
    }).then(function (res) {
      commit('RATE_DATA', res.data);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};