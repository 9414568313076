import { post, fetch } from '@/service';
import store from '@/store';
var baseURL = '/api/';

var showLoading = function showLoading() {
  store.dispatch('common/showLoading', true);
};

export var minerInfo = function minerInfo() {
  return fetch("".concat(baseURL, "/miner/minerInfo"));
};
export var minManage = function minManage() {
  return fetch("".concat(baseURL, "/miner/minManage"));
};
export var addMinOrder = function addMinOrder(data) {
  showLoading();
  return post("".concat(baseURL, "/miner/addMinOrder"), data);
};
export var minOrder = function minOrder(data) {
  return fetch("".concat(baseURL, "/miner/minOrder"), data);
};
export var redeemOrder = function redeemOrder(data) {
  showLoading();
  return post("".concat(baseURL, "/miner/redeemOrder"), data);
};