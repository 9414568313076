import "core-js/modules/es.array.includes";
import "core-js/modules/es.string.includes";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import CN from './config/tw/index';
import TV from './config/tv/index';
import EN from './config/en/index';
import JP from './config/jp/index';
import KO from './config/ko/index';
import DE from './config/de/index';
import ES from './config/es/index';
import FR from './config/fr/index';
import IT from './config/it/index';
import PT from './config/pt/index';
import ID from './config/id/index'; // import ZH from './config/zh/index' // 切换到中文，请求头 local 不符合要求，需要后端配

var systemLangStr = localStorage.getItem('locale') || 'EN';

if (systemLangStr.includes('CN')) {
  localStorage.setItem('locale', 'CN');
} else if (systemLangStr.includes('TV')) {
  localStorage.setItem('locale', 'TV');
} else if (systemLangStr.includes('JP')) {
  localStorage.setItem('locale', 'JP');
} else if (systemLangStr.includes('KO')) {
  localStorage.setItem('locale', 'KO');
} else if (systemLangStr.includes('DE')) {
  localStorage.setItem('locale', 'DE');
} else if (systemLangStr.includes('ES')) {
  localStorage.setItem('locale', 'ES');
} else if (systemLangStr.includes('FR')) {
  localStorage.setItem('locale', 'FR');
} else if (systemLangStr.includes('IT')) {
  localStorage.setItem('locale', 'IT');
} else if (systemLangStr.includes('PT')) {
  localStorage.setItem('locale', 'PT');
} else if (systemLangStr.includes('ID')) {
  localStorage.setItem('locale', 'ID');
} else {
  localStorage.setItem('locale', 'EN');
}

var i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'EN',
  messages: {
    CN: CN,
    TV: TV,
    EN: EN,
    JP: JP,
    KO: KO,
    DE: DE,
    ES: ES,
    FR: FR,
    IT: IT,
    PT: PT,
    ID: ID // ZH

  }
});
export default i18n;