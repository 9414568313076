import { post, fetch } from '@/service';
import store from '@/store';
var baseURL = '/api/';

var showLoading = function showLoading() {
  store.dispatch('common/showLoading', true);
};

export var tradeOverview = function tradeOverview() {
  return fetch("".concat(baseURL, "/trad/overview"));
};
export var tradeSymbols = function tradeSymbols() {
  return fetch("".concat(baseURL, "/trad/symbols"));
};
export var tradeCoinInfo = function tradeCoinInfo(data) {
  return fetch("".concat(baseURL, "/trad/trad"), data);
};
export var currencyIntroduction = function currencyIntroduction(data) {
  return fetch("".concat(baseURL, "/trad/currencyIntroduction"), data);
};
export var tradAccount = function tradAccount(data) {
  return fetch("".concat(baseURL, "/trad/tradAccount"), data);
};
export var buyLimit = function buyLimit(data) {
  showLoading();
  return post("".concat(baseURL, "/trad/buyLimit"), data);
};
export var sellLimit = function sellLimit(data) {
  showLoading();
  return post("".concat(baseURL, "/trad/sellLimit"), data);
};
export var buyMarket = function buyMarket(data) {
  showLoading();
  return post("".concat(baseURL, "/trad/buyMarket"), data);
};
export var sellMarket = function sellMarket(data) {
  showLoading();
  return post("".concat(baseURL, "/trad/sellMarket"), data);
};
export var closeEntrust = function closeEntrust(data) {
  showLoading();
  return post("".concat(baseURL, "/trad/closeEntrust"), data);
};
export var entrustOrder = function entrustOrder(data) {
  return fetch("".concat(baseURL, "/trad/entrustOrder"), data);
};
export var entrustHistory = function entrustHistory(data) {
  return fetch("".concat(baseURL, "/trad/entrustHistory"), data);
};
export var tradEntrust = function tradEntrust(data) {
  return fetch("".concat(baseURL, "/trad/tradEntrust"), data);
};
export var tradHistory = function tradHistory(data) {
  return fetch("".concat(baseURL, "/trad/tradHistory"), data);
};
export var tradOrder = function tradOrder(data) {
  return fetch("".concat(baseURL, "/trad/tradOrder"), data);
};
export var tradeKline = function tradeKline(data) {
  return fetch("".concat(baseURL, "/trad/kline"), data);
};