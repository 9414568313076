export default {
  lang1: 'Startseite',
  lang2: 'Neues IEO-Währungsabonnement',
  lang3: 'Sammeltoken werden täglich freigegeben',
  lang4: 'Verdeckt',
  lang5: 'Nicht geöffnet',
  lang6: 'Beendet',
  lang7: 'Preis',
  lang8: 'Startzeit',
  lang9: 'Gesamt ausgegeben',
  lang10: 'Endzeit',
  lang11: 'Überschussbetrag',
  lang12: 'Sperrfrist',
  lang13: 'Tag',
  lang14: 'Kaufe {Münze}',
  lang15: 'Zahlungsmünze',
  lang15a: 'Bezahlen',
  lang16: 'Gleichgewicht',
  lang17: 'Alle verwenden',
  lang18: 'Bitte geben Sie die Abnahmemenge ein',
  lang19: 'Bezahlen:',
  lang20: 'Geschenk:',
  lang21: 'Bestellprotokoll',
  lang22: 'Freigabe',
  lang23: 'IEO-Versprechen',
  lang24: 'Rabatt',
  lang25: 'Bitte geben Sie die korrekte Abnahmemenge ein',
  lang26: 'Die Restsumme reicht nicht aus',
  lang27: 'Sind Sie sicher, {number}{coin} umzutauschen?',
  lang28: 'Hinweis',
  lang29: 'Noch keine Benachrichtigung',
  lang30: 'Hinweisdetails',
  lang31: 'Token zum Suchen eingeben',
  lang32: 'Mining jeden Tag',
  lang33: 'Je höher die Kraft, desto mehr Mining',
  lang34: 'Deal',
  lang35: 'Aufstieg',
  lang36: 'Ablehnen',
  lang37: 'Spot',
  lang38: 'Futures',
  lang39: 'Zyklus',
  lang40: 'Favoriten',
  lang41: 'Alle',
  lang42: 'Mian',
  lang43: 'Weiter',
  lang44: 'Vol',
  lang45: 'Preis',
  lang46: 'Änderung%',
  lang47: 'Gesperrtes Mining',
  lang48: 'Verwaltete Fonds',
  lang49: 'Einnahmen heute',
  lang50: 'Einkommen',
  lang51: 'Befehl',
  lang52: 'Tagesertrag',
  lang53: 'Tag',
  lang54: 'Bestellung bestätigen',
  lang55: 'Gewinn',
  lang56: 'Zyklus',
  lang57: 'Einzellimit',
  lang58: 'Dividendenzeit',
  lang59: 'Täglich',
  lang60: 'Hinterlegungsbetrag',
  lang61: 'Nach Ablauf zurücksenden',
  lang62: 'Vorzeitige Rückzahlung',
  lang63: 'Bitte geben Sie den Treuhandbetrag ein',
  lang64: 'Geschätzter Umsatz',
  lang65: 'Bitte geben Sie den korrekten Treuhandbetrag ein',
  lang66: 'Unzureichender Kontostand',
  lang67: 'Der Betrag liegt nicht innerhalb des Einzellimits',
  lang68: 'Datensatz sperren',
  lang69: 'Läuft',
  lang70: 'Fertig',
  lang71: 'Eingelöst',
  lang72: 'Einkommen',
  lang73: 'Befehl',
  lang74: 'Ablaufzeit',
  lang75: 'Vorzeitige Rücknahme der Verwahrung festlegen, bei vorzeitiger Rücknahme wird die entsprechende Bearbeitungsgebühr abgezogen'
};