export default {
  "lang1": "cancelar",
  "lang2": "Enviar",
  "lang3": "determinar",
  "lang4": "Sem dados",
  "lang5": "Sem registo",
  "lang6": "cópia",
  "lang7": "A cópia foi bem sucedida",
  "lang8": "a cópia falhou",
  "lang9": "confirmar",
  "lang10": "Moedas",
  "lang11": "activos",
  "lang12": "Pedido falhou! Verifique por favor a sua ligação à rede",
  "lang13": "ciclo",
  "lang14": "transacção",
  "lang15": "cotação",
  "lang16": "Entre em contato com o atendimento ao cliente!",
  "theme": "Peles por tema"
};