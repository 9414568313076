export default {
  lang1: 'Hủy bỏ',
  lang2: 'Gửi đi',
  lang3: 'Xác nhận',
  lang4: 'Chưa có dữ liệu',
  lang5: 'Chưa có bản ghi',
  lang6: 'Sao chép',
  lang7: 'Sao chép thành công',
  lang8: 'Sao chép thất bại',
  lang9: 'Xác nhận',
  lang10: 'Giao dịch',
  lang11: 'Tài sản',
  lang12: 'Yêu cầu thất bại! Vui lòng kiểm tra kết nối mạng',
  lang13: 'Chu kỳ',
  lang14: 'Giao dịch',
  lang15: 'Thị trường',
  lang16: 'Liên hệ Dịch vụ khách hàng!',
  theme: 'Giao diện'
};