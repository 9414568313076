var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-tabbar",
    {
      model: {
        value: _vm.selected,
        callback: function($$v) {
          _vm.selected = $$v
        },
        expression: "selected"
      }
    },
    [
      _c(
        "van-tabbar-item",
        {
          staticClass: "text_hide",
          on: {
            click: function($event) {
              return _vm.pushPage("/home")
            }
          }
        },
        [
          _c("p", [
            _c(
              "svg",
              {
                staticClass: "icon",
                attrs: {
                  t: "1696425133760",
                  viewBox: "0 0 1024 1024",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "p-id": "4848",
                  width: "20",
                  height: "20"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M937.067069 482.335377 556.719504 106.839307c-1.89107-2.251274-6.123464-6.173606-11.997242-10.048867-9.889231-6.522554-21.093411-10.486842-33.388435-10.486842-13.137205 0-24.610514 3.984754-34.245965 10.590196-5.826705 3.997034-9.844206 8.076956-12.117992 11.117199L85.643566 482.381425c-14.653745 14.434757-14.653745 37.890982 0 52.358485 14.538111 14.380522 33.883715 8.316409 50.366108-7.919367L161.532977 501.587859l350.847693-339.869664 374.329501 368.073007c20.077268 13.223163 37.773302 17.377786 50.358945 4.946662C951.720813 520.273431 951.720813 496.801856 937.067069 482.335377z",
                    "p-id": "4849"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M793.007045 462.046285c-17.391089 0-31.567973 13.938454-31.634488 31.236422l0 0.085958 0 0.089028 0 350.143659c0 17.416671-14.371312 31.602765-32.119535 31.602765l-84.129072 0 0-192.166671c0-49.818639-40.803311-90.111321-91.14486-90.111321l-85.268012 0c-50.326199 0-91.143836 40.300868-91.143836 90.111321l0 192.166671L293.437146 875.204116c-17.750269 0-32.119535-14.186094-32.119535-31.602765L261.317611 493.391177c-0.033769-17.355273-14.21884-31.343869-31.611975-31.343869-17.418718 0-31.589462 13.96506-31.658024 31.302937l0 354.429265c0 49.844222 40.808428 90.133833 91.14486 90.133833l141.253094 0 10.389628 0 0-10.391674 0-240.262062c0-17.410532 14.365172-31.580253 32.119535-31.580253l76.801177 0c17.756409 0 32.119535 14.169721 32.119535 31.580253l0 240.262062 0 10.391674 10.391674 0 141.253094 0c50.321082 0 91.14486-40.297798 91.14486-90.133833L824.665069 493.322615C824.527946 475.958132 810.380738 462.046285 793.007045 462.046285z",
                    "p-id": "4850"
                  }
                })
              ]
            )
          ]),
          _c("span", { staticClass: "footer_name text_hide" }, [
            _vm._v(_vm._s(_vm.$t("homeLang.lang1")))
          ])
        ]
      ),
      _c(
        "van-tabbar-item",
        {
          staticClass: "text_hide",
          on: {
            click: function($event) {
              return _vm.pushPage("/ordersrecord")
            }
          }
        },
        [
          _c("p", [
            _c(
              "svg",
              {
                staticClass: "icon",
                attrs: {
                  t: "1696425169283",
                  viewBox: "0 0 1024 1024",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "p-id": "6695",
                  width: "20",
                  height: "20"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M268.190476 146.285714h48.761905v73.118476L268.190476 219.428571v609.52381h487.619048V219.428571h-48.761905V146.285714h48.761905a73.142857 73.142857 0 0 1 73.142857 73.142857v609.52381a73.142857 73.142857 0 0 1-73.142857 73.142857H268.190476a73.142857 73.142857 0 0 1-73.142857-73.142857V219.428571a73.142857 73.142857 0 0 1 73.142857-73.142857z m292.571429 390.095238v73.142858h-219.428572v-73.142858h219.428572z m121.904762-146.285714v73.142857H341.333333v-73.142857h341.333334zM585.142857 73.142857a73.142857 73.142857 0 0 1 73.142857 73.142857v48.761905a73.142857 73.142857 0 0 1-73.142857 73.142857h-146.285714a73.142857 73.142857 0 0 1-73.142857-73.142857V146.285714a73.142857 73.142857 0 0 1 73.142857-73.142857h146.285714z m0 73.142857h-146.285714v48.761905h146.285714V146.285714z",
                    "p-id": "6696"
                  }
                })
              ]
            )
          ]),
          _c("span", { staticClass: "footer_name text_hide" }, [
            _vm._v(_vm._s(_vm.$t("addLang.lang3")))
          ])
        ]
      ),
      _c(
        "van-tabbar-item",
        {
          staticClass: "text_hide",
          on: {
            click: function($event) {
              return _vm.pushPage("/graborders")
            }
          }
        },
        [
          _c("p", [
            _c(
              "svg",
              {
                staticClass: "icon",
                attrs: {
                  t: "1696425223616",
                  viewBox: "0 0 1024 1024",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "p-id": "8630",
                  width: "20",
                  height: "20"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M309.03 954.86c-4.2 0-8.45-0.97-12.4-2.96a27.526 27.526 0 0 1-14.55-30.29l62.26-295.56-114.98-3.16c-8.38-0.22-16.2-4.25-21.26-10.95a27.628 27.628 0 0 1-4.68-23.45L331.68 89.38c3.14-12.18 14.12-20.71 26.7-20.71H791.9c10.12 0 19.43 5.56 24.26 14.46a27.567 27.567 0 0 1-1.22 28.22l-191.37 291.6h127.11c10.86 0 20.69 6.37 25.14 16.27 4.45 9.9 2.67 21.48-4.54 29.61L329.65 945.62a27.57 27.57 0 0 1-20.62 9.24z m-43.62-386.12l113.46 3.12c8.16 0.22 15.83 4.06 20.89 10.48a27.544 27.544 0 0 1 5.33 22.75l-47.68 226.34 331.9-373.35H572.53c-10.12 0-19.43-5.56-24.26-14.46a27.567 27.567 0 0 1 1.22-28.22l191.37-291.6H379.75L265.41 568.74z",
                    "p-id": "8631"
                  }
                })
              ]
            )
          ]),
          _c("span", { staticClass: "footer_name text_hide" }, [
            _vm._v(_vm._s(_vm.$t("addLang.lang4")))
          ])
        ]
      ),
      _c(
        "van-tabbar-item",
        {
          staticClass: "text_hide",
          on: {
            click: function($event) {
              return _vm.pushPage("/helpCenter")
            }
          }
        },
        [
          _c("p", [
            _c(
              "svg",
              {
                staticClass: "icon",
                attrs: {
                  t: "1696425270897",
                  viewBox: "0 0 1024 1024",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "p-id": "11390",
                  width: "20",
                  height: "20"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M888.5 761.7 756.4 761.7c-15.8 0-28.6-12.8-28.6-28.6l-0.3-336.8C725.7 278.5 630 188.6 512 188c-118 0.6-213.7 90.5-215.6 208.3l-0.2 292.2c0 0 36.9 127.4 124.8 112.6 6.7-22.3 30.3-30.7 45.5-30.7l92.6 0c31.6 0 57.2 25.6 57.2 57.2l0 17.9c0 31.6-25.6 57.2-57.2 57.2l-85.2 0c-24.2 0-44.8-15.2-53.2-36.5-1.4-0.8-12.8 0.2-24-2.2-86.7-31-124.7-102.7-124.7-102.7-1.5 0.3-3 0.5-4.5 0.5L135.5 761.8c-15.8 0-28.6-12.8-28.6-28.6L106.9 440c0-15.8 12.8-28.6 28.6-28.6l94.4 0c0-24.2-0.1-15.2-0.1-15.2 3.4-273.9 277.5-274.8 277.5-274.8 2.2-0.1 7.3-0.1 9.4 0 0 0 274.1 1 277.5 274.8 0 0 0-9.1-0.1 15.2l94.4 0c15.8 0 28.6 12.8 28.6 28.6l0 293.1C917.1 748.9 904.3 761.7 888.5 761.7L888.5 761.7zM235.8 495.2c0-9.7-5.2-17.6-11.6-17.6l-48.8 0c-6.4 0-11.6 7.9-11.6 17.6l0 180.5c0 9.8 5.2 17.6 11.6 17.6l48.8 0c6.4 0 11.6-7.9 11.6-17.6L235.8 495.2 235.8 495.2zM860.2 495.2c0-9.7-5.2-17.6-11.6-17.6l-48.7 0c-6.4 0-11.7 7.9-11.7 17.6l0 180.5c0 9.8 5.3 17.6 11.7 17.6l48.7 0c6.4 0 11.6-7.9 11.6-17.6L860.2 495.2 860.2 495.2zM860.2 495.2",
                    "p-id": "11391"
                  }
                })
              ]
            )
          ]),
          _c("span", { staticClass: "footer_name text_hide" }, [
            _vm._v(_vm._s(_vm.$t("addLang.lang61")))
          ])
        ]
      ),
      _c(
        "van-tabbar-item",
        {
          staticClass: "text_hide",
          on: {
            click: function($event) {
              return _vm.pushPage("/my")
            }
          }
        },
        [
          _c("p", [
            _c(
              "svg",
              {
                staticClass: "icon",
                attrs: {
                  t: "1696425293454",
                  viewBox: "0 0 1024 1024",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg",
                  "p-id": "13212",
                  width: "20",
                  height: "20"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M899.640668 897.714805c-7.238868-108.132766-60.680011-208.738092-146.610102-276.03173l-5.16565-3.568269-0.047072 0.069585c-4.88731-2.988054-10.458189-4.586458-16.192797-4.586458-19.701714 0-31.458479 9.498328-31.458479 25.411763 0 9.266038 4.285606 17.975397 11.477402 23.882943l-0.080841 0.13917 4.562922 3.266393c72.065316 51.518351 112.638383 129.652895 120.584356 232.273111l0.084934 1.119497c-0.071631 0.862647-0.116657 1.731434-0.116657 2.612501 0 17.340947 14.058181 31.400151 31.400151 31.400151 16.759709 0 30.449499-13.131065 31.350009-29.665647l0.624217 0L899.640668 897.714805zM798.515502 371.795794c0-156.107407-128.529305-283.119149-286.524712-283.119149s-286.525736 127.011742-286.525736 283.119149c0 84.110653 37.19104 162.570609 102.377692 216.660528-117.01711 62.452377-194.688097 181.588755-203.479321 313.118394l-0.36225 5.482875c-0.007163 0.113587-0.016373 0.226151-0.022513 0.340761l-0.032746 0.499373 0.019443 0c-0.008186 0.267083-0.019443 0.533143-0.019443 0.802272 0 14.702863 11.918447 26.622334 26.622334 26.622334 14.702863 0 26.622334-11.918447 26.622334-26.622334 0-0.26913-0.01228-0.535189-0.019443-0.802272l0.553609 0 0.417509-5.467525c9.647731-124.997875 88.871073-234.450706 202.112184-279.552927 33.809013 15.705704 85.420485 32.060183 131.714857 32.060183 42.889833 0 84.540442-9.451256 124.198673-28.307719l29.048593-16.863063 23.720238-15.566534C758.567675 539.996634 798.515502 458.919061 798.515502 371.795794zM511.989767 597.931115c-126.247332 0-228.949412-101.461833-228.949412-226.157833 0-124.673487 102.701057-226.111784 228.949412-226.111784 126.259612 0 228.972948 101.438297 228.972948 226.111784C740.961692 496.469282 638.249379 597.931115 511.989767 597.931115z",
                    "p-id": "13213"
                  }
                })
              ]
            )
          ]),
          _c("span", { staticClass: "footer_name text_hide" }, [
            _vm._v(_vm._s(_vm.$t("addLang.lang62")))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }