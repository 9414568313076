export default {
  lang1: '確定',
  lang2: '取消',
  lang3: '您要下載新的安裝包嗎？',
  lang4: '正在下載',
  lang5: '下載更新包失敗',
  lang6: '安裝後請重新打開應用程序！',
  lang7: '更新資源失敗',
  lang8: '當前沒有網絡服務，請檢查網絡連接！',
  lang9: '再按一次退出程序',
  lang10: 'APP版本更新',
  lang11: '1.優化了使用體驗；2.修復了一些已知問題。',
  lang12: '立即更新',
  lang13: '安裝包下載失敗！'
};