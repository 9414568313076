export default {
  lang1: '취소',
  lang2: '제출',
  lang3: '확인',
  lang4: '현재 데이터가 없습니다',
  lang5: '아직 기록이 없습니다',
  lang6: '복사',
  lang7: '복사 성공',
  lang8: '복사 실패',
  lang9: '확인',
  lang10: '동전',
  lang11: '자산',
  lang12: '요청 실패! 네트워크 연결을 확인하십시오',
  lang13: '주기',
  lang14: '거래',
  lang15: '인용',
  lang16: '고객센터로 연락주세요!',
  "theme": "테마 스킨",
  lang17: '',
  lang18: '',
  lang19: '',
  lang20: '',
  lang21: '',
  lang22: '',
  lang23: '',
  lang24: '',
  lang25: '',
  lang26: '',
  lang27: '',
  lang28: '',
  lang29: '',
  lang30: '',
  lang31: '',
  lang32: '',
  lang33: '',
  lang34: '',
  lang35: '',
  lang36: '',
  lang37: '',
  lang38: '',
  lang39: '',
  lang40: ''
};