export default {
  lang1: "출금",
  lang2: "계정 세부정보",
  lang3: "기록",
  lang4: "주문",
  lang5: "회원 등급",
  lang6: "필요 금액",
  lang7: "주문당 수수료",
  lang8: "주문 수",
  lang9: "참여",
  lang10: "사용 가능한 잔액",
  lang11: "동결된 잔액",
  lang12: "보류 중",
  lang13: "지불됨",
  lang14: "정산됨",
  lang15: "아직 데이터가 없습니다",
  lang16: "주문 총액",
  lang17: "수수료",
  lang18: "주문 제출",
  lang19: "주문 번호",
  lang20: "상품 수익률",
  lang21: "현재 잔액",
  lang22: "계산 공식",
  lang23: "이 주문의 수익",
  lang24: "제출",
  lang25: "지금 주문하기",
  lang26: "수수료 받기",
  lang27: "오늘의 작업",
  lang28: "오늘 완료",
  lang29: "절차",
  lang30: "\"작업 시작\" 버튼을 클릭하고 지시에 따라 작업을 완료하세요.",
  lang31: "작업을 완료하면 수수료를 잔액으로 정산할 수 있습니다",
  lang32: "단가",
  lang33: "리베이트",
  lang34: "진행 중인 주문",
  lang35: "대기열 취소",
  lang36: "현재 레벨에서 주문을 경쟁하는 사용자가 많아 시스템이 주문 할당에 열심히 노력하고 있습니다. 현재 11위에 랭크되어 있습니다. 잠시만 기다려주세요.",
  lang37: "팁: VIP 레벨을 업그레이드하면 더 빨리 주문을 받을 수 있습니다",
  lang38: "문의사항이나 도움이 필요하시면 온라인 고객 서비스에 문의하세요",
  lang39: "여기를 클릭하여 전용 고객 서비스에 문의⭣⭣⭣",
  lang40: "FAQ",
  lang41: "충전 방법과 결제 방법은 무엇입니까?",
  lang42: "이 시스템은 여러 국가에 개방되어 있습니다. 국제 결제 방법을 제공하고 각 국가의 현지 통화를 지원합니다.",
  lang43: "투자하고 수입을 얻으려면 어떻게 해야 합니까?",
  lang44: "시스템에서 주문을 받고 매일 안정적인 자동 주문 배치 수입과 이자를 얻을 수 있습니다. 모든 수익은 은행 카드로 인출할 수 있습니다.",
  lang45: "회원으로 등록하려면 어떻게 해야 합니까?",
  lang46: "신규 회원은 초대를 통해 등록됩니다. 등록된 회원의 혜택을 보장하고 과도한 등록으로 인한 혜택 감소를 방지하기 위해 신규 회원은 기존 회원의 초대를 통해서만 등록할 ��� 있습니다.",
  lang47: "구매 금액에 제한이 있습니까?",
  lang48: "예, 더 많은 회원이 혜택을 받을 수 있도록 시스템의 각 계정에는 최소 구매 금액 제한이 있습니다.",
  lang49: "작동 중에 문제가 발생하면 어떻게 해야 합니까?",
  lang50: "모든 작업을 완료하는 데 도움이 되도록 언제든지 온라인 고객 서비스에 문의할 수 있습니다.",
  lang51: "고객 서비스에 문의",
  lang52: "환영합니다",
  lang53: "충전",
  lang54: "입출금 기록",
  lang55: "내 팀",
  lang56: "친구 초대",
  lang57: "출금용 은행 카드",
  lang58: "보안 센터",
  lang59: "설정",
  lang60: "은행명",
  lang61: "도움말",
  lang62: "내",
  lang63: "주문 내역",
  lang64: "고객 서비스",
  lang65: "Lowe's",
  lang66: '커플 활동',
  lang67: '커플 연결',
  lang68: 'A 충전 금액 도달',
  lang69: 'B 충전 금액 도달',
  lang70: '청구 가능',
  lang71: '참여',
  lang72: '활동 규칙',
  lang73: '커플은 연결을 위해 서로의 ID를 제공해야 합니다. 연결이 성공하면 양측은 각자의 계정에서 해당 보상을 청구할 수 있습니다. 각 계정은 한 번만 연결할 수 있으며 연결된 후에는 다시 연결할 수 없습니다. 커��� 연결 활동을 통해 파트너와 함께 보상 활동을 즐기고 서로의 관계를 깊게 할 수 있습니다. 활동에서 즐거운 경험을 하시기 바랍니다!',
  lang74: '프로세스 설명',
  lang75: '사용자 A가 사용자 B의 ID를 입력하고 동의를 얻으면 시스템이 연결 정보를 확인하고 두 사용자의 연결 상태를 확인한 후 양측이 동의하면 연결이 성공합니다.',
  lang76: '커플 ID를 입력하세요',
  lang77: '취소',
  lang78: '확인',
  lang79: '영원히 함께',
  lang80: '영원한 사랑',
  lang81: '당신만을 사랑해',
  lang82: '절대 헤어지지 않아',
  lang83: '영원히 함께해',
  lang84: '자산 상세',
  lang85: '주문 규칙',
  lang86: '플랫폼 소개',
  lang87: '자산 상세',
  lang88: '자산 상세',
  lang89: '아직 계정이 없으신가요?',
  lang90: '획득한 주문 수',
  lang91: '계정',
  lang92: '오늘의 결과',
  lang93: '수수료',
  lang94: '플랫폼 운영 규칙',
  lang95: '1. 이 플랫폼은 판매자와 주문 고객을 위해 더 편리하고 빠른 서비스를 제공하기 위해 AI 시스템을 사용합니다.',
  lang96: '2. 이 플랫폼은 FLANNELS와 협력하는 전 세계 100,000개 이상의 전자상거래 판매자에게 서비스를 제공합니다. 제품 노출을 늘리기 위해 판매자는 이 플랫폼에서 제품을 판매합니다. 주문 완료 후 판매자는 즉시 주문 금액과 수수료를 환불합니다. 플랫폼은 주문 고객과 판매자 주문을 무작위로 매칭합니다. 수수료는 주문 규모와 VIP 레벨과 관련이 있습니다.',
  lang97: '주문 수는 VIP 레벨에 따라 다릅니다. VIP 레벨이 높을수록 더 많은 주문과 더 높은 수익을 얻을 수 있습니다.',
  lang98: '3. 플랫폼 시스템은 계정 잔액에 따라 VIP 레벨을 결정하고 주문 고객을 업그레이드/다운그레이드합니다.',
  lang99: '4. 주문 고객은 하루에 한 번만 해당 VIP 레벨의 주문 수량을 완료할 수 있습니다. 주문 고객은 해당 VIP 레벨의 주문 수량을 완료한 후에만 돈을 인출할 수 있습니다. 일일 주문을 완료한 후 사용자가 계속해서 수수료를 받고 싶다면 VIP 레벨을 높이거나 다음 날 주문 시스템 리셋을 기다릴 수 있습니다.',
  lang100: '5. 주문 고객의 동기부여를 위해 플랫폼은 무작위로 매칭되는 행운의 주문을 제공합니다.',
  lang101: '각 고객은 세 개의 보상 주문을 받을 기회가 있으며, 보상은 주문 금액의 20%-35%입니다',
  lang102: '① 첫 번째 행운의 주문은 주문 금액의 20%를 보상합니다.',
  lang103: '② 두 번째 행운의 주문은 주문 금액의 30%를 보상합니다.',
  lang104: '③ 세 번째 행운의 주문은 주문 금액의 35%를 보상합니다.',
  lang105: '6. 개인정보 보호정책: FLANNELS 플랫폼은 모든 주문 고객의 개인정보를 존중하며 주문 고객의 개인정보 보호에 특별히 중점을 둡니다.',
  lang106: 'FLANNELS 플랫폼은 최종 해석권을 보유하며, 사용자는 규칙을 준수해야 합니다',
  lang107: 'FLANNELS는 1976년 영국 북부에서 설립되어 현재 업계에서 가장 흥미진진한 플레이어 중 하나가 되었습니다. 브랜드는 현재 영국에 50개 이상의 매장을 보유하고 있으며 기성복, 액세서리, 신발 및 새롭게 출시된 청소년 및 뷰티 컬렉션을 판매하고 있습니다. FLANNELS Beauty는 2021년에 출시되어 "소비자 우선" 접근 방식으로 뷰티 소매를 혁신하기 위해 노력하고 있습니다.',
  lang108: 'FLANNELS Beauty Bars에서 FLANNELS Beauty Changing Rooms까지, 최첨단 매장과 일류 경험을 갖춘 지역에 럭셔리 뷰티를 제공합니다. 차세대 소매 리더들의 지원을 받아 새로 부임하는 CEO Michael Murray는 FLANNELS를 새로운 수준으로 끌어올리고 그 비교할 수 없는 제안을 지역 도시로 가져오고 있습니다. Group Head of Elevation으로서 Murray는 업계를 뒤흔들었습니다 - "우리가 가장 잘하는 종류의 럭셔리에 대한 전례 없는 수요가 있습니다.',
  lang109: 'FLANNELS는 계속해서 소매를 혁신하고 재고하며, 우리의 비전을 인상적으로 보여주고 영국의 주요 도시에 세계적 수준의 쇼핑 목적지를 제공합니다." FLANNELS는 럭셔리 패션과 뷰티 분야의 기존 및 신흥 브랜드를 보유한 슈퍼 포용적인 멀티 카테고리 소매업체로, 매장 내 경험을 되살리고 럭셔리를 모든 사람이 접근할 수 있도록 하는 데 전념하고 있습니다.'
};