export default {
  lang1: 'Rút tiền',
  lang2: 'Chi tiết tài khoản',
  lang3: 'Ghi chép',
  lang4: 'Đơn hàng',
  lang5: 'Cấp độ thành viên',
  lang6: 'Số tiền yêu cầu',
  lang7: 'Hoa hồng mỗi đơn hàng',
  lang8: 'Số lượng đơn hàng',
  lang9: 'Tham gia',
  lang10: 'Số dư khả dụng',
  lang11: 'Số dư đóng băng',
  lang12: 'Đang chờ xử lý',
  lang13: 'Đã thanh toán',
  lang14: 'Đã thanh toán',
  lang15: 'Chưa có dữ liệu',
  lang16: 'Tổng đơn hàng',
  lang17: 'Hoa hồng',
  lang18: 'Gửi đơn hàng',
  lang19: 'Số đơn hàng',
  lang20: 'Lợi suất sản phẩm',
  lang21: 'Số dư hiện tại',
  lang22: 'Công thức tính toán',
  lang23: 'Lợi nhuận từ đơn hàng này',
  lang24: 'Gửi đi',
  lang25: 'Nhận đơn hàng ngay',
  lang26: 'Nhận hoa hồng',
  lang27: 'Nhiệm vụ hôm nay',
  lang28: 'Đã hoàn thành hôm nay',
  lang29: 'Thủ tục',
  lang30: 'Nhấp vào nút "Bắt đầu nhiệm vụ" và làm theo hướng dẫn để hoàn thành nhiệm vụ.',
  lang31: 'Sau khi hoàn thành nhiệm vụ, bạn có th�� thanh toán hoa hồng vào số dư',
  lang32: 'Đơn giá',
  lang33: 'Hoàn tiền',
  lang34: 'Đơn hàng đang xử lý',
  lang35: 'Hủy hàng đợi',
  lang36: 'Do số lượng người dùng cạnh tranh đơn hàng ở cấp độ hiện tại lớn, hệ thống đang nỗ lực phân bổ đơn hàng. Hiện đang xếp hạng thứ 11. Vui lòng kiên nhẫn chờ đợi.',
  lang37: 'Mẹo: Nâng cấp cấp độ VIP có thể giúp bạn nhận đơn hàng nhanh hơn',
  lang38: 'Nếu bạn có bất kỳ câu hỏi hoặc cần trợ giúp, vui lòng liên hệ dịch vụ khách hàng trực tuyến',
  lang39: 'Nhấp vào tôi để liên hệ dịch vụ khách hàng độc quyền của bạn⭣⭣⭣',
  lang40: 'Câu hỏi thường gặp',
  lang41: 'Làm thế nào để nạp tiền và có những phương thức thanh toán nào?',
  lang42: 'Hệ thống này mở cho nhiều quốc gia. Chúng tôi cung cấp phương thức thanh toán quốc tế và hỗ trợ tiền tệ địa phương cho các quốc gia khác nhau.',
  lang43: 'Làm thế nào tôi có thể đầu tư và kiếm thu nhập?',
  lang44: 'Bạn có thể nhận đơn hàng trong hệ thống và nhận thu nhập đặt hàng tự động ổn định và lãi suất mỗi ngày. Tất cả thu nhập có thể đợc rút về thẻ ngân hàng của bạn.',
  lang45: 'Làm thế nào để đăng ký làm thành viên?',
  lang46: 'Thành viên mới được đăng ký bằng lời mời. Để đảm bảo quyền lợi của thành viên đã đăng ký và ngăn chặn việc đăng ký quá mức làm giảm lợi ích, thành viên mới chỉ có thể đăng ký theo lời mời của thành viên hiện tại.',
  lang47: 'Có giới hạn về số tiền mua không?',
  lang48: 'Có, để cho phép nhiều thành viên có cơ hội nhận lợi ích, mỗi tài khoản trong hệ thống có giới hạn số tiền mua tối thiểu.',
  lang49: 'Tôi nên làm gì nếu gặp vấn đề trong quá trình vận hành?',
  lang50: 'Bạn có thể liên hệ dịch vụ khách hàng trực tuyến bất cứ lúc nào để hỗ trợ hoàn thành tất cả các hoạt động.',
  lang51: 'Liên hệ dịch vụ khách hàng',
  lang52: 'Chào mừng',
  lang53: 'Nạp tiền',
  lang54: 'Lịch sử nạp và rút tiền',
  lang55: 'Đội của tôi',
  lang56: 'Mời bạn bè',
  lang57: 'Thẻ ngân hàng rút tiền',
  lang58: 'Trung tâm bảo mật',
  lang59: 'Thiết lập',
  lang60: 'Tên ngân hàng',
  lang61: 'Trợ giúp',
  lang62: 'Của tôi',
  lang63: 'Lịch sử đơn hàng',
  lang64: 'Dịch vụ khách hàng',
  lang65: 'FLASHOP',
  lang66: 'Hoạt động cặp đôi',
  lang67: 'Liên kết cặp đôi',
  lang68: 'A đạt số tiền nạp',
  lang69: 'B đạt số tiền nạp',
  lang70: 'Có thể nhận',
  lang71: 'Tham gia',
  lang72: 'Quy tắc hoạt động',
  lang73: 'Các cặp đôi cần cung cấp ID của nhau để liên kết. Sau khi liên kết thành công, cả hai bên có thể nhận phần thưởng tương ứng vào tài khoản của mình. Mỗi tài khoản chỉ có thể liên kết một lần và sau khi đã liên kết không thể liên kết lại. Thông qua hoạt động liên kết cặp đôi, bạn có thể tận hưởng các hoạt động phần thưởng với đối tác của mình và làm sâu sắc thêm mối quan hệ của nhau. Chúc các bạn có trải nghiệm vui vẻ trong hoạt động!',
  lang74: 'Mô tả quy trình',
  lang75: 'Người dùng A nhập ID của người dùng B, được sự đồng ý, hệ thống xác minh thông tin liên kết và kiểm tra trạng thái liên kết của cả hai người dùng, sau khi cả hai bên đồng ý, liên kết thành công.',
  lang76: 'Vui lòng nhập ID cặp đôi',
  lang77: 'Hủy',
  lang78: 'Xác nhận',
  lang79: 'Mãi mãi bên nhau',
  lang80: 'Tình yêu vĩnh cửu',
  lang81: 'Chỉ yêu mình em',
  lang82: 'Không bao giờ xa nhau',
  lang83: 'Mãi mãi bên nhau',
  lang84: 'Chi tiết tài sản',
  lang85: 'Quy tắc đặt hàng',
  lang86: 'Giới thiệu nền tảng',
  lang87: 'Chi tiết tài sản',
  lang88: 'Chi tiết tài sản',
  lang89: 'Chưa có tài khoản?',
  lang90: 'Số lượng đơn hàng đã nhận',
  lang91: 'Tài khoản',
  lang92: 'Kết quả hôm nay',
  lang93: 'Hoa hồng',
  lang94: 'Quy tắc hoạt động của nền tảng',
  lang95: '1. Nền tảng này sử dụng hệ thống AI để cung cấp dịch vụ thuận tiện và nhanh chóng hơn cho các thương nhân và khách hàng đặt hàng;',
  lang96: '2. Nền tảng này phục vụ hơn 100.000 thương nhân thương mại điện tử trên toàn thế giới hợp tác với FLANNELS. Để tăng độ phơi bày sản phẩm, các thương nhân trưng bày sản phẩm của họ để bán trên nền tảng này. Sau khi hoàn thành đơn hàng, các thương nhân ngay lập tức hoàn lại số tiền đặt hàng và hoa hồng. Nền tảng ngẫu nhiên ghép khách hàng đặt hàng với đơn đặt hàng của thương nhân. Hoa hồng liên quan đến quy mô đơn hàng và cấp độ VIP;',
  lang97: 'Số lượng đơn hàng phụ thuộc vào cấp độ VIP. Cấp độ VIP càng cao, càng nhiều đơn hàng và lợi nhuận cao hơn;',
  lang98: '3. Hệ thống nền tảng xác định cấp độ VIP dựa trên số dư tài khoản và nâng cấp/hạ cấp khách hàng đặt hàng;',
  lang99: '4. Khách hàng đặt hàng chỉ có thể hoàn thành số lượng đơn hàng cấp độ VIP tương ứng một lần mỗi ngày. Khách hàng đặt hàng chỉ có thể rút tiền sau khi hoàn thành số lượng đơn hàng cấp độ VIP tương ứng. Sau khi hoàn thành đơn hàng hàng ngày, nếu người dùng muốn tiếp tục kiếm hoa hồng, họ có thể tăng cấp độ VIP của mình hoặc đợi hệ thống đặt lại đơn hàng vào ngày hôm sau;',
  lang100: '5. Để tạo động lực cho khách hàng đặt hàng, nền tảng cung cấp các đơn hàng may mắn được ghép ngẫu nhiên;',
  lang101: 'Mỗi khách hàng có cơ hội nhận được ba đơn hàng thưởng, với phần thưởng là 20%-35% giá trị đơn hàng',
  lang102: '① Đơn hàng may mắn đầu tiên thưởng 20% giá trị đơn hàng.',
  lang103: '② Đơn hàng may mắn thứ hai thưởng 30% giá trị đơn hàng.',
  lang104: '③ Đơn hàng may mắn thứ ba thưởng 35% giá trị đơn hàng.',
  lang105: '6. Chính sách bảo mật: Nền tảng FLANNELS tôn trọng quyền riêng tư của mỗi khách hàng đặt hàng và đặc biệt nhấn mạnh việc bảo vệ quyền riêng tư của khách hàng đặt hàng;',
  lang106: 'Nền tảng FLANNELS có quyền giải thích cuối cùng, người dùng được yêu cầu tuân thủ các quy tắc',
  lang107: 'FLANNELS được thành lập vào năm 1976 ở miền Bắc nước Anh và hiện là một trong những công ty thú vị nhất trong ngành. Thương hiệu hiện có hơn 50 cửa hàng ở Vương quốc Anh và bán quần áo may sẵn, phụ kiện, giày dép và các bộ sưu tập thanh thiếu niên và làm đẹp mới ra mắt. FLANNELS Beauty ra mắt vào năm 2021 và cam kết làm gián đoạn ngành bán lẻ làm đẹp với cách tiếp cận "người tiêu dùng là trên hết".',
  lang108: 'Từ FLANNELS Beauty Bars đến FLANNELS Beauty Changing Rooms, nó mang vẻ đẹp xa xỉ đến các khu vực với các cửa hàng hiện đại và trải nghiệm hạng nhất. Được hậu thuẫn bởi thế hệ lãnh đạo bán lẻ tiếp theo, CEO sắp tới Michael Murray đang đưa FLANNELS lên một tầm cao mới và mang đến đề xuất vô song của mình cho các thành phố khu vực. Với tư cách là Giám đốc Nâng cao Tập đoàn, Murray đã làm rung chuyển ngành công nghiệp - "Có nhu cầu chưa từng có đối với loại hình xa xỉ mà chúng tôi làm tốt nhất.',
  lang109: 'FLANNELS tiếp tục làm gián đoạn và suy nghĩ lại về bán lẻ, thể hiện ấn tượng tầm nhìn của chúng tôi và cung cấp điểm đến mua sắm đẳng cấp thế giới cho các thành phố lớn của Anh." FLANNELS là một nhà bán lẻ đa danh mục siêu bao trùm với các thương hiệu đã thành lập và mới nổi trong lĩnh vực thời trang và làm đẹp cao cấp, cam kết làm sống lại trải nghiệm trong cửa hàng và làm cho sự xa xỉ có thể tiếp cận với tất cả mọi người.'
};