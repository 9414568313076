export default {
  lang1: 'Trao đổi',
  lang2: 'Hợp đồng tương lai',
  lang3: 'Tên',
  lang4: 'Giá cuối cùng',
  lang5: 'Thay đổi%',
  lang6: 'Số lượng',
  lang7: 'Giá',
  lang8: 'Thời gian',
  lang9: '1 phút',
  lang9a: '5 phút',
  lang10: '15 phút',
  lang10a: '30 phút',
  lang11: '1 giờ',
  lang12: '4 giờ',
  lang13: '1 ngày',
  lang13a: '1 tuần',
  lang14: 'Chỉ số',
  lang14a: 'Thêm',
  lang15: 'Cao',
  lang16: 'Thấp',
  lang17: '24 giờ',
  lang18: 'Biểu đồ',
  lang19: 'Giao dịch',
  lang20: 'Giới thiệu',
  lang21: 'Thời gian',
  lang22: 'Hướng',
  lang23: 'Mua',
  lang24: 'Bán',
  lang25: 'Tổng phát hành',
  lang26: 'Trang web chính thức',
  lang27: 'Trình khám phá khối',
  lang28: 'Mua',
  lang29: 'Bán',
  lang30: 'Tìm kiếm Token',
  lang31: 'Đã xem gần đây',
  lang32: 'Không có tiền tệ liên quan',
  lang33: 'Tiền tệ này chưa mở giao dịch',
  lang34: 'Tổng tài sản',
  lang35: 'Nạp tiền',
  lang36: 'Rút tiền',
  lang37: 'Chuyển khoản',
  lang38: 'Trao đổi',
  lang39: 'Chuyển đổi tài sản ví',
  lang40: 'Ẩn tiền tệ nhỏ',
  lang41: 'Tìm kiếm',
  lang42: 'Khả dụng',
  lang43: 'Đã khóa',
  lang44: 'Ước tính',
  lang45: 'Số dư nắm giữ',
  lang46: 'Ký quỹ vị thế',
  lang47: 'Lãi/Lỗ',
  lang48: 'Ký quỹ ủy thác',
  lang49: 'Tổng tài sản',
  lang50: 'Nạp tiền',
  lang51: 'Rút tiền',
  lang52: 'Khác',
  lang53: 'Trạng thái:',
  lang54: 'Địa chỉ:',
  lang55: 'Đang chờ',
  lang56: 'Hoàn thành',
  lang57: 'Từ chối',
  lang58: 'Chuyển đổi Token',
  lang59: 'Tên chuỗi',
  lang60: 'Địa chỉ nạp tiền',
  lang61: 'Vui lòng kiểm tra và điền nhãn địa chỉ cẩn thận, nếu bỏ qua nhãn, tiền sẽ bị mất',
  lang62: 'Không nạp bất kỳ tài sản không phải {coin} nào vào địa chỉ trên, nếu không tài sản sẽ không được khôi phục',
  lang63: 'Số tiền nạp tối thiểu {low}{coin}, nạp ít hơn số tiền nạp tối thiểu sẽ không được ghi có',
  lang64: 'Địa chỉ ví',
  lang65: 'Vui lòng nhập hoặc dán địa chỉ ví',
  lang66: 'Vui lòng nhập số tiền rút',
  lang67: 'Tất cả',
  lang68: 'Phí',
  lang69: 'Số tiền vào tài khoản',
  lang70: 'Lưu ý: Rút tiền tối đa là {oneoutheight} lần, rút tiền tối thiểu là {low}, và rút tiền tối đa là {height} mỗi ngày',
  lang71: 'Vui lòng nhập mật khẩu quỹ',
  lang72: 'Mật khẩu quỹ',
  lang73: 'Địa chỉ ví',
  lang74: 'Bạn chưa thêm địa chỉ ví của tiền tệ này',
  lang75: 'Đi thêm',
  lang76: 'Xác thực',
  lang77: 'Vui lòng hoàn thành xác thực danh tính trước',
  lang78: 'Xác thực',
  lang79: 'Vui lòng thiết lập mật khẩu quỹ tài khoản trước',
  lang80: 'Cài đặt',
  lang81: 'Số dư không đủ!',
  lang82: 'Vui lòng sử dụng quét trong APP',
  lang83: 'Quét',
  lang84: 'đến',
  lang85: 'Tiền tệ',
  lang86: 'Vui lòng nhập số tiền chuyển',
  lang87: 'Chỉ có thể giao dịch tài sản bằng cách chuyển tài sản vào tài khoản tương ứng',
  lang88: 'Tiền pháp định',
  lang89: 'Số dư tài khoản không đủ',
  lang90: 'Tỷ giá:',
  lang91: 'Lưu ý: Tỷ giá trao đổi tối thiểu là {low}, và tỷ giá trao đổi tối đa là {height}.',
  lang92: 'Lịch sử trao đổi',
  lang93: 'đến',
  lang94: 'tiêu thụ',
  lang95: 'vào tài khoản',
  lang96: 'Vui lòng nhập số tiền trao đổi chính xác',
  lang97: 'Địa chỉ rút tiền',
  lang98: 'Địa chỉ rút tiền chưa được thêm~',
  lang99: 'Thêm địa chỉ rút tiền',
  lang100: 'Địa chỉ rút tiền',
  lang101: 'Vui lòng nhập địa chỉ',
  lang102: 'Ghi chú',
  lang103: 'Vui lòng nhập ghi chú',
  lang104: 'Bạn có chắc chắn muốn xóa địa chỉ không?',
  lang105: 'Trao đổi',
  lang106: 'Giao dịch với giá tốt nhất',
  lang107: 'Tổng',
  lang108: 'Đơn hàng hiện tại',
  lang108a: 'Thêm',
  lang109: 'Giới hạn',
  lang110: 'Thị trường',
  lang111: 'Đóng',
  lang112: 'Giá đặt hàng',
  lang113: 'Thời gian',
  lang114: 'Số lượng',
  lang115: 'Chưa giao dịch',
  lang116: 'Tổng',
  lang117: 'Trạng thái',
  lang118: 'Chưa giao dịch',
  lang119: 'Giao dịch một phần',
  lang120: 'Tất cả giao dịch',
  lang121: 'Hủy tất cả',
  lang122: 'Hủy một phần',
  lang123: 'Vui lòng nhập giá',
  lang124: 'Vui lòng nhập giá chính xác',
  lang125: 'Vui lòng nhập giá lớn hơn 0',
  lang126: 'Vui lòng nhập số lượng',
  lang127: 'Vui lòng nhập số lượng chính xác',
  lang128: 'Số tiền mua tối thiểu là {min}',
  lang129: 'Vượt quá số lượng nắm giữ',
  lang130: 'Xác nhận mua {coin}',
  lang131: 'Số tiền bán tối thiểu là {min}',
  lang132: 'Xác nhận bán {coin}',
  lang133: 'Xác nhận đóng đơn hàng?',
  lang134: 'Lịch sử đơn hàng',
  lang135: 'Mở',
  lang136: 'Vị thế',
  lang137: 'Đóng tất cả vị thế',
  lang138: 'Mua',
  lang139: 'Bán',
  lang140: 'Chia sẻ',
  lang141: 'Giá chốt lời',
  lang142: 'Giá dừng lỗ',
  lang143: 'Ký quỹ',
  lang144: 'Đơn hàng hiện tại',
  lang145: 'Dài',
  lang146: 'Ngắn',
  lang147: 'Cô lập',
  lang148: 'Chéo',
  lang149: 'Giá',
  lang150: 'Giá mở',
  lang151: 'Số lượng',
  lang152: 'Chưa giao dịch',
  lang153: 'Loại',
  lang154: 'Ước tính',
  lang155: 'Lãi/Lỗ',
  lang155a: 'Lãi suất',
  lang156: 'Giá trị vị thế',
  lang157: 'Dừng Lãi/Lỗ',
  lang158: 'Đóng nhanh',
  lang159: 'Đóng',
  lang160: 'Khả dụng',
  lang161: 'Ký quỹ vị thế',
  lang162: 'Số lượng vị thế đóng',
  lang163: 'Vui lòng nhập số lượng đóng',
  lang164: 'Hoán đổi',
  lang165: 'Đóng tất cả',
  lang166: 'Đóng dài',
  lang167: 'Đóng ngắn',
  lang168: 'Vui lòng nhập giá',
  lang169: 'Vui lòng nhập giá chính xác',
  lang170: 'Vui lòng nhập số lượng',
  lang171: 'Vui lòng nhập số nguyên',
  lang172: 'Số tiền mua tối đa là {max}',
  lang173: 'Số tiền bán tối đa là {max}',
  lang174: 'Giá chốt lời không thể thấp hơn giá mở vị thế dài',
  lang175: 'Giá dừng lỗ không thể cao hơn giá mở vị thế dài',
  lang176: 'Giá chốt lời không thể cao hơn giá mở vị thế ngắn',
  lang177: 'Giá dừng lỗ không thể thấp hơn giá mở vị thế ngắn',
  lang178: 'Có mở dài {tradeNum} {tradeCoin}, ký quỹ khoảng {bondNum}{mainCoin} không?',
  lang179: 'Có mở ngắn {tradeNum} {tradeCoin}, ký quỹ khoảng {bondNum}{mainCoin} không?',
  lang180: 'Xác nhận đóng nhanh?',
  lang181: 'Ghi chép',
  lang182: 'Vị thế',
  lang183: 'Lịch sử vị thế',
  lang184: 'Đơn hàng hiện tại',
  lang185: 'Lịch sử đơn hàng',
  lang186: 'Giá đóng',
  lang187: 'Thời gian đóng',
  lang188: 'Xác nhận đóng một lần?',
  lang189: 'Tên',
  lang190: 'Giá',
  lang191: 'Giá mở',
  lang192: 'Hướng',
  lang193: 'Số lượng đóng',
  lang194: 'Giá chốt lời',
  lang195: 'Doanh thu:',
  lang196: 'Giá dừng lỗ',
  lang197: 'Vui lòng nhập giá chốt lời',
  lang198: 'Vui lòng nhập giá dừng lỗ',
  lang199: 'Ghi chép',
  lang200: 'Sửa đổi',
  lang201: 'Vui lòng nhập số tiền chính xác để đóng vị thế',
  lang202: 'Vui lòng nhập giá chốt lời chính xác',
  lang203: 'Giá chốt lời phải cao hơn giá mở',
  lang204: 'Giá chốt lời phải thấp hơn giá mở',
  lang205: 'Vui lòng nhập giá dừng lỗ chính xác',
  lang206: 'Giá dừng lỗ phải cao hơn giá mở',
  lang207: 'Giá dừng phải thấp hơn giá mở',
  lang208: 'Vui lòng nhập giá chốt lời hoặc giá dừng lỗ',
  lang209: 'Bạn thắng',
  lang210: 'Bạn thua',
  lang211: 'Lợi suất',
  lang212: 'Hoán đổi',
  lang213: 'Giá đóng',
  lang214: 'Đuổi kịp',
  lang215: 'Vượt qua',
  lang216: 'Dẫn đầu',
  lang217: 'Lưu',
  lang218: 'Lưu hình ảnh thành công',
  lang219: 'Lưu hình ảnh thất bại',
  lang220: 'Số lượng mua',
  lang221: 'Vui lòng nhập số lượng mua',
  lang222: 'Phương thức thanh toán',
  lang223: 'Ước tính: ',
  lang224: 'Lưu ý: Số tiền mua tối thiểu là {low}{coin}, và số tiền mua tối đa là {high}{coin}',
  lang225: 'Đi thanh toán',
  lang226: 'Lịch sử mua',
  lang227: 'Chờ thanh toán',
  lang228: 'Hoàn thành',
  lang229: 'Đã hủy',
  lang230: 'Vui lòng nhập số lượng mua chính xác',
  lang231: 'Số lượng mua không thể thấp hơn số lượng mua tối thiểu',
  lang232: 'Số lượng mua không thể cao hơn số lượng mua tối đa',
  lang233: 'Thu nhập',
  lang234: 'Lợi suất',
  lang235: 'Mua',
  lang236: 'Bán',
  lang237: 'Đơn hàng vị thế',
  lang238: 'Giá mở',
  lang239: 'Giá',
  lang240: 'Tổng số tiền',
  lang241: 'Lãi/Lỗ',
  lang242: 'Chu kỳ',
  lang243: 'Phí',
  lang244: 'Lịch sử đơn hàng',
  lang245: 'Giá đóng',
  lang246: 'Thời gian đóng',
  lang247: 'Đơn hàng lợi nhuận',
  lang248: 'Thời gian',
  lang249: 'Hướng',
  lang250: 'Lợi nhuận',
  lang251: 'Giá đóng',
  lang252: 'Hợp đồng chu kỳ',
  lang253: 'Tài sản',
  lang254: 'Khả dụng',
  lang255: 'Số lượng',
  lang256: 'Tối thiểu {min}{coin}',
  lang257: 'Dự kiến:',
  lang258: 'Dự kiến:',
  lang259: 'Vui lòng nhập số lượng giao dịch',
  lang260: 'Vui lòng nhập số lượng chính xác',
  lang261: 'Số dư không đủ',
  lang262: 'Số lượng phải cao hơn {min}',
  lang263: 'Số lượng phải thấp hơn {max}',
  lang264: 'Dữ liệu đẩy websocket bất thường, vui lòng thử lại sau!',
  lang265: 'Kết nối websocket bất thường, vui lòng thử lại sau!',
  lang266: 'Lợi nhuận',
  lang267: 'Thua lỗ',
  lang268: 'Giá mở:',
  lang269: 'Số tiền đặt hàng:',
  lang270: 'Đếm ngược:',
  lang271: 'Tất cả vị thế lịch sử',
  lang272: 'Quản lý đơn hàng',
  lang273: 'Lịch sử giao dịch',
  lang274: 'Khối lượng',
  lang275: 'Số tiền nạp',
  lang276: 'Vui lòng nhập số tiền nạp',
  lang277: 'Đơn hàng nạp tiền',
  lang278: 'Vui lòng nhập đơn hàng nạp tiền',
  lang279: 'Lợi nhuận hôm nay',
  lang280: 'Tổng lợi nhuận',
  lang281: 'Chủ thẻ'
};