var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-item-box" },
    _vm._l(_vm.goodsList, function(item, index) {
      return _c("div", { key: index, staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("span", { staticClass: "text-secondary small" }),
          _c("span", { staticClass: "status statuswaitpay" }, [
            _vm._v(_vm._s(_vm.$t("addLang.lang13")))
          ])
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "media" }, [
            _c("div", { staticClass: "media-icon" }, [
              _c("img", {
                attrs: { src: _vm.getImgUrl(item.picture), alt: "" }
              })
            ]),
            _c("div", { staticClass: "media-desc" }, [
              _vm._v(" " + _vm._s(item.productDescribe) + " ")
            ])
          ])
        ]),
        _c("div", { staticClass: "card-footer" }, [
          _c("div", { staticClass: "row" }, [
            _c("span", { staticClass: "gray" }, [
              _vm._v(_vm._s(_vm.$t("addLang.lang16")) + ":")
            ]),
            _c("span", { staticClass: "text-right" }, [
              _vm._v(_vm._s(item.price) + "$")
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("span", { staticClass: "gray" }, [
              _vm._v(_vm._s(_vm.$t("addLang.lang17")) + ":")
            ]),
            _c("span", { staticClass: "text-right" }, [
              _vm._v(
                _vm._s(_vm._f("mathFloor")(item.price * item.rebate, 2)) + "$"
              )
            ])
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }