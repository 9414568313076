export default {
  lang1: 'Abbrechen',
  lang2: 'Senden',
  lang3: 'Bestätigen',
  lang4: 'Noch keine Daten',
  lang5: 'Noch kein Eintrag',
  lang6: 'Kopieren',
  lang7: 'Kopieren erfolgreich',
  lang8: 'Kopieren fehlgeschlagen',
  lang9: 'Bestätigen',
  lang10: 'Spot-Ware',
  lang11: 'Brieftasche',
  lang12: 'Anfrage fehlgeschlagen! Bitte Netzwerkverbindung prüfen',
  lang13: 'Zyklus',
  lang14: 'Handel',
  lang15: 'Markt',
  lang16: 'Wenden Sie sich an den Kundendienst!',
  "theme": "Themenhaut"
};