import account from './account';
import home from './home';
import common from './common';
import trade from './trade';
import plus from './plus';
import add from './add';
var en = {
  accountLang: account,
  homeLang: home,
  commonLang: common,
  tradeLang: trade,
  plusLang: plus,
  addLang: add
};
export default en;