import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import _slicedToArray from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/slicedToArray";
export default {
  'input-line': {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: function inserted(el) {
      // 聚焦元素时改变父节点底线颜色
      el.onfocus = function () {
        el.parentElement.className += ' input_focus'; // el.parentElement.parentElement.style.borderColor = '#334186'
      };

      el.onblur = function () {
        el.parentElement.classList.remove('input_focus');
      };
    }
  },
  'input-line-parent': {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: function inserted(el) {
      // 聚焦元素时改变父节点底线颜色
      el.onfocus = function () {
        el.parentElement.parentElement.className += ' input_focus'; // el.parentElement.parentElement.style.borderColor = '#334186'
      };

      el.onblur = function () {
        el.parentElement.parentElement.classList.remove('input_focus');
      };
    }
  },
  // 输入框只允许输入正整数
  'intNumber': {
    inserted: function inserted(el) {
      el.addEventListener('keypress', function (e) {
        e = e || window.event;
        var charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode;
        var re = /\d/;

        if (!re.test(String.fromCharCode(charcode)) && charcode > 9 && !e.ctrlKey) {
          if (e.preventDefault) {
            e.preventDefault();
          } else {
            e.returnValue = false;
          }
        }
      });
    }
  },
  // 只能输入整数
  onlyNum: function onlyNum(el, binding, vnode) {
    var ele = el.tagName === 'INPUT' ? el : el.querySelector('input');

    ele.oninput = function () {
      // 获取相关的指令配置信息
      var rel = vnode.data.directives.filter(function (item) {
        return item.name === 'only-num';
      })[0];
      vnode.context.$nextTick(function () {
        handleInput(ele, vnode, rel);
      });
    };
  },

  /** *
  * 防抖 单位时间只触发最后一次
  *  @param {?Number|300} time - 间隔时间
  *  @param {Function} fn - 执行事件
  *  @param {?String|"click"} event - 事件类型 例："click"
  *  @param {Array} binding.value - [fn,event,time]
  *  @date  2020-1-29
  *  @author aChuan
  *  例：<el-button v-debounce="[reset,`click`,300]">刷新</el-button>
  *  也可简写成：<el-button v-debounce="[reset]">刷新</el-button>
  */
  'debounce': {
    inserted: function inserted(el, binding) {
      var _binding$value = _slicedToArray(binding.value, 3),
          fn = _binding$value[0],
          _binding$value$ = _binding$value[1],
          event = _binding$value$ === void 0 ? 'click' : _binding$value$,
          _binding$value$2 = _binding$value[2],
          time = _binding$value$2 === void 0 ? 300 : _binding$value$2;

      var timer;
      el.addEventListener(event, function () {
        timer && clearTimeout(timer);
        timer = setTimeout(function () {
          return fn();
        }, time);
      });
    }
  },

  /** *
  *  节流 每单位时间可触发一次
  *  第一次瞬间触发，最后一次不管是否达到间隔时间依然触发
  * 【考虑到input的change事件】
  *  @param {?Number|300} time - 间隔时间
  *  @param {Function} fn - 执行事件
  *  @param {?String|"click"} event - 事件类型 例："click"
  *  @param {Array} binding.value - [fn,event,time]
  *  @date  2020-1-31
  *  @author aChuan
  *  例：<el-button v-throttle="[reset,`click`,300]">刷新</el-button>
  *  传递参数则：<el-button v-throttle="[()=>reset(param),`click`,300]">刷新</el-button>
  */
  'throttle': {
    inserted: function inserted(el, binding) {
      var _binding$value2 = _slicedToArray(binding.value, 3),
          fn = _binding$value2[0],
          _binding$value2$ = _binding$value2[1],
          event = _binding$value2$ === void 0 ? 'click' : _binding$value2$,
          _binding$value2$2 = _binding$value2[2],
          time = _binding$value2$2 === void 0 ? 300 : _binding$value2$2;

      var timer, timer_end;
      el.addEventListener(event, function () {
        if (timer) {
          clearTimeout(timer_end);
          return timer_end = setTimeout(function () {
            return fn();
          }, time);
        }

        fn();
        timer = setTimeout(function () {
          return timer = null;
        }, time);
      });
    }
  }
};
/**
 * [handleInput 在输入阶段的处理逻辑]
 * @param  {[DOM]} ele   [当前指令操作的dom对象]
 * @param  {[虚拟节点]} vnode [当前指令渲染的虚拟节点]
 * @param  {[指令信息]} rel   [当前指令的所有指令信息]
 * @param  {[校验类型]} type  [输入阶段的校验类型]
 *      "number": 仅支持输入数字
 *      "float": 仅支持数字和小数点
 */

function handleInput(ele, vnode, rel) {
  var rule;

  switch (true) {
    case rel.modifiers.float:
      // 浮点型
      rule = /[^\d\.]/g;
      break;

    default:
      // 默认仅支持输入数字
      rule = /\D/g;
  }

  var val = ele.value.replace(rule, '');
  var maxLen = vnode.data.attrs && vnode.data.attrs['max-len'] ? vnode.data.attrs['max-len'] : 0;

  if (maxLen > 0) {
    val = val.substr(0, maxLen);
  }

  setValueWithExpressionVue({
    currObj: vnode.context.$data,
    expression: rel.expression,
    value: val,
    key: vnode.key,
    arg: rel.arg,
    toString: rel.modifiers.string || rel.modifiers.float
  });
}
/**
 * [setValueWithExpressionVue 更新数据模型]
 * @param {Boolean} toString    [是否转化为字符串]
 * @param {[type]}  currObj    [当前的数据模型]
 * @param {[type]}  expression [指令表达式]
 * @param {[type]}  value      [指令的值]
 * @param {[type]}  key        [用于批量渲染时的跟踪键]
 * @param {[type]}  arg        [指令的参数]
 */


function setValueWithExpressionVue(option) {
  var expression = option.expression.split('.');
  expression.forEach(function (item, i) {
    if (i < expression.length - 1) {
      option.currObj = option.currObj[item];
    } else {
      if (option.key !== undefined) {
        option.currObj[item][option.key][option.arg] = option.value === '' || option.toString ? option.value : option.value * 1;
      } else {
        option.currObj[item] = option.value === '' || option.toString ? option.value : option.value * 1;
      }
    }
  });
}