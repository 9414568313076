export default {
  lang1: 'キャンセル',
  lang2: '送信',
  lang3: '確認',
  lang4: 'まだデータがありません',
  lang5: 'まだ記録がありません',
  lang6: 'コピー',
  lang7: '正常にコピー',
  lang8: 'コピーに失敗しました',
  lang9: '確認',
  lang10: 'スポット取引',
  lang11: 'アセット',
  lang12: 'リクエストに失敗しました！ネットワーク接続を確認してください',
  lang13: 'サイクル',
  lang14: 'トレード',
  lang15: 'マーケット',
  lang16: 'カスタマーサービスに連絡してください！',
  "theme": "テーマスキン"
};