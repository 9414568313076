import "core-js/modules/es.object.to-string";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import navFooter from '@/components/navFooter.vue';
import appUpdate from '@/components/appUpdate.vue';
export default {
  name: 'App',
  components: {
    navFooter: navFooter,
    appUpdate: appUpdate
  },
  data: function data() {
    return {
      transitionName: 'slide-left'
    };
  },
  computed: _objectSpread({}, mapState({
    showLoading: function showLoading(state) {
      return state.common.showLoading;
    },
    showFooter: function showFooter(state) {
      return state.common.showFooter;
    },
    isAppUpdate: function isAppUpdate(state) {
      return state.common.isAppUpdate;
    }
  })),
  watch: {
    $route: function $route(to, from) {
      if (to.meta.level < from.meta.level) {
        this.transitionName = 'slide-right';
      } else if (to.meta.level > from.meta.level) {
        this.transitionName = 'slide-left';
      }
    }
  },
  created: function created() {
    this.$store.dispatch('user/getUserInfo');
    this.$store.dispatch('wallet/getRate', 'USD');
  },
  mounted: function mounted() {
    var _this = this;

    try {
      fetch('/api/user/get_type_value?type=KF_ADDRESS_H5', {
        method: "GET",
        headers: {
          "Content-Type": "text/plain;charset=UTF-8",
          token: localStorage.getItem('token') || '',
          locale: localStorage.getItem('lang') || 'EN',
          type: 'KF_ADDRESS_H5'
        }
      }).then(function (response) {
        return response.json();
      }).then(function (data) {
        if (data.code == 200 && data.data) {
          _this.$store.state.common.serviceUrl = data.data;
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
};