import { post, fetch } from '@/service';
import store from '@/store';
var baseURL = '/api/';
export var getWaitpayList = function getWaitpayList(data) {
  return fetch("".concat(baseURL, "/rush/queryRushOrders"), data);
};
export var getGrabordersList = function getGrabordersList(data) {
  return fetch("".concat(baseURL, "/rush/").concat(data.viplevel, "/commoditys"), data);
}; // 抢单接口

export var getRushpurchase = function getRushpurchase(data) {
  return post("".concat(baseURL, "/rush/rushpurchase"));
}; // 提交订单

export var submitPsy = function submitPsy(data) {
  return post("".concat(baseURL, "/rush/pay"), data);
}; // 提交订单

export var getEarnings = function getEarnings() {
  return fetch("".concat(baseURL, "/rush/earnings"));
};