import "core-js/modules/es.object.to-string";
export default [{
  path: '/coinRecord/:data',
  name: 'coinRecord',
  component: function component() {
    return import('@/views/coinExchange/coinRecord');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/allRecord',
  name: 'allRecord',
  component: function component() {
    return import('@/views/coinExchange/allRecord');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/futuresRecord',
  name: 'futuresRecord',
  component: function component() {
    return import('@/views/futures/futuresRecord');
  },
  meta: {
    level: 2,
    requiresAuth: true
  }
}, {
  path: '/stopPl',
  name: 'stopPl',
  component: function component() {
    return import('@/views/futures/stopPl');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/cycleHistory',
  name: 'cycleHistory',
  component: function component() {
    return import('@/views/futures/cycleHistory');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}];