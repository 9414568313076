import { post, fetch } from '@/service';
import store from '@/store';
var baseURL = '/api/';

var showLoading = function showLoading() {
  store.dispatch('common/showLoading', true);
};

export var selectRate = function selectRate(data) {
  return fetch("".concat(baseURL, "/account/getExchangeRateByCoin"), data);
};
export var myAccountCount = function myAccountCount() {
  return fetch("".concat(baseURL, "/account/myAccountCount"));
};
export var selectExchangeAssets = function selectExchangeAssets() {
  return fetch("".concat(baseURL, "/account/myAccount"));
};
export var selectFuturesAssets = function selectFuturesAssets() {
  return fetch("".concat(baseURL, "/account/contractAssets"));
};
export var selectFaitAssets = function selectFaitAssets() {
  return fetch("".concat(baseURL, "/account/c2cAccount"));
};
export var rechargeCoins = function rechargeCoins() {
  return fetch("".concat(baseURL, "/account/coins"));
};
export var getCoinAddress = function getCoinAddress(data) {
  return fetch("".concat(baseURL, "/account/getRjCoinAddress"), data);
};
export var withdrawalTips = function withdrawalTips(data) {
  return fetch("".concat(baseURL, "/account/withdrawalTips"), data);
};
export var outCoin = function outCoin(data) {
  showLoading();
  return post("".concat(baseURL, "/account/outCoin"), data);
};
export var coinBalance = function coinBalance(data) {
  return fetch("".concat(baseURL, "/account/balance"), data);
};
export var tradBalance = function tradBalance(data) {
  return fetch("".concat(baseURL, "/account/tradBalance"), data);
};
export var contractBalance = function contractBalance(data) {
  return fetch("".concat(baseURL, "/account/contractBalance"), data);
};
export var c2cBalance = function c2cBalance(data) {
  return fetch("".concat(baseURL, "/account/c2cBalance"), data);
};
export var contractTransfer = function contractTransfer(data) {
  showLoading();
  return post("".concat(baseURL, "/account/contractTransfer"), data);
};
export var c2cTransfer = function c2cTransfer(data) {
  showLoading();
  return post("".concat(baseURL, "/account/c2cTransfer"), data);
};
export var financeByTypeCoin = function financeByTypeCoin(data) {
  return fetch("".concat(baseURL, "/account/financeByTypeCoin"), data);
};
export var toAddressList = function toAddressList() {
  return fetch("".concat(baseURL, "/account/toAddressList"));
};
export var addToAddress = function addToAddress(data) {
  showLoading();
  return post("".concat(baseURL, "/account/addToAddress"), data);
};
export var removeToAddress = function removeToAddress(data) {
  showLoading();
  return post("".concat(baseURL, "/account/removeToAddress"), data);
};
export var flashCoin = function flashCoin() {
  return fetch("".concat(baseURL, "/flash/flashCoin"));
};
export var flashOrder = function flashOrder(data) {
  return fetch("".concat(baseURL, "/flash/flashOrder"), data);
};
export var addFlash = function addFlash(data) {
  showLoading();
  return post("".concat(baseURL, "/flash/addFlash"), data);
};
export var ieoList = function ieoList() {
  return fetch("".concat(baseURL, "/ieo/getIeoList"));
};
export var findPayCoinByCoin = function findPayCoinByCoin(data) {
  return fetch("".concat(baseURL, "/ieo/findPayCoinByCoin"), data);
};
export var findGasById = function findGasById(data) {
  return fetch("".concat(baseURL, "/ieo/findGasById"), data);
};
export var getIeoOrder = function getIeoOrder(data) {
  return fetch("".concat(baseURL, "/ieo/getIeoOrder"), data);
};
export var addIeoOrder = function addIeoOrder(data) {
  showLoading();
  return post("".concat(baseURL, "/ieo/addIeoOrder"), data);
};
export var fiatCoin = function fiatCoin() {
  return fetch("".concat(baseURL, "/fiat/fiatCoin"));
};
export var payCoinList = function payCoinList() {
  return fetch("".concat(baseURL, "/fiat/payCoinList"));
};
export var addFiatPayOrder = function addFiatPayOrder(data) {
  showLoading();
  return post("".concat(baseURL, "/fiat/addFiatPayOrder"), data);
};
export var fiatOrderList = function fiatOrderList(data) {
  return fetch("".concat(baseURL, "/fiat/fiatOrderList"), data);
};
export var rgaddFiatPayOrder = function rgaddFiatPayOrder(data) {
  showLoading();
  return post("".concat(baseURL, "/fiat/rgaddFiatPayOrder"), data);
};
export var orderUndo = function orderUndo(data) {
  showLoading();
  return post("".concat(baseURL, "/account/out_revoke"), data);
};