export default {
  lang1: 'Inicio',
  lang2: 'Suscripción de nueva moneda de IEO',
  lang3: 'Los tokens de colección se lanzarán diariamente',
  lang4: 'Encubierto',
  lang5: 'No abierto',
  lang6: 'Terminado',
  lang7: 'Precio',
  lang8: 'Hora de inicio',
  lang9: 'Total emitido',
  lang10: 'Hora de finalización',
  lang11: 'Cantidad excedente',
  lang12: 'Período de bloqueo',
  lang13: 'D',
  lang14: 'Comprar {moneda}',
  lang15: 'Moneda de pago',
  lang15a: 'Pagar',
  lang16: 'equilibrio',
  lang17: 'Usar todo',
  lang18: 'Ingrese la cantidad de compra',
  lang19: 'Pagar:',
  lang20: 'Regalo:',
  lang21: 'Registro de pedido',
  lang22: 'Liberar',
  lang23: 'Promesas de la OEI',
  lang24: 'Reembolso',
  lang25: 'Ingrese la cantidad de compra correcta',
  lang26: 'El total restante es insuficiente',
  lang27: '¿Estás seguro de cambiar {número}{moneda}?',
  lang28: 'Aviso',
  lang29: 'Sin aviso todavía',
  lang30: 'Detalles del aviso',
  lang31: 'Ingrese el token para buscar',
  lang32: 'Minería todos los días',
  lang33: 'Cuanto mayor sea la fuerza, más minería',
  lang34: 'Trato',
  lang35: 'Creciente',
  lang36: 'Rechazar',
  lang37: 'Punto',
  lang38: 'Futuros',
  lang39: 'Ciclo',
  lang40: 'Favoritos',
  lang41: 'Todos',
  lang42: 'Mian',
  lang43: 'Siguiente',
  lang44: 'Vol',
  lang45: 'Precio',
  lang46: 'Cambiar%',
  lang47: 'Minería bloqueada',
  lang48: 'Fondos administrados',
  lang49: 'Ganancias de hoy',
  lang50: 'Ingresos acumulados',
  lang51: 'Pedido',
  lang52: 'Rendimiento diario',
  lang53: 'día',
  lang54: 'Confirmar pedido',
  lang55: 'Beneficio',
  lang56: 'Ciclo',
  lang57: 'Límite único',
  lang58: 'Tiempo de dividendo',
  lang59: 'Diario',
  lang60: 'Cantidad de bloqueo',
  lang61: 'devolución vencida',
  lang62: 'Canje anticipado',
  lang63: 'Ingrese el monto del depósito en garantía',
  lang64: 'Ingresos estimados',
  lang65: 'Ingrese el monto correcto del depósito en garantía',
  lang66: 'Saldo de cuenta insuficiente',
  lang67: 'La cantidad no está dentro del límite único',
  lang68: 'Bloqueo de registro',
  lang69: 'Corriendo',
  lang70: 'Completado',
  lang71: 'Redimido',
  lang72: 'Ingresos acumulados',
  lang73: 'Tiempo de gestión',
  lang74: 'Tiempo de caducidad',
  lang75: 'Determine la redención anticipada de la custodia, se deducirá la tarifa de manejo correspondiente para la redención anticipada'
};