export default {
  lang1: 'Withdrawal',
  lang2: 'Account Details',
  lang3: 'Record',
  lang4: 'orders',
  lang5: 'Membership Level',
  lang6: 'Amount Required',
  lang7: 'Commission per order',
  lang8: 'Number of orders',
  lang9: 'Join',
  lang10: 'Available balance',
  lang11: 'Freeze Balance',
  lang12: 'Pending',
  lang13: 'Paid',
  lang14: 'Settled',
  lang15: 'No data yet',
  lang16: 'Order Total',
  lang17: 'commission',
  lang18: 'Submit Order',
  lang19: 'Order Number',
  lang20: 'Product yield',
  lang21: 'Current Balance',
  lang22: 'Calculation formula',
  lang23: 'Profit from this order',
  lang24: 'submit',
  lang25: 'Grab the order now',
  lang26: 'Get commission',
  lang27: 'Today is Tasks',
  lang28: 'Completed today',
  lang29: 'Procedure',
  lang30: 'Click the "Start Task" button and follow the prompts to complete the task.',
  lang31: 'After completing the task, you can settle the commission to the balance',
  lang32: 'unit price',
  lang33: 'Rebate',
  lang34: 'Order in progress',
  lang35: 'Cancel queue',
  lang36: 'Due to the large number of users competing for orders at the current level, the system is working hard to assign orders. It is currently ranked 11th. Please wait patiently.',
  lang37: 'Tips: Upgrading your VIP level can help you get orders faster',
  lang38: 'If you have any questions or need help, please contact online customer service',
  lang39: 'Click me to contact your exclusive customer service⭣⭣⭣',
  lang40: 'FAQ',
  lang41: 'How to top up and what payment methods are there?',
  lang42: 'This system is open to multiple countries. We provide international payment methods and support local currencies for different countries.',
  lang43: 'How can I invest and earn income?',
  lang44: 'You can grab orders in the system and get stable automatic order-placing income and interest every day. All earnings can be withdrawn to your bank card.',
  lang45: 'How to register as a member?',
  lang46: 'New members are registered by invitation. In order to ensure the benefits of registered members and prevent excessive registration from reducing benefits, new members can only register under the invitation of existing members.',
  lang47: 'Is there a limit on the purchase amount?',
  lang48: 'Yes, in order to allow more members to have the opportunity to gain benefits, each account in the system has a minimum purchase amount limit.',
  lang49: 'What should I do if I encounter problems during operation?',
  lang50: 'You can contact online customer service at any time to assist in completing all operations.',
  lang51: 'Contact Customer Service',
  lang52: 'welcome',
  lang53: 'top up',
  lang54: 'Deposit and Withdrawal Records',
  lang55: 'My Team',
  lang56: 'Invite friends',
  lang57: 'Withdrawal bank card',
  lang58: 'Security Center',
  lang59: 'set up',
  lang60: 'Bank Name',
  lang61: 'help',
  lang62: 'my',
  lang63: 'Order History',
  lang64: 'Customer Service',
  lang65: 'FLASHOP',
  lang66: 'Couple Activity',
  lang67: 'Bind Couple',
  lang68: 'A reaches recharge amount',
  lang69: 'B reaches recharge amount',
  lang70: 'Can be claimed',
  lang71: 'Participate',
  lang72: 'Activity Rules',
  lang73: "Couples need to provide each other's ID for binding. After successful binding, both parties can claim corresponding rewards to their own accounts. Each account can only be bound once and once bound cannot be bound again. Through the couple binding activity, you can enjoy reward activities with your partner and deepen your relationship with each other. We wish you a pleasant experience in the activity!",
  lang74: 'Process Description',
  lang75: "User A enters User B's ID, obtains consent, system verifies binding information and checks both users' binding status, after both parties agree, binding is successful.",
  lang76: 'Please enter couple ID',
  lang77: 'Cancel',
  lang78: 'Confirm',
  lang79: 'Forever and ever',
  lang80: 'Eternal love',
  lang81: 'I only love you',
  lang82: 'Never apart',
  lang83: 'Together forever',
  lang84: 'Asset Details',
  lang85: 'Order Rules',
  lang86: 'Platform Introduction',
  lang87: 'Asset Details',
  lang88: 'Asset Details',
  lang89: "Don't have an account yet?",
  lang90: 'Number of Orders Grabbed',
  lang91: 'Account',
  lang92: "Today's Results",
  lang93: 'Commission',
  lang94: 'Platform Operating Rules',
  lang95: '1. This platform uses an AI system for more convenient and faster service for merchants and order customers;',
  lang96: '2. This platform serves over 100,000 e-commerce merchants worldwide who cooperate with FLANNELS. To increase product exposure, merchants display their products for sale on this platform. After completing the order, merchants immediately refund the order amount and commission. The platform randomly matches order customers with merchant orders. The commission is related to the order size and VIP level;',
  lang97: 'The number of orders depends on the VIP level. The higher the VIP level, the more orders and higher profits;',
  lang98: '3. The platform system determines VIP level based on account balance and upgrades/downgrades order customers;',
  lang99: '4. Order customers can only complete the corresponding VIP level order quantity once per day. Order customers can withdraw money only after completing the corresponding VIP level order quantity. After completing the daily order, if users want to continue earning commissions, they can either increase their VIP level or wait for the system reset of orders the next day;',
  lang100: '5. To motivate order customers, the platform offers lucky orders that are randomly matched;',
  lang101: 'Each customer has the chance to receive three reward orders, with the reward being 20%-35% of the order amount',
  lang102: '① The first lucky order rewards 20% of the order amount.',
  lang103: '② The second lucky order rewards 30% of the order amount.',
  lang104: '③ The third lucky order rewards 35% of the order amount.',
  lang105: '6. Privacy Policy: The FLANNELS platform respects the privacy of every order customer and places special emphasis on protecting order customers\' privacy;',
  lang106: 'The FLANNELS platform reserves the right of final interpretation, users are requested to comply with the rules',
  lang107: 'FLANNELS was founded in 1976 in Northern England and is now one of the most exciting players in the industry. The brand now has over 50 stores in the UK and carries ready-to-wear, accessories, footwear and newly launched youth and beauty collections. FLANNELS Beauty launched in 2021 and is committed to disrupting beauty retail with its "consumer first" approach.',
  lang108: 'From FLANNELS Beauty Bars to FLANNELS Beauty Changing Rooms, it brings luxury beauty to regions with state-of-the-art stores and first-class experiences. Backed by the next generation of retail leaders, incoming CEO Michael Murray is taking FLANNELS to a new level and bringing its unparalleled offering to regional cities. As Group Head of Elevation, Murray has shaken up the industry - "There\'s unprecedented demand for the type of luxury we do best.',
  lang109: 'FLANNELS continues to disrupt and rethink retail, impressively demonstrating our vision and providing world-class shopping destinations for Britain\'s major cities." FLANNELS is a super-inclusive, multi-category retailer with established and emerging brands in luxury fashion and beauty, committed to reviving the in-store experience and making luxury accessible to everyone.'
};