export default {
  lang1: 'Annulla',
  lang2: 'Invia',
  lang3: 'Conferma',
  lang4: 'Nessun dato ancora',
  lang5: 'Nessun record ancora',
  lang6: 'Copia',
  lang7: 'Copia riuscita',
  lang8: 'Copia non riuscita',
  lang9: 'Conferma',
  lang10: 'Punto',
  lang11: 'Attività',
  lang12: 'Richiesta non riuscita! Verificare la connessione di rete',
  lang13: 'Ciclo',
  lang14: 'Commercio',
  lang15: 'Mercato',
  lang16: 'Si prega di contattare il servizio clienti!',
  "theme": "Pelle del soggetto"
};