import { selectUserInfo, getInvitationUrl } from '@api/user';
var state = {
  userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
  invitedData: {} // 邀请好友数据

};
var mutations = {
  USER_INFO: function USER_INFO(state, val) {
    state.userInfo = val;
  },
  INVITED_DATA: function INVITED_DATA(state, val) {
    state.invitedData = val;
  }
};
var actions = {
  // 获取 用户信息  缓存中有数据就直接取  不再调用
  getUserInfo: function getUserInfo(_ref) {
    var commit = _ref.commit;

    if (this.state.common.isLogin) {
      selectUserInfo().then(function (res) {
        localStorage.setItem('userInfo', JSON.stringify(res.data));
        commit('USER_INFO', res.data);
      });
    } else {
      commit('USER_INFO', {});
    }
  },
  getInvitedData: function getInvitedData(_ref2) {
    var commit = _ref2.commit;
    getInvitationUrl().then(function (res) {
      commit('INVITED_DATA', res.data);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};