import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.string.includes";
import "core-js/modules/web.dom-collections.for-each";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/nav-bar/style";
import _NavBar from "vant/es/nav-bar";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/swipe/style";
import _Swipe from "vant/es/swipe";
import "vant/es/swipe-item/style";
import _SwipeItem from "vant/es/swipe-item";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/pull-refresh/style";
import _PullRefresh from "vant/es/pull-refresh";
import "vant/es/lazyload/style";
import _Lazyload from "vant/es/lazyload";
import "vant/es/list/style";
import _List from "vant/es/list";
import "vant/es/tab/style";
import _Tab from "vant/es/tab";
import "vant/es/tabs/style";
import _Tabs from "vant/es/tabs";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/overlay/style";
import _Overlay from "vant/es/overlay";
import "vant/es/checkbox/style";
import _Checkbox from "vant/es/checkbox";
import "vant/es/index-bar/style";
import _IndexBar from "vant/es/index-bar";
import "vant/es/index-anchor/style";
import _IndexAnchor from "vant/es/index-anchor";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/password-input/style";
import _PasswordInput from "vant/es/password-input";
import "vant/es/number-keyboard/style";
import _NumberKeyboard from "vant/es/number-keyboard";
import "vant/es/uploader/style";
import _Uploader from "vant/es/uploader";
import "vant/es/stepper/style";
import _Stepper from "vant/es/stepper";
import "vant/es/count-down/style";
import _CountDown from "vant/es/count-down";
import "vant/es/action-sheet/style";
import _ActionSheet from "vant/es/action-sheet";
import "vant/es/area/style";
import _Area from "vant/es/area";
import "vant/es/switch/style";
import _Switch from "vant/es/switch";
import "vant/es/divider/style";
import _Divider from "vant/es/divider";
import "vant/es/sticky/style";
import _Sticky from "vant/es/sticky";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import "vant/es/collapse/style";
import _Collapse from "vant/es/collapse";
import "vant/es/collapse-item/style";
import _CollapseItem from "vant/es/collapse-item";
import Vue from 'vue';
import router from './router';
import store from './store/index';
import 'amfe-flexible';
import '@/assets/js/plus'; // 加载plus文件

import '@/service/websocket.js'; // 按需引入vant

Vue.use(_NavBar).use(_Tabbar).use(_TabbarItem).use(_Swipe).use(_SwipeItem).use(_Popup).use(_Button).use(_PullRefresh).use(_Lazyload).use(_List).use(_Tab).use(_Tabs).use(_Image).use(_Loading).use(_Overlay).use(_Checkbox).use(_IndexBar).use(_IndexAnchor).use(_Cell).use(_Field).use(_PasswordInput).use(_NumberKeyboard).use(_Uploader).use(_Stepper).use(_CountDown).use(_ActionSheet).use(_Area).use(_Switch).use(_Divider).use(_Sticky).use(_Icon).use(_Dialog).use(_Collapse).use(_CollapseItem);

_Toast.setDefaultOptions({
  duration: 2000,
  position: 'bottom',
  getContainer: function getContainer() {
    return document.getElementById('app');
  }
}); //设定全局图片颜色主题


store.state.common.theme = localStorage.getItem('theme') || 'yellow'; // 引入全部样式

import 'vant/lib/index.less';
import '../src/assets/css/common.scss'; // 公共样式

import '../src/assets/css/vant-common.scss'; // 公共样式

import i18n from '@/i18n/index';
import navHeader from '@/components/navHeader.vue';
Vue.component(navHeader.name, navHeader);
import App from '@/App.vue';
import utils from '@/assets/js/utils';
import filter from '@/assets/js/filter';
import directives from '@/assets/js/directive'; // 过滤器

Object.keys(filter).forEach(function (key) {
  Vue.filter(key, filter[key]);
}); // 全局指令

Object.keys(directives).forEach(function (key) {
  Vue.directive(key, directives[key]);
});
Object.assign(Vue.prototype, {
  $utils: utils,
  goBack: function goBack(step) {
    if (window.history.length > 1) {
      router.go(step || -1);
    } else {
      router.push('/');
    }
  },
  pushPath: function pushPath(path, query) {
    router.push({
      path: path,
      query: query || {}
    });
  },
  pushState: function pushState(path) {
    if (!window.location.href.includes(path)) {
      window.location.href = "".concat(window.location.href).concat(path);
    }
  }
});
Vue.config.productionTip = false; // 浏览器回退事件

window.onpopstate = function () {
  store.dispatch('common/showLoading', false);
};

window.vue = new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');