export default {
  lang1: 'Annuler',
  lang2: 'Soumettre',
  lang3: 'Confirmer',
  lang4: 'Pas encore de données',
  lang5: "Pas encore d'enregistrement",
  lang6: 'Copier',
  lang7: 'Copie réussie',
  lang8: 'Échec de la copie',
  lang9: 'Confirmer',
  lang10: 'Spot',
  lang11: 'Actifs',
  lang12: 'La requête a échoué ! Veuillez vérifier la connexion réseau',
  lang13: 'Cycle',
  lang14: 'Commerce',
  lang15: 'Marché',
  lang16: 'Veuillez contacter le service client !',
  "theme": "Thème des skins"
};