export default {
  lang1: 'Spot',
  lang2: 'Vertrag',
  lang3: 'Name',
  lang4: 'Neuester Preis',
  lang5: 'Erhöhen',
  lang6: 'Menge',
  lang7: 'Preis',
  lang8: 'Time',
  lang9: '1min',
  lang9a: '5min',
  lang10: '15min',
  lang10a: '30min',
  lang11: '1h',
  lang12: '4h',
  lang13: '1D',
  lang13a: '1W',
  lang14: 'Indikator',
  lang14a: 'Mehr',
  lang15: 'Hoch',
  lang16: 'niedrig',
  lang17: '24H',
  lang18: 'Standplan',
  lang19: 'Deal',
  lang20: 'Einführung',
  lang21: 'Zeit',
  lang22: 'Richtung',
  lang23: 'Kaufen',
  lang24: 'Verkaufen',
  lang25: 'Gesamt ausgegeben',
  lang26: 'Offizielle Website',
  lang27: 'Block-Explorer',
  lang28: 'Mehr öffnen',
  lang29: 'Leer öffnen',
  lang30: 'Währung suchen',
  lang31: 'Kürzlich angesehen',
  lang32: 'Es gibt keine zugehörige Währung',
  lang33: 'Diese Währung ist noch nicht für den Handel geöffnet',
  lang34: 'Umwandlung des gesamten Kontovermögens',
  lang35: 'Geld aufladen',
  lang36: 'Auszahlung',
  lang37: 'übertragen',
  lang38: 'Austausch',
  lang39: 'Wallet-Asset-Konvertierung',
  lang40: 'Kleingeld ausblenden',
  lang41: 'Suchen',
  lang42: 'Verfügbar',
  lang43: 'Einfrieren',
  lang44: 'Konvertieren',
  lang45: 'Menge halten',
  lang46: 'Positionsspanne',
  lang47: 'realisierte Gewinne und Verluste',
  lang48: 'Anvertraute Marge',
  lang49: 'Gesamtvermögen',
  lang50: 'Aufladen',
  lang51: 'Auszahlung',
  lang52: 'Andere',
  lang53: 'Status:',
  lang54: 'Adresse:',
  lang55: 'Warten',
  lang56: 'Abgeschlossen',
  lang57: 'abgelehnt',
  lang58: 'Währung wechseln',
  lang59: 'Kettenname',
  lang60: 'Einzahlungsadresse',
  lang61: 'Bitte überprüfen und füllen Sie das Adressetikett sorgfältig aus, wenn das Etikett weggelassen wird, geht das Geld verloren',
  lang62: 'Hinterlegen Sie keine Nicht-{Coin}-Assets an die obige Adresse, sonst werden die Assets nicht wiederhergestellt',
  lang63: 'Mindesteinzahlungsbetrag {low}{coin}, Einzahlungen unter dem Mindesteinzahlungsbetrag werden nicht gutgeschrieben',
  lang64: 'Wallet-Adresse',
  lang65: 'Bitte geben Sie die Wallet-Adresse ein oder fügen Sie sie ein',
  lang66: 'Bitte geben Sie den Auszahlungsbetrag ein',
  lang67: 'Alle',
  lang68: 'Gebühren',
  lang69: 'Betrag auf Konto',
  lang70: 'Hinweis: Die maximale Auszahlung ist einmal {oneoutheight}, die minimale Auszahlung ist {low} und die maximale Auszahlung ist {height} pro Tag',
  lang71: 'Bitte geben Sie das Fondspasswort ein',
  lang72: 'Funding-Passwort',
  lang73: 'Wallet-Adresse',
  lang74: 'Sie haben die Wallet-Adresse dieser Währung nicht hinzugefügt',
  lang75: 'Zum Hinzufügen gehen',
  lang76: 'Authentifizierung',
  lang77: 'Bitte führen Sie zuerst die Identitätsauthentifizierung durch',
  lang78: 'Zur Authentifizierung gehen',
  lang79: 'Bitte legen Sie zuerst das Kontoguthaben-Passwort fest',
  lang80: 'Gehe zu den Einstellungen',
  lang81: 'Unzureichendes Guthaben!',
  lang82: 'Bitte verwenden Sie den Scan in der APP',
  lang83: 'Scannen und scannen',
  lang84: 'übertragen an',
  lang85: 'Währung',
  lang86: 'Bitte Überweisungsbetrag eingeben',
  lang87: 'Nur die Vermögenswerte können gehandelt werden, indem die Vermögenswerte auf das entsprechende Konto übertragen werden',
  lang88: 'Französische Währung',
  lang89: 'Unzureichender Kontostand',
  lang90: 'Wechselkurs:',
  lang91: 'Hinweis: Der minimale Wechselkurs ist {low} und der maximale Wechselkurs ist {height}.',
  lang92: 'Datensatz austauschen',
  lang93: 'Austausch',
  lang94: 'Verbrauch',
  lang95: 'auf das Konto',
  lang96: 'Bitte geben Sie den korrekten Wechselbetrag ein',
  lang97: 'Abzugsadresse',
  lang98: 'Die Auszahlungsadresse wurde nicht hinzugefügt~',
  lang99: 'Abhebungsadresse hinzufügen',
  lang100: 'Abzugsadresse',
  lang101: 'Bitte geben Sie die Adresse ein',
  lang102: 'Bemerkungen',
  lang103: 'Bitte geben Sie eine Notiz ein',
  lang104: 'Wollen Sie die Adresse wirklich löschen?',
  lang105: 'Coin-Transaktion',
  lang106: 'Zum aktuellen Bestpreis handeln',
  lang107: 'Menge',
  lang108: 'Strom',
  lang108a: 'Mehr anzeigen',
  lang109: 'Grenzpreis',
  lang110: 'Marktpreis',
  lang111: 'Abbrechen',
  lang112: 'Bestellpreis',
  lang113: 'Delegationszeit',
  lang114: 'Delegierter Betrag',
  lang115: 'Nicht gehandeltes',
  lang116: 'Gesamt beauftragt',
  lang117: 'Status',
  lang118: 'Nicht gehandelt',
  lang119: 'Teilangebot',
  lang120: 'Alle Transaktionen',
  lang121: 'Alle stornieren',
  lang122: 'Teilweises Rückgängigmachen',
  lang123: 'Bitte geben Sie den Stückpreis ein',
  lang124: 'Bitte geben Sie den korrekten Stückpreis ein',
  lang125: 'Bitte geben Sie einen Stückpreis größer als 0 ein',
  lang126: 'Bitte geben Sie die Menge ein',
  lang127: 'Bitte geben Sie die richtige Menge ein',
  lang128: 'Der Mindestkaufbetrag beträgt {min}',
  lang129: 'Bestandsmenge überschritten',
  lang130: 'Kauf von {coin} bestätigen',
  lang131: 'Der Mindestverkaufsbetrag beträgt {min}',
  lang132: 'Bestätige den Verkauf von {coin}',
  lang133: 'Bestätigen, die Delegierung abzubrechen?',
  lang134: 'Historisch',
  lang135: 'Offene Stelle',
  lang136: 'Position',
  lang137: 'Position schließen',
  lang138: 'Mehr öffnen',
  lang139: 'Leer öffnen',
  lang140: 'Hand',
  lang141: 'Take-Profit-Preis',
  lang142: 'Stop-Loss-Preis',
  lang143: 'Marge',
  lang144: 'Derzeit delegiert',
  lang145: 'Lang',
  lang146: 'Kurz',
  lang147: 'Isoliert',
  lang148: 'volles',
  lang149: 'Aktueller Preis',
  lang150: 'Eröffnungskurs',
  lang151: 'Position',
  lang152: 'Nicht gehandelt',
  lang153: 'Typ',
  lang154: 'Erzwungene Parität',
  lang155: 'Gewinn und Verlust',
  lang155a: 'Lagerzinsen',
  lang156: 'Positionswert',
  lang157: 'stoppen P/L',
  lang158: 'schnell schließen',
  lang159: 'Schließung',
  lang160: 'Behalten',
  lang161: 'Positionsspanne',
  lang162: 'Anzahl geschlossener Positionen',
  lang163: 'Bitte geben Sie den Abschlussbetrag ein',
  lang164: 'Unbefristeter Vertrag',
  lang165: 'flach alles',
  lang166: 'Long-Positionen schließen',
  lang167: 'Short-Position schließen',
  lang168: 'Bitte Stückpreis eingeben',
  lang169: 'Bitte geben Sie den korrekten Stückpreis ein',
  lang170: 'Bitte geben Sie die Menge ein',
  lang171: 'Bitte geben Sie eine Ganzzahl ein',
  lang172: 'Der maximale Kaufbetrag beträgt {max}',
  lang173: 'Der maximale Verkaufsbetrag ist {max}',
  lang174: 'Der Take-Profit-Preis darf nicht niedriger sein als der Preis der offenen Long-Position',
  lang175: 'Der Stop-Loss-Preis darf nicht höher sein als der Preis der offenen Long-Position',
  lang176: 'Der Take-Profit-Preis darf nicht höher sein als der Preis der offenen Short-Position',
  lang177: 'Der Stop-Loss-Preis darf nicht niedriger sein als der Short-Eröffnungspreis',
  lang178: 'Ob {tradeNum} {tradeCoin} long eröffnet werden soll, die Marge liegt bei etwa {bondNum}{mainCoin}',
  lang179: 'Ob {tradeNum} {tradeCoin} short eröffnet werden soll, die Marge liegt bei etwa {bondNum}{mainCoin}',
  lang180: 'Schnelles Schließen bestätigen?',
  lang181: 'Vertragsakte',
  lang182: 'Positionsauftrag',
  lang183: 'Historische Position',
  lang184: 'Aktuell delegiert',
  lang185: 'Historische Kommission',
  lang186: 'Aktueller Schlusskurs',
  lang187: 'Schließzeit',
  lang188: 'Ein-Klick-Schließen bestätigen?',
  lang189: 'Vertragsname',
  lang190: 'Aktueller Preis',
  lang191: 'Durchschnittlicher Eröffnungspreis',
  lang192: 'Positionsrichtung',
  lang193: 'Anzahl geschlossener Positionen',
  lang194: 'Gewinnmitnahmepreis',
  lang195: 'Geschätzter Umsatz:',
  lang196: 'Stop-Loss-Kurs',
  lang197: 'Bitte geben Sie den Take-Profit-Preis ein',
  lang198: 'Bitte geben Sie den Stop-Loss-Kurs ein',
  lang199: 'Gewinnmitnahme- und Stop-Loss-Liste',
  lang200: 'Ändern',
  lang201: 'Bitte geben Sie den korrekten Betrag ein, um die Position zu schließen',
  lang202: 'Bitte geben Sie den korrekten Take-Profit-Preis ein',
  lang203: 'Der Take-Profit-Preis muss höher sein als der Eröffnungspreis',
  lang204: 'Der Take-Profit-Preis muss niedriger sein als der Eröffnungspreis',
  lang205: 'Bitte geben Sie den korrekten Stop-Loss-Preis ein',
  lang206: 'Der Stop-Loss-Kurs muss höher sein als der Eröffnungskurs',
  lang207: 'Der Stopppreis muss niedriger sein als der Eröffnungspreis',
  lang208: 'Bitte geben Sie den Take-Profit-Preis oder den Stop-Loss-Preis ein',
  lang209: 'Ich bin der Gott des Währungskreises',
  lang210: 'Scheitern ist die Mutter des Erfolgs',
  lang211: 'Ertrag',
  lang212: 'Austausch',
  lang213: 'Schlusskurs',
  lang214: 'aufholen',
  lang215: 'Jenseits',
  lang216: 'Führend',
  lang217: 'Bild speichern',
  lang218: 'Bild erfolgreich gespeichert',
  lang219: 'Bild konnte nicht gespeichert werden',
  lang220: 'Kaufmenge',
  lang221: 'Bitte geben Sie die Abnahmemenge ein',
  lang222: 'Zahlungsmethode',
  lang223: 'Geschätzte Zahlung:',
  lang224: 'Hinweis: Der Mindestkaufbetrag ist {low}{coin} und der Höchstkaufbetrag ist {high}{coin}',
  lang225: 'zahlen gehen',
  lang226: 'Kaufdatensatz',
  lang227: 'Zu bezahlen',
  lang228: 'Abgeschlossen',
  lang229: 'Abgesagt',
  lang230: 'Bitte geben Sie die korrekte Abnahmemenge ein',
  lang231: 'Die Abnahmemenge darf nicht unter der Mindestabnahmemenge liegen',
  lang232: 'Die Abnahmemenge darf nicht höher sein als die maximale Abnahmemenge',
  lang233: 'Einnahmen',
  lang234: 'Ertrag',
  lang235: 'Aufkaufen',
  lang236: 'Kaufen',
  lang237: 'Positionsauftrag',
  lang238: 'Preis',
  lang239: 'Aktueller Preis',
  lang240: 'Gesamtbetrag',
  lang241: 'Gewinn und Verlust',
  lang242: 'Kreislauf',
  lang243: 'Bearbeitungsgebühr',
  lang244: 'Historisch',
  lang245: 'Schlusskurs',
  lang246: 'Schließzeit',
  lang247: 'Profit-Order',
  lang248: 'Zeit',
  lang249: 'Richtung',
  lang250: 'Gewinn',
  lang251: 'Schlusskurs',
  lang252: 'Periodischer Vertrag',
  lang253: 'Vermögenswerte',
  lang254: 'Balance',
  lang255: 'Menge',
  lang256: 'Minimum {min}{coin}',
  lang257: 'Erwartete Rückgabe:',
  lang258: 'Erwartete Rückgabe:',
  lang259: 'Bitte geben Sie die Transaktionsmenge ein',
  lang260: 'Bitte geben Sie die richtige Menge ein',
  lang261: 'Unzureichendes Guthaben',
  lang262: 'Zahl muss größer als {min} sein',
  lang263: 'Anzahl muss kleiner als {max} sein',
  lang264: 'Die Websocket-Push-Daten sind nicht normal, bitte versuchen Sie es später erneut!',
  lang265: 'Die Websocket-Verbindung ist nicht normal, bitte versuchen Sie es später erneut!',
  lang266: 'Gewinn',
  lang267: 'Verlust',
  lang268: 'Preis:',
  lang269: 'Bestellmenge:',
  lang270: 'Countdown:',
  lang271: 'Alle historischen Positionen',
  lang272: 'Auftragsverwaltung',
  lang273: 'Schallplatte',
  lang274: 'Lautstärke',
  lang275: 'Einzahlungsbetrag',
  lang276: 'Bitte geben Sie den Einzahlungsbetrag ein',
  lang277: 'Einzahlungsauftrag',
  lang278: 'Bitte geben Sie einen Einzahlungsauftrag ein',
  lang279: 'Das heutige Ergebnis',
  lang280: 'Gesamteinnahmen',
  lang281: 'Karteninhaber'
};