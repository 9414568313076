var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "div",
        {
          staticClass: "season-header",
          on: {
            click: function($event) {
              return _vm.pushPath("/graborders")
            }
          }
        },
        [
          _c("h1", [_vm._v("STEP OUT IN STYLE THIS SEASON:")]),
          _c("h2", [_vm._v("DISCOVER OVER 150 NEW SEASON STYLES")])
        ]
      ),
      _vm._m(0),
      _c("div", { staticClass: "main_content quotation_details" }, [
        _c(
          "div",
          { ref: "homeBox" },
          [
            _c("div", { staticClass: "sub_banner banner1" }, [
              _c("img", {
                attrs: {
                  src: require("@img/home/home_banner_1.png").default,
                  alt: ""
                }
              })
            ]),
            _c("div", { staticClass: "nav-buttons" }, [
              _c(
                "div",
                {
                  staticClass: "nav-btn",
                  on: { click: _vm.showOnlineService }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "svg_icon menu_icon",
                      attrs: { viewBox: "0 0 1024 1024" }
                    },
                    [_c("use", { attrs: { "xlink:href": "#chongzhi" } })]
                  ),
                  _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang50")))])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "nav-btn",
                  on: {
                    click: function($event) {
                      return _vm.pushPath("/coinWithdraw")
                    }
                  }
                },
                [
                  _c("svg", { staticClass: "svg_icon menu_icon" }, [
                    _c("use", { attrs: { "xlink:href": "#withdraw" } })
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("tradeLang.lang51")))])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "nav-btn",
                  on: {
                    click: function($event) {
                      return _vm.pushPath("/orderRules")
                    }
                  }
                },
                [
                  _c("svg", { staticClass: "svg_icon menu_icon" }, [
                    _c("use", { attrs: { "xlink:href": "#zhanghu" } })
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("addLang.lang85")))])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "nav-btn",
                  on: {
                    click: function($event) {
                      return _vm.pushPath("/platform")
                    }
                  }
                },
                [
                  _c("svg", { staticClass: "svg_icon menu_icon" }, [
                    _c("use", { attrs: { "xlink:href": "#pingtai" } })
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.$t("addLang.lang86")))])
                ]
              )
            ]),
            _vm._m(1),
            _c(
              "van-swipe",
              {
                staticClass: "banner_box",
                attrs: { autoplay: 5000, duration: 1000 }
              },
              _vm._l(_vm.banner1List, function(image, index) {
                return _c("van-swipe-item", { key: index }, [
                  _c("div", { staticClass: "banner_img" }, [
                    _c("img", { attrs: { src: image.banner.default } })
                  ])
                ])
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "container" },
              [
                _vm._m(2),
                _vm._l(_vm.viplevList, function(item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass: "vip-warp",
                        class: "vip-" + "" + item.levlabel,
                        style: _vm.getBackgroundStyle(item.levlabel)
                      },
                      [
                        _c("div", { staticClass: "text-warp" }, [
                          _c("div", { staticClass: "vip-info" }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("addLang.lang6")) +
                                  ": " +
                                  _vm._s(
                                    _vm._f("mathFloor")(item.rechargeAmount, 0)
                                  )
                              )
                            ]),
                            item.levlabel === "6"
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("addLang.lang7")) +
                                      ": " +
                                      _vm._s(
                                        _vm._f("mathFloor")(
                                          item.rebate * 100,
                                          1
                                        )
                                      ) +
                                      "% "
                                  )
                                ])
                              : _vm._e(),
                            item.levlabel === "5"
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("addLang.lang7")) +
                                      ": " +
                                      _vm._s(
                                        _vm._f("mathFloor")(
                                          item.rebate * 100,
                                          1
                                        )
                                      ) +
                                      "%"
                                  )
                                ])
                              : _vm._e(),
                            item.levlabel === "4"
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("addLang.lang7")) +
                                      ": " +
                                      _vm._s(
                                        _vm._f("mathFloor")(
                                          item.rebate * 100,
                                          1
                                        )
                                      ) +
                                      "%"
                                  )
                                ])
                              : _vm._e(),
                            item.levlabel === "3"
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("addLang.lang7")) +
                                      ": " +
                                      _vm._s(
                                        _vm._f("mathFloor")(
                                          item.rebate * 100,
                                          1
                                        )
                                      ) +
                                      "%"
                                  )
                                ])
                              : _vm._e(),
                            item.levlabel === "2"
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("addLang.lang7")) +
                                      ": " +
                                      _vm._s(
                                        _vm._f("mathFloor")(
                                          item.rebate * 100,
                                          1
                                        )
                                      ) +
                                      "%"
                                  )
                                ])
                              : _vm._e(),
                            item.levlabel === "1"
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(_vm.$t("addLang.lang7")) +
                                      ": " +
                                      _vm._s(
                                        _vm._f("mathFloor")(
                                          item.rebate * 100,
                                          1
                                        )
                                      ) +
                                      "%"
                                  )
                                ])
                              : _vm._e(),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("addLang.lang8")) +
                                  "：" +
                                  _vm._s(item.purchaseLimit)
                              )
                            ])
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "assets-info",
                            on: {
                              click: function($event) {
                                return _vm.pushPath("/graborders")
                              }
                            }
                          },
                          [
                            _c("p", [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("addLang.lang9")))
                              ]),
                              _vm._v(">> ")
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                })
              ],
              2
            ),
            _vm._m(3),
            _c(
              "van-swipe",
              {
                staticClass: "banner_box",
                attrs: { autoplay: 5000, duration: 1000 }
              },
              _vm._l(_vm.banner2List, function(image, index) {
                return _c("van-swipe-item", { key: index }, [
                  _c("div", { staticClass: "banner_img" }, [
                    _c("img", { attrs: { src: image.banner.default } })
                  ])
                ])
              }),
              1
            ),
            _vm._m(4),
            _c(
              "div",
              { staticClass: "shop-grid" },
              _vm._l(_vm.shopItems, function(item, index) {
                return _c("div", { key: index, staticClass: "grid-item" }, [
                  _c("div", { staticClass: "item-image" }, [
                    _c("img", { attrs: { src: item.image, alt: "" } })
                  ]),
                  _vm._m(5, true)
                ])
              }),
              0
            ),
            _vm._m(6),
            _c(
              "van-swipe",
              {
                staticClass: "banner_box",
                attrs: { autoplay: 5000, duration: 1000 }
              },
              _vm._l(_vm.banner3List, function(image, index) {
                return _c("van-swipe-item", { key: index }, [
                  _c("div", { staticClass: "banner_img" }, [
                    _c("img", { attrs: { src: image.banner.default } })
                  ])
                ])
              }),
              1
            ),
            _vm._m(7),
            _c(
              "van-swipe",
              {
                staticClass: "banner_box",
                attrs: { autoplay: 5000, duration: 1000 }
              },
              _vm._l(_vm.banner4List, function(image, index) {
                return _c("van-swipe-item", { key: index }, [
                  _c("div", { staticClass: "banner_img" }, [
                    _c("img", { attrs: { src: image.banner.default } })
                  ])
                ])
              }),
              1
            ),
            _vm._m(8),
            _c("div", { staticClass: "partners" }, [
              _c("h3", [_vm._v("合作伙伴")]),
              _c("div", { staticClass: "partners-grid" }, [
                _c("div", { staticClass: "partner-item" }, [
                  _c("img", {
                    attrs: { src: require("@img/home/h1.png"), alt: "Macy's" }
                  })
                ]),
                _c("div", { staticClass: "partner-item" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/home/h2.png"),
                      alt: "Neiman Marcus"
                    }
                  })
                ]),
                _c("div", { staticClass: "partner-item" }, [
                  _c("img", {
                    attrs: { src: require("@img/home/h3.png"), alt: "Amazon" }
                  })
                ]),
                _c("div", { staticClass: "partner-item" }, [
                  _c("img", {
                    attrs: { src: require("@img/home/h4.png"), alt: "Shopbop" }
                  })
                ]),
                _c("div", { staticClass: "partner-item" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/home/h5.png"),
                      alt: "Bergdorf Goodman"
                    }
                  })
                ]),
                _c("div", { staticClass: "partner-item" }, [
                  _c("img", {
                    attrs: {
                      src: require("@img/home/h6.png"),
                      alt: "Saks Fifth Avenue"
                    }
                  })
                ])
              ]),
              _vm._m(9)
            ])
          ],
          1
        )
      ]),
      _c(
        "van-popup",
        {
          staticClass: "right_popup",
          attrs: { "close-on-popstate": "", "overlay-class": "block_bg" },
          model: {
            value: _vm.noticePopup,
            callback: function($$v) {
              _vm.noticePopup = $$v
            },
            expression: "noticePopup"
          }
        },
        [
          _c("nav-header", { attrs: { title: _vm.$t("homeLang.lang30") } }),
          _c("div", { staticClass: "main_content notice_details" }, [
            _c("h3", [_vm._v(_vm._s(_vm.noticeData.title))]),
            _c("p", [_vm._v(_vm._s(_vm.noticeData.wdate))]),
            _c("pre", { domProps: { innerHTML: _vm._s(_vm.noticeData.reply) } })
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flannels-section" }, [
      _c("h2", [_vm._v("FLANNELS")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "paris-review" }, [
      _c("h2", [_vm._v("PARIS REVIEW")]),
      _c("p", { staticClass: "review-text" }, [
        _vm._v(
          " Spotted in the fashion capital: Allover animal print adds a certain je ne sais quoi to sleek dresses, opulent outerwear and darling accessories. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _c("h3", [_vm._v("SHOP HANDBAGS ▸")]),
      _c("h4", [_vm._v("会员等级")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _c("h3", [_vm._v("PRESENT PERFECT")]),
      _c("h4", [
        _vm._v(
          "Discover can't-miss gifts for birthdays，anniversaries or just because"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _c("p", [_vm._v("SHOP CLOTHING ▸")]),
      _c("br"),
      _c("p", [_vm._v("SHOP FEMALE'S BAGS ▸")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-text" }, [_c("h3", [_vm._v("SHOP")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _c("p", [_vm._v("THE SCENIC ROUTE")]),
      _c("br"),
      _c("p", [_vm._v("SHOP NEW BEAUTY PRODUCTS▸")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "subtitle" }, [
      _c("p", [_vm._v("THE SCENIC ROUTE")]),
      _c("br"),
      _c("p", [_vm._v("SHOP FOR CLOTHING▸")]),
      _c("br"),
      _c("p", [_vm._v("MICHAEL KORS COLLECTION")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "urban-bohemia" }, [
      _c("h1", [_vm._v("URBAN BOHEMIA")]),
      _c("p", { staticClass: "description" }, [
        _vm._v(
          " Inspired by New York's Greenwich Village in the 1970s, Fall/Winter 2023 showcases a luxurious mix of textures right in step with the bold spirit of big-city life. "
        )
      ]),
      _c("p", [_vm._v("SHOP FALL/WINTER 2023 COLLECTION")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _c("p", [_vm._v("© 2023, FLANNELS")]),
      _c("p", { staticClass: "supply-chain" }, [
        _vm._v("CA Transparency in Supply Chains Act")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }