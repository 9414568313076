export default {
  lang1: '提款',
  lang2: '帳號詳細資料',
  lang3: '訂單',
  lang4: '搶單',
  lang5: '會員等級',
  lang6: '所需金額',
  lang7: '每張訂單的佣金',
  lang8: '訂單數量',
  lang9: '加入',
  lang10: '可用餘額',
  lang11: '凍結平衡',
  lang12: '待處理',
  lang13: '已支付',
  lang14: '已結算',
  lang15: '還沒有數據',
  lang16: '訂單總數',
  lang17: '佣金',
  lang18: '提交訂單',
  lang19: '訂單號碼',
  lang20: '產品產量',
  lang21: '當前餘額',
  lang22: '計算公式',
  lang23: '從該訂單中獲利',
  lang24: '提交',
  lang25: '立即搶單',
  lang26: '取得佣金',
  lang27: '今天是任務',
  lang28: '今天完成',
  lang29: '過程',
  lang30: '點選「開始任務」按鈕，依照指示完成任務',
  lang31: '完成任務後，可以將佣金結算到餘額',
  lang32: '單價',
  lang33: '回扣',
  lang34: '訂單正在進行中',
  lang35: '取消佇列',
  lang36: '由於現階段搶單的使用者較多，系統正在努力分配訂單。目前排名第11名。請耐心等待。',
  lang37: '溫馨提示：升級VIP等級可以幫助您更快獲得訂單',
  lang38: '如果您有任何疑問或需要協助，請聯絡線上客服',
  lang39: '點我聯絡您的專屬客服⭣⭣⭣',
  lang40: '常見問題',
  lang41: '如何儲值，有哪些付款方式？',
  lang42: '『這個系統對多個國家開放。我們提供國際支付方式並支持不同國家的當地貨幣。',
  lang43: '我如何投資並賺取收入？',
  lang44: '『可以在系統中搶單，每天獲得穩定的自動下單收入和利息。所有收入都可以提取到您的銀行卡。',
  lang45: '如何註冊成為會員？',
  lang46: '新會員透過邀請註冊。為了確保註冊會員的權益，防止過度註冊減少權益，新會員只能在現有會員的邀請下註冊。',
  lang47: '購買數量有限制嗎？',
  lang48: '是的，為了讓更多會員有機會獲得福利，系統中每個帳戶都有最低購買金額限制。',
  lang49: '操作過程中遇到問題怎麼辦？',
  lang50: '您可以隨時聯絡線上客服，協助完成所有操作。',
  lang51: '聯絡客服',
  lang52: '歡迎',
  lang53: '儲值',
  lang54: '充提記錄',
  lang55: '我的團隊',
  lang56: '邀請朋友',
  lang57: '取款銀行卡',
  lang58: '安全中心',
  lang59: '設定',
  lang60: '銀行名稱',
  lang61: '幫助',
  lang62: '我的',
  lang63: '訂單歷史記錄',
  lang64: '客戶服務',
  lang65: 'FLASHOP',
  lang66: '情侶活動',
  lang67: '綁定情侶',
  lang68: 'A 充值金額達到',
  lang69: 'B 充值金額達到',
  lang70: '即可領取',
  lang71: '參與',
  lang72: '活動細則',
  lang73: '情侶需要提供對方的ID進行關聯，關聯成功後，雙方可以領取對應的獎勵到自己的賬戶，每個賬號僅能關聯一次，一旦關聯將無法再次關聯。通過情侶關聯活動你可以與您的伴侶一起享受獎勵活動，並加深彼此之間的關係，祝您們在活動中活的愉快的體驗！',
  lang74: '流程說明',
  lang75: '用戶A輸入用戶B的ID，取得同意後，經系統驗證關聯信息，並檢查雙方用戶的關聯狀態，雙方同意後即可關聯成功。',
  lang76: '請輸入情侶ID',
  lang77: '取消',
  lang78: '確定',
  lang79: '一生一世',
  lang80: '生生世世',
  lang81: '我只爱你',
  lang82: '永不分离',
  lang83: '长长久久',
  lang84: '資產明細',
  lang85: '订单规则',
  lang86: '平台介绍',
  lang87: '資產明細',
  lang88: '資產明細',
  lang89: '还没有账号？',
  lang90: '已抢订单数量',
  lang91: '账号',
  lang92: '今日战果',
  lang93: '佣金',
  lang94: '平台运行规则',
  lang95: '1.本平台采用人工智能化系统，更方便快捷的服务商家和抢单用户；',
  lang96: '2.本平台服务全球与FLANNELS合作的10万+的电商商家，商家为提高商品曝光度而将商品在本平台展示出售，抢单用户在完成订单后，商户会立即返还订单金额以及佣金，平台为抢单用户随机匹配商户订单，佣金由订单大小以及VIP等级有关；',
  lang97: '订单数量与VIP等级相关，VIP等级越高，订单数量越多，利润越高；',
  lang98: '3.平台系统会根据用户账户余额判定VIP等级并为抢单用户升/降等级；',
  lang99: '4.抢单用户每天仅限一次完成相关VIP等级的订单数量，抢单用户完成相应VIP等级的订单数量后方可提现；完成当日订单后，如果用户还想继续赚取佣金，可以选择提高VIP等级或者等待第二天的系统重置订单；',
  lang100: '5.平台为激励抢单用户，设置有幸运订单，幸运订单随机匹配；',
  lang101: '每位客户有机会获得三个奖励订单，奖励金额为订单金额的20%-35%',
  lang102: '①第一个幸运订单奖励订单金额20%的奖金。',
  lang103: '②第二个幸运订单奖励订单金额30%的奖金。',
  lang104: '③第三个幸运订单奖励订单金额35%的奖金。',
  lang105: '6.隐私政策，FLANNELS平台遵重每一位抢单用户的隐私，更注重保护抢单用户隐私；',
  lang106: '以上FLANNELS平台享有最终解释权，敬请用户遵守规则',
  lang107: 'FLANNELS 于 1976 年在英格兰北部成立，现已成为业内最令人兴奋的参与者之一。如今，该品牌在英国拥有 50 多家门店，主营成衣、配饰、鞋履以及新推出的青少年和美容系列。FLANNELS Beauty 于 2021 年推出，致力于以其"消费者至上"的方式颠覆美容零售业。',
  lang108: '从 FLANNELS 美容酒吧到 FLANNELS 美容更衣室，将奢华美容带到拥有最先进商店和一流体验的地区。在下一代零售领袖的支持下，即将上任的首席执行官 Michael Murray 正在将 FLANNELS 提升到一个新的水平，并将其无与伦比的产品带到区域城市。作为集团高建业务主管，Murray 震撼了整个行业 - "市场对我们最擅长的奢侈品类型有着无与伦比的需求。',
  lang109: 'FLANNELS 继续颠覆和重新思考零售业，令人印象深刻地展示了我们的愿景，并为英国的主要城市提供了世界一流的购物目的地。" FLANNELS 是一家超级包容、多品类的零售商，拥有奢华时尚和美容领域的知名品牌以及不断发展的新兴品牌，致力于复兴店内体验并将奢华带给每个人。'
};