import "core-js/modules/es.object.to-string";
export default [{
  path: '/marketDetails/:type',
  name: 'marketDetails',
  component: function component() {
    return import('@/views/home/marketDetails');
  },
  meta: {
    level: 10
  }
}, {
  path: '/coinSearch',
  name: 'coinSearch',
  component: function component() {
    return import('@/views/home/coinSearch');
  },
  meta: {
    level: 2
  }
}, {
  path: '/notice',
  name: 'notice',
  component: function component() {
    return import('@/views/home/notice');
  },
  meta: {
    level: 3
  }
}, {
  path: '/lockMining',
  name: 'lockMining',
  component: function component() {
    return import('@/views/home/lockMining');
  },
  meta: {
    level: 2,
    requiresAuth: true
  }
}, {
  path: '/comfirmLock',
  name: 'comfirmLock',
  component: function component() {
    return import('@/views/home/comfirmLock');
  },
  meta: {
    level: 3,
    requiresAuth: true
  }
}, {
  path: '/lockRecord',
  name: 'lockRecord',
  component: function component() {
    return import('@/views/home/lockRecord');
  },
  meta: {
    level: 4,
    requiresAuth: true
  }
}, {
  path: '/browser',
  name: 'browser',
  component: function component() {
    return import('@/views/home/browser');
  },
  meta: {
    level: 0,
    requiresAuth: false
  }
}, {
  path: '/orderSubmit',
  name: 'orderSubmit',
  component: function component() {
    return import('@/views/ordersrecord/orderSubmit');
  },
  meta: {
    level: 4,
    requiresAuth: true
  }
}, {
  path: '/orderRules',
  name: 'OrderRules',
  component: function component() {
    return import('@/views/home/orderRules.vue');
  },
  meta: {
    title: '订单规则'
  }
}, {
  path: '/platform',
  name: 'Platform',
  component: function component() {
    return import('@/views/home/platform.vue');
  },
  meta: {
    title: '平台介绍'
  }
}];