require("/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/core-js/modules/es.array.iterator.js");

require("/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/core-js/modules/es.promise.js");

require("/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/core-js/modules/es.object.assign.js");

require("/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/core-js/modules/es.promise.finally.js");

window.appVersion = '1.0';

function loadApp() {
  if (window.mui) {
    // app环境 等待plus
    var appInterval = setInterval(function () {
      if (window.plus) {
        window.clearInterval(appInterval);

        require('./App.js');
      }
    }, 500);
  } else {
    require('./App.js');
  }
}

loadApp();