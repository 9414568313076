export default {
  lang1: 'Auszahlung',
  lang2: 'Kontodetails',
  lang3: 'Aufzeichnung',
  lang4: 'Bestellungen',
  lang5: 'Mitgliedschaftsstufe',
  lang6: 'Erforderlicher Betrag',
  lang7: 'Provision pro Auftrag',
  lang8: 'Anzahl der Bestellungen',
  lang9: 'Beitreten',
  lang10: 'Verfügbares Guthaben',
  lang11: 'Guthaben einfrieren',
  lang12: 'Ausstehend',
  lang13: 'Bezahlt',
  lang14: 'Abgerechnet',
  lang15: 'Noch keine Daten',
  lang16: 'Bestellsumme',
  lang17: 'Provision',
  lang18: 'Bestellung aufgeben',
  lang19: 'Bestellnummer',
  lang20: 'Produktertrag',
  lang21: 'Aktuelles Guthaben',
  lang22: 'Berechnungsformel',
  lang23: 'Gewinn aus dieser Bestellung',
  lang24: 'Einreichen',
  lang25: 'Jetzt Bestellung aufgeben',
  lang26: 'Provision erhalten',
  lang27: 'Heutige Aufgaben',
  lang28: 'Heute abgeschlossen',
  lang29: 'Verfahren',
  lang30: 'Klicken Sie auf die Schaltfläche "Aufgabe starten" und folgen Sie den Anweisungen, um die Aufgabe abzuschließen.',
  lang31: 'Nach Abschluss der Aufgabe können Sie die Provision auf das Guthaben abrechnen',
  lang32: 'Stückpreis',
  lang33: 'Rabatt',
  lang34: 'Bestellung in Bearbeitung',
  lang35: 'Warteschlange abbrechen',
  lang36: 'Aufgrund der großen Anzahl von Benutzern, die um Aufträge auf der aktuellen Ebene konkurrieren, arbeitet das System hart daran, Aufträge zuzuweisen. Es ist derzeit auf Platz 11. Bitte warten Sie geduldig.',
  lang37: 'Tipp: Eine Erhöhung Ihrer VIP-Stufe kann Ihnen helfen, schneller Bestellungen zu erhalten',
  lang38: 'Wenn Sie Fragen haben oder Hilfe benötigen, wenden Sie sich bitte an den Online-Kundendienst',
  lang39: 'Klicken Sie hier, um Ihren exklusiven Kundenservice zu kontaktieren⭣⭣⭣',
  lang40: 'FAQ',
  lang41: 'Wie kann man aufladen und welche Zahlungsmethoden gibt es?',
  lang42: 'Dieses System ist für mehrere Länder geöffnet. Wir bieten internationale Zahlungsmethoden und unterstützen lokale Währungen für verschiedene Länder.',
  lang43: 'Wie kann ich investieren und Einkommen erzielen?',
  lang44: 'Sie können Aufträge im System ergreifen und jeden Tag ein stabiles automatisches Auftragsplatzierungseinkommen und Zinsen erhalten. Alle Einnahmen können auf Ihre Bankkarte ausgezahlt werden.',
  lang45: 'Wie registriere ich mich als Mitglied?',
  lang46: 'Neue Mitglieder werden auf Einladung registriert. Um die Vorteile registrierter Mitglieder zu gewährleisten und eine übermäßige Registrierung zur Reduzierung von Vorteilen zu verhindern, können sich neue Mitglieder nur auf Einladung bestehender Mitglieder registrieren.',
  lang47: 'Gibt es ein Limit für den Kaufbetrag?',
  lang48: 'Ja, damit mehr Mitglieder die Möglichkeit haben, von den Vorteilen zu profitieren, hat jedes Konto im System ein Mindestabnahmelimit.',
  lang49: 'Was soll ich tun, wenn während des Betriebs Probleme auftreten?',
  lang50: 'Sie können sich jederzeit an den Online-Kundenservice wenden, um Sie bei der Durchführung aller Vorgänge zu unterstützen.',
  lang51: 'Kundenservice kontaktieren',
  lang52: 'Willkommen',
  lang53: 'Aufladen',
  lang54: 'Ein- und Auszahlungshistorie',
  lang55: 'Mein Team',
  lang56: 'Freunde einladen',
  lang57: 'Auszahlungsbankkarte',
  lang58: 'Sicherheitszentrum',
  lang59: 'Einrichten',
  lang60: 'Bankname',
  lang61: 'Hilfe',
  lang62: 'Mein',
  lang63: 'Bestellverlauf',
  lang64: 'Kundenservice',
  lang65: "Lowe's",
  lang66: 'Paaraktivität',
  lang67: 'Paar verbinden',
  lang68: 'A erreicht Aufladebetrag',
  lang69: 'B erreicht Aufladebetrag',
  lang70: 'Kann beansprucht werden',
  lang71: 'Teilnehmen',
  lang72: 'Aktivitätsregeln',
  lang73: 'Paare müssen die ID des anderen für die Bindung angeben. Nach erfolgreicher Bindung können beide Parteien entsprechende Belohnungen auf ihre eigenen Konten beanspruchen. Jedes Konto kann nur einmal gebunden werden und nach der Bindung kann es nicht erneut gebunden werden. Durch die Paarbindungsaktivität können Sie Belohnungsaktivitäten mit Ihrem Partner genießen und Ihre Beziehung zueinander vertiefen. Wir wünschen Ihnen eine angenehme Erfahrung bei der Aktivität!',
  lang74: 'Prozessbeschreibung',
  lang75: 'Benutzer A gibt die ID von Benutzer B ein, erhält die Zustimmung, System überprüft Bindungsinformationen und prüft den Bindungsstatus beider Benutzer, nach Zustimmung beider Parteien ist die Bindung erfolgreich.',
  lang76: 'Bitte geben Sie die Paar-ID ein',
  lang77: 'Abbrechen',
  lang78: 'Bestätigen',
  lang79: 'Für immer und ewig',
  lang80: 'Ewige Liebe',
  lang81: 'Ich liebe nur dich',
  lang82: 'Nie getrennt',
  lang83: 'Für immer zusammen',
  lang84: 'Vermögensdetails',
  lang85: 'Bestellregeln',
  lang86: 'Plattform-Einführung',
  lang87: 'Vermögensdetails',
  lang88: 'Vermögensdetails',
  lang89: 'Noch kein Konto?',
  lang90: 'Anzahl der Bestellungen',
  lang91: 'Konto',
  lang92: 'Heutige Ergebnisse',
  lang93: 'Provision',
  lang94: 'Plattform-Betriebsregeln',
  lang95: '1. Diese Plattform verwendet ein KI-System für einen bequemeren und schnelleren Service für Händler und Bestellkunden;',
  lang96: '2. Diese Plattform bedient weltweit über 100.000 E-Commerce-Händler, die mit FLANNELS zusammenarbeiten. Um die Produktpräsenz zu erhöhen, stellen Händler ihre Produkte auf dieser Plattform zum Verkauf aus. Nach Abschluss der Bestellung erstatten die Händler sofort den Bestellbetrag und die Provision. Die Plattform ordnet Bestellkunden zufällig Händleraufträge zu. Die Provision hängt von der Bestellgröße und dem VIP-Level ab;',
  lang97: 'Die Anzahl der Bestellungen hängt vom VIP-Level ab. Je höher das VIP-Level, desto mehr Bestellungen und höhere Gewinne;',
  lang98: '3. Das Plattformsystem bestimmt das VIP-Level basierend auf dem Kontoguthaben und stuft Bestellkunden hoch/herunter;',
  lang99: '4. Bestellkunden können täglich nur einmal die entsprechende VIP-Level-Bestellmenge abschließen. Bestellkunden können erst nach Abschluss der entsprechenden VIP-Level-Bestellmenge Geld abheben. Nach Abschluss der Tagesbestellung können Benutzer, die weiter Provisionen verdienen möchten, entweder ihr VIP-Level erhöhen oder auf den System-Reset der Bestellungen am nächsten Tag warten;',
  lang100: '5. Um Bestellkunden zu motivieren, bietet die Plattform Glücksbestellungen an, die zufällig zugeordnet werden;',
  lang101: 'Jeder Kunde hat die Chance, drei Belohnungsaufträge zu erhalten, wobei die Belohnung 20%-35% des Auftragswertes beträgt',
  lang102: '① Der erste Glücksauftrag belohnt mit 20% des Auftragswertes.',
  lang103: '② Der zweite Glücksauftrag belohnt mit 30% des Auftragswertes.',
  lang104: '③ Der dritte Glücksauftrag belohnt mit 35% des Auftragswertes.',
  lang105: '6. Datenschutzrichtlinie: Die FLANNELS-Plattform respektiert die Privatsphäre jedes Bestellkunden und legt besonderen Wert auf den Schutz der Privatsphäre der Bestellkunden;',
  lang106: 'Die FLANNELS-Plattform behält sich das Recht der endgültigen Auslegung vor, Benutzer werden gebeten, die Regeln einzuhalten',
  lang107: 'FLANNELS wurde 1976 in Nordengland gegründet und ist heute einer der aufregendsten Akteure der Branche. Die Marke verfügt heute über mehr als 50 Geschäfte in Großbritannien und führt Konfektionskleidung, Accessoires, Schuhe sowie neu eingeführte Jugend- und Schönheitskollektionen. FLANNELS Beauty wurde 2021 eingeführt und hat sich zum Ziel gesetzt, den Beauty-Einzelhandel mit seinem "Verbraucher zuerst"-Ansatz zu revolutionieren.',
  lang108: 'Von FLANNELS Beauty Bars bis hin zu FLANNELS Beauty Changing Rooms bringt es Luxus-Beauty in Regionen mit modernsten Geschäften und erstklassigen Erlebnissen. Unterstützt von der nächsten Generation von Einzelhandelsführern, bringt der designierte CEO Michael Murray FLANNELS auf ein neues Niveau und bringt sein unvergleichliches Angebot in regionale Städte. Als Group Head of Elevation hat Murray die Branche erschüttert - "Es gibt eine beispiellose Nachfrage nach der Art von Luxus, die wir am besten können.',
  lang109: 'FLANNELS revolutioniert und überdenkt den Einzelhandel weiterhin, demonstriert eindrucksvoll unsere Vision und bietet erstklassige Einkaufsziele für die wichtigsten Städte Großbritanniens." FLANNELS ist ein super-inklusiver, Multi-Kategorie-Einzelhändler mit etablierten und aufstrebenden Marken aus den Bereichen Luxusmode und Schönheit, der sich der Wiederbelebung des In-Store-Erlebnisses verschrieben hat und Luxus für alle zugänglich macht.'
};