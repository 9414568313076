export default {
  lang1: "幣幣",
  lang2: "合約",
  lang3: "名稱",
  lang4: "最新價",
  lang5: "漲幅",
  lang6: "數量",
  lang7: "價格",
  lang8: "分時",
  lang9: "1分鐘",
  lang9a: "5分鐘",
  lang10: "15分鐘",
  lang10a: "30分鐘",
  lang11: "1小時",
  lang12: "4小時",
  lang13: "日線",
  lang13a: "周線",
  lang14: "名額",
  lang14a: "更多",
  lang15: "高",
  lang16: "低",
  lang17: "24H量",
  lang18: "檔點陣圖",
  lang19: "成交",
  lang20: "簡介",
  lang21: "時間",
  lang22: "方向",
  lang23: "買入",
  lang24: "賣出",
  lang25: "發行總量",
  lang26: "官網",
  lang27: "區塊瀏覽器",
  lang28: "開多",
  lang29: "開空",
  lang30: "蒐索幣種",
  lang31: "最近查看",
  lang32: "暫無相關幣種",
  lang33: "此幣種暫未開放交易",
  lang34: "總帳戶資產折合",
  lang35: "充幣",
  lang36: "提幣",
  lang37: "劃轉",
  lang38: "兌換",
  lang39: "錢包資產折合",
  lang40: "隱藏小額幣種",
  lang41: "蒐索",
  lang42: "可用",
  lang43: "凍結",
  lang44: "折合",
  lang45: "持有數量",
  lang46: "倉位保證金",
  lang47: "已實現盈虧",
  lang48: "委託保證金",
  lang49: "總資產",
  lang50: "充值",
  lang51: "提現",
  lang52: "其他",
  lang53: "狀態：",
  lang54: "地址：",
  lang55: "等待中",
  lang56: "已完成",
  lang57: "已駁回",
  lang58: "切換幣種",
  lang59: "鏈名稱",
  lang60: "充幣地址",
  lang61: "請認真核對並填寫此地址標籤，若遺漏標籤將導致資金遺失",
  lang62: "請勿向上述地址充值任何非{coin}資產，否則資產將不可找回",
  lang63: "最小充值金額{low}{coin}，小於最小充值金額的充值將不會入帳",
  lang64: "錢包地址",
  lang65: "請輸入或粘貼錢包地址",
  lang66: "請輸入提幣數量",
  lang67: "全部",
  lang68: "手續費",
  lang69: "到賬數量",
  lang70: "注：一次最高提幣{oneoutheight}，最低提幣{low}，每日最高提幣{height}",
  lang71: "請輸入資金密碼",
  lang72: "資金密碼",
  lang73: "錢包地址",
  lang74: "您還沒有添加該幣種的錢包地址",
  lang75: "去添加",
  lang76: "身份認證",
  lang77: "請先完成身份認證",
  lang78: "去認證",
  lang79: "請先設定帳號資金密碼",
  lang80: "去設定",
  lang81: "餘額不足！",
  lang82: "請在APP中使用掃一掃",
  lang83: "掃一掃",
  lang84: "劃轉到",
  lang85: "幣種",
  lang86: "請輸入劃轉數量",
  lang87: "只有將資產劃轉到對應帳戶才可交易",
  lang88: "法幣",
  lang89: "帳戶餘額不足",
  lang90: "匯率：",
  lang91: "注：最小兌換{low}，最大兌換{height}。",
  lang92: "兌換記錄",
  lang93: "兌換",
  lang94: "消耗",
  lang95: "到賬",
  lang96: "請輸入正確的兌換數量",
  lang97: "提幣地址",
  lang98: "還沒有添加提幣地址~",
  lang99: "添加提幣地址",
  lang100: "提幣地址",
  lang101: "請輸入地址",
  lang102: "備註",
  lang103: "請輸入備註",
  lang104: "確認删除地址？",
  lang105: "幣幣交易",
  lang106: "以當前最優價格交易",
  lang107: "交易額",
  lang108: "當前委託",
  lang108a: "查看更多",
  lang109: "限價",
  lang110: "市價",
  lang111: "撤銷",
  lang112: "委託價",
  lang113: "委託時間",
  lang114: "委託量",
  lang115: "未成交量",
  lang116: "委託總額",
  lang117: "狀態",
  lang118: "未成交",
  lang119: "部分成交",
  lang120: "全部成交",
  lang121: "全部撤銷",
  lang122: "部分撤銷",
  lang123: "請輸入單價",
  lang124: "請輸入正確的單價",
  lang125: "請輸入大於0的單價",
  lang126: "請輸入數量",
  lang127: "請輸入正確的數量",
  lang128: "最小買入量為{min}",
  lang129: "超出持有數量",
  lang130: "確認買入{coin}",
  lang131: "最小賣出量為{min}",
  lang132: "確認賣出{coin}",
  lang133: "確認撤銷委託？",
  lang134: "歷史委託",
  lang135: "開倉",
  lang136: "持倉",
  lang137: "一鍵平倉",
  lang138: "開多",
  lang139: "開空",
  lang140: "手",
  lang141: "止盈價",
  lang142: "止損價",
  lang143: "保證金",
  lang144: "當前委託",
  lang145: "多倉",
  lang146: "空倉",
  lang147: "逐倉",
  lang148: "全倉",
  lang149: "當前價",
  lang150: "建倉價",
  lang151: "倉位",
  lang152: "未成交量",
  lang153: "類型",
  lang154: "預估强平價",
  lang155: "盈虧",
  lang155a: "倉息",
  lang156: "倉位價值",
  lang157: "止盈止損",
  lang158: "極速平倉",
  lang159: "平倉",
  lang160: "可平",
  lang161: "倉位保證金",
  lang162: "平倉數量",
  lang163: "請輸入平倉數量",
  lang164: "永續合約",
  lang165: "平全部",
  lang166: "平多倉",
  lang167: "平空倉",
  lang168: "請輸入單價",
  lang169: "請輸入正確的單價",
  lang170: "請輸入數量",
  lang171: "請輸入整數數量",
  lang172: "最大買入量為{max}",
  lang173: "最大賣出量為{max}",
  lang174: "止盈價不能低於開多開倉價",
  lang175: "止損價不能高於開多開倉價",
  lang176: "止盈價不能高於開空開倉價",
  lang177: "止損價不能低於開空開倉價",
  lang178: "是否開多{tradeNum} {tradeCoin}，保證金約為{bondNum}{mainCoin}",
  lang179: "是否開空{tradeNum} {tradeCoin}，保證金約為{bondNum}{mainCoin}",
  lang180: "確認極速平倉？",
  lang181: "合約記錄",
  lang182: "持倉訂單",
  lang183: "歷史持倉",
  lang184: "當前委託",
  lang185: "歷史委託",
  lang186: "實際平倉價格",
  lang187: "平倉時間",
  lang188: "確認一鍵平倉？",
  lang189: "合約名稱",
  lang190: "當前價格",
  lang191: "開倉均價",
  lang192: "持倉方向",
  lang193: "平倉數量",
  lang194: "止盈價格",
  lang195: "預計收益：",
  lang196: "止損價格",
  lang197: "請輸入止盈價格",
  lang198: "請輸入止損價格",
  lang199: "止盈止損清單",
  lang200: "修改",
  lang201: "請輸入正確的平倉數量",
  lang202: "請輸入正確的止盈價格",
  lang203: "止盈價格必須高於開倉價",
  lang204: "止盈價格必須低於開倉價",
  lang205: "請輸入正確的止損價格",
  lang206: "止損價格必須高於開倉價",
  lang207: "止損價格必須低於開倉價",
  lang208: "請輸入止盈價格或止損價格",
  lang209: "在下乃幣圈之神",
  lang210: "失敗乃成功之母",
  lang211: "收益率",
  lang212: "永續",
  lang213: "平倉價",
  lang214: "追趕",
  lang215: "超越",
  lang216: "領先",
  lang217: "保存圖片",
  lang218: "保存圖片成功",
  lang219: "保存圖片失敗",
  lang220: "購買數量",
  lang221: "請輸入購買數量",
  lang222: "付款方式",
  lang223: "預計付款：",
  lang224: "注：單次最小購買量為{low}{coin}，單次最大購買量為{high}{coin}",
  lang225: "去付款",
  lang226: "購買記錄",
  lang227: "待支付",
  lang228: "已完成",
  lang229: "已取消",
  lang230: "請輸入正確的購買數量",
  lang231: "購買數量不能低於最小購買量",
  lang232: "購買數量不能高於最大購買量",
  lang233: "收益",
  lang234: "收益率",
  lang235: "買漲",
  lang236: "買跌",
  lang237: "持倉訂單",
  lang238: "建倉價",
  lang239: "當前價",
  lang240: "交易總額",
  lang241: "盈虧",
  lang242: "交易週期",
  lang243: "手續費",
  lang244: "歷史訂單",
  lang245: "實際平倉價格",
  lang246: "平倉時間",
  lang247: "盈利訂單",
  lang248: "時間",
  lang249: "方向",
  lang250: "獲利",
  lang251: "平倉價",
  lang252: "週期合約",
  lang253: "交易資產",
  lang254: "可用餘額",
  lang255: "交易數量",
  lang256: "最低限額{min}{coin}",
  lang257: "預期收益：",
  lang258: "預期回報：",
  lang259: "請輸入交易數量",
  lang260: "請輸入正確的數量",
  lang261: "餘額不足",
  lang262: "數量必須高於{min}",
  lang263: "數量必須低於{max}",
  lang264: "websocket推送數據异常，請稍後重試！",
  lang265: "websocket連接异常，請稍後重試！",
  lang266: "盈",
  lang267: "虧",
  lang268: "建倉價格：",
  lang269: "下單金額：",
  lang270: "倒數計時：",
  lang271: "全部歷史持倉",
  lang272: "訂單管理",
  lang273: "成交記錄",
  lang274: "成交量",
  lang275: "充幣數量",
  lang276: "請輸入充幣數量",
  lang277: "充幣訂單",
  lang278: "請輸入充幣訂單",
  lang279: "今日收益",
  lang280: "總收益",
  lang281: "持卡人"
};