export default {
  lang1: '집',
  lang2: 'IEO 새 통화 구독',
  lang3: '수집 토큰은 매일 릴리스됩니다.',
  lang4: '지금 사용',
  lang5: '열리지 않음',
  lang6: '종료',
  lang7: '단가',
  lang8: '시작 시간',
  lang9: '총 발행량',
  lang10: '종료 시간',
  lang11: '총 남은 금액',
  lang12: '잠금 기간',
  lang13: '일',
  lang14: '{coin} 구매',
  lang15: '결제 통화',
  lang15a: '결제',
  lang16: '균형',
  lang17: '모두 사용',
  lang18: '구매 수량을 입력하세요',
  lang19: '예상 결제:',
  lang20: '선물:',
  lang21: '주문 기록',
  lang22: '누적 릴리스',
  lang23: 'IEO는 기금 마련을 약속합니다',
  lang24: '프로모션 리베이트',
  lang25: '정확한 구매 수량을 입력하세요',
  lang26: '남은 합계가 부족합니다',
  lang27: '{number}{coin}을(를) 교환하시겠습니까?',
  lang28: '공지',
  lang29: '아직 발표가 없습니다',
  lang30: '공지 내용',
  lang31: '검색할 통화를 입력하세요',
  lang32: '매일 채굴',
  lang33: '힘이 높을수록 채굴량이 많아집니다',
  lang34: '거래 목록',
  lang35: '인기 목록',
  lang36: '거부 목록',
  lang37: '반점',
  lang38: '계약',
  lang39: '정기 계약',
  lang40: '선택 사항',
  lang41: '모두',
  lang42: '마더보드',
  lang43: 'GEM',
  lang44: '볼륨',
  lang45: '최신 가격',
  lang46: '24시간 증가',
  lang47: '잠긴 채굴',
  lang48: '관리 자금',
  lang49: '오늘 예상 수입',
  lang50: '누적 소득',
  lang51: '관리 주문',
  lang52: '일일 수익',
  lang53: '일',
  lang54: '주문 확인',
  lang55: '최근 일수(일일 수익률)',
  lang56: '관리 기간',
  lang57: '단일 제한',
  lang58: '배당 시간',
  lang59: '매일',
  lang60: '에스크로 금액',
  lang61: '만료되면 반환',
  lang62: '조기 구속',
  lang63: '에스크로 금액을 입력하세요',
  lang64: '예상 수익',
  lang65: '올바른 에스크로 금액을 입력하세요.',
  lang66: '계정 잔액 부족',
  lang67: '금액이 단일 제한 내에 있지 않습니다',
  lang68: '기록 잠금',
  lang69: '실행 중',
  lang70: '완료',
  lang71: '구함',
  lang72: '누적 소득',
  lang73: '관리 시간',
  lang74: '만료 시간',
  lang75: '조기 상환을 결정하면 조기 상환을 위해 해당 처리 수수료가 공제됩니다.'
};