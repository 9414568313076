export default {
  lang1: '取消',
  lang2: '提交',
  lang3: '確定',
  lang4: '暫無數據',
  lang5: '暫無記錄',
  lang6: '複製',
  lang7: '複製成功',
  lang8: '複製失敗',
  lang9: '確認',
  lang10: '幣幣',
  lang11: '資產',
  lang12: '請求失敗！請檢查網絡連接',
  lang13: '週期',
  lang14: '交易',
  lang15: '行情',
  lang16: '請聯繫客服！',
  "theme": "主題皮膚"
};