import home from '@/views/home/mainIndex';
import ordersrecord from '@/views/ordersrecord/mainIndex';
import graborders from '@/views/graborders/mainIndex';
import helpCenter from '@/views/helpCenter/mainIndex';
import my from '@/views/my/mainIndex';
export default [{
  path: '/',
  name: 'home',
  component: home,
  meta: {
    level: 1
  }
}, {
  path: '*',
  redirect: '/'
}, {
  path: '/ordersrecord',
  name: 'ordersrecord',
  component: ordersrecord,
  meta: {
    level: 1.1
  }
}, {
  path: '/graborders',
  name: 'graborders',
  component: graborders,
  meta: {
    level: 1.2,
    requiresAuth: true
  }
}, {
  path: '/helpCenter',
  name: 'helpCenter',
  component: helpCenter,
  meta: {
    level: 1.3,
    requiresAuth: true
  }
}, {
  path: '/my',
  name: 'my',
  component: my,
  meta: {
    level: 2
  }
}];