export default {
  lang1: "baik",
  lang2: "tidak",
  lang3: "Apakah anda ingin men-download paket instalasi baru?",
  lang4: "pengunduhan",
  lang5: "Gagal mengunduh paket pemutakhiran",
  lang6: "Tolong buka kembali aplikasi setelah instalasi!",
  lang7: "Gagal memutakhirkan sumber daya",
  lang8: "Saat ini tidak ada jaringan layanan, tolong periksa koneksi jaringan!",
  lang9: "Tekan lagi untuk keluar dari program",
  lang10: "Pemutakhiran versi aplikasicomment",
  lang11: "1. Mengoptimalkan pengalaman pengguna; 2. Menyelesaikan beberapa masalah.",
  lang12: "Mutakhirkan sekarang",
  lang13: "Gagal mengunduh paket instalasi!"
};