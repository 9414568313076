//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    goodsList: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    getImgUrl: function getImgUrl(url) {
      return "/api/res/preview/".concat(url);
    },
    handleSubmit: function handleSubmit() {}
  }
};