import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  computed: _objectSpread({}, mapState({
    rateData: function rateData(state) {
      return state.wallet.rateData;
    }
  })),
  props: ['type', 'coinList'],
  methods: {
    goDetails: function goDetails(data) {
      sessionStorage.setItem('marketDetails', JSON.stringify(data));
      var type = this.type == 'contract' ? 'contract' : 'trad';
      this.pushPath('/marketDetails/' + type);
    }
  }
};