var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "navHeader",
      staticClass: "nav_header",
      style: { background: _vm.background }
    },
    [
      _c("van-nav-bar", {
        attrs: {
          title: _vm.title,
          "left-text": _vm.leftText,
          "right-text": _vm.rightText,
          "left-arrow": _vm.leftArrow,
          border: _vm.border
        },
        on: {
          "click-left": function($event) {
            return _vm.goBack()
          },
          "click-right": _vm.onClickRight
        },
        scopedSlots: _vm._u(
          [
            {
              key: "right",
              fn: function() {
                return [_vm._t("headerRight")]
              },
              proxy: true
            },
            {
              key: "title",
              fn: function() {
                return [_vm._t("headerTitle")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }