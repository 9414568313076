import "core-js/modules/es.array.index-of";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.search";
import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { logout } from "@api/user";
import { getEarnings as _getEarnings } from '@api/ordersrecord';
export default {
  data: function data() {
    return {
      exitPopup: false,
      langSheet: false,
      langList: [{
        name: '🇺🇸English',
        type: 'EN'
      }, {
        name: '🇻🇳Tiếng Việt',
        type: 'TV'
      }, {
        name: '🇨🇳繁體中文',
        type: 'CN'
      }, {
        name: '🇯🇵日本語',
        type: 'JP'
      }, {
        name: '🇰🇷한국어',
        type: 'KO'
      }, {
        name: '🇩🇪Deutsch',
        type: 'DE'
      }, {
        name: '🇪🇸España',
        type: 'ES'
      }, {
        name: '🇫🇷Français',
        type: 'FR'
      }, {
        name: '🇮🇹Italia',
        type: 'IT'
      }, {
        name: '🇵🇹Portugal',
        type: 'PT'
      }, {
        name: '🇮🇩Indonesia',
        type: 'ID'
      }],
      viplevParams: {
        page: 1,
        limit: 20
      },
      totalOrders: 90,
      todayOrders: 16,
      commission: 0,
      earningsInfo: {}
    };
  },
  computed: _objectSpread({}, mapState({
    viplevInfo: function viplevInfo(state) {
      return state.common.viplevInfo;
    },
    userInfo: function userInfo(state) {
      return state.user.userInfo;
    },
    exchangeCoinList: function exchangeCoinList(state) {
      return state.wallet.exchangeCoinList[0] || {};
    },
    serviceUrl: function serviceUrl(state) {
      return state.common.serviceUrl;
    }
  })),
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 4);
    this.$store.dispatch('common/getViplevList', this.viplevParams);
    this.$store.dispatch('user/getUserInfo');
    this.$store.dispatch('wallet/getExchangeAssets');
    this.getEarnings();
  },
  methods: {
    showOnlineService: function showOnlineService() {
      //06客户的客服链接是外部加载形式，但app无法跳转外部 所以区别处理，跳内部浏览器页面去加载
      if (window.cordova || location.search.indexOf('isapp') !== -1) {
        console.log(111);
        this.$router.push('/browser');
      } else {
        console.log(1122);
        window.open(this.serviceUrl);
      }
    },
    onSelectLang: function onSelectLang(data) {
      if (this.$i18n.locale == data.type) {
        this.langSheet = false;
        return;
      }

      localStorage.setItem('locale', data.type);
      window.location.reload();
      this.langSheet = false;
    },
    exit: function exit() {
      var _this = this;

      logout().then(function (res) {
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');

        _this.$store.dispatch('common/updateLoginStatus', false); //this.pushPath('/login');


        _this.$router.replace('/login');
      });
    },
    getEarnings: function getEarnings() {
      var _this2 = this;

      _getEarnings().then(function (res) {
        _this2.earningsInfo = res.data || {};
      });
    }
  }
};