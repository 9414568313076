import "core-js/modules/es.array.includes";
import "core-js/modules/es.function.name";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'NavFooter',
  computed: {
    selected: {
      get: function get() {
        return this.$store.state.common.selectedFooter;
      },
      set: function set(newValue) {
        this.$store.state.common.selectedFooter = newValue;
      }
    }
  },
  watch: {
    $route: function $route(to, from) {
      var _this = this;

      if (['home', 'ordersrecord', 'graborders', 'helpCenter', 'my'].includes(to.name)) {
        this.$store.dispatch('common/showFooter', true);
      } else {
        setTimeout(function () {
          _this.$store.dispatch('common/showFooter', false);
        }, 10);
      }
    }
  },
  methods: {
    pushPage: function pushPage(path) {
      this.$router.replace(path);
    }
  }
};