import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/web.dom-collections.for-each";
import _toConsumableArray from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
var getters = {
  areaCodeData: function areaCodeData(state) {
    var returnData = {
      indexBar: [],
      areaCode: [],
      mainArea: []
    };

    var areaCode = _toConsumableArray(state.common.areaCode);

    returnData.mainArea = areaCode.filter(function (data) {
      return data.state == 1;
    });
    areaCode.sort(function (param1, param2) {
      return param1.country.localeCompare(param2.country, 'zh');
    });
    var indexBar = [];
    areaCode.forEach(function (data) {
      if (!indexBar.includes(data.initials)) {
        indexBar.push(data.initials);
      }
    });
    indexBar.sort();
    indexBar.forEach(function (key) {
      var indexBarData = {
        key: key,
        data: []
      };
      areaCode.forEach(function (data) {
        if (key == data.initials) {
          indexBarData.data.push(data);
        }
      });
      returnData.areaCode.push(indexBarData);
    });
    returnData.indexBar = [].concat(indexBar);
    return returnData;
  },
  withdrawCoinList: function withdrawCoinList(state) {
    var coinList = state.wallet.rechargeCoinArr;
    var coinListCopy = [];
    var coinNameArr = [];
    var resCoinData = [];

    if (coinList.length) {
      coinList.forEach(function (data) {
        if (data.isout == 'enable') {
          coinListCopy.push(data);

          if (!coinNameArr.includes(data.coin)) {
            coinNameArr.push(data.coin);
          }
        }
      });
      coinNameArr.forEach(function (key) {
        var coinData = {
          name: key,
          coinArr: []
        };
        coinListCopy.forEach(function (data) {
          if (data.coin == key) {
            coinData.coinArr.push(Object.assign({}, data));
          }
        });
        resCoinData.push(coinData);
      });
      return resCoinData;
    }
  },
  rechargeCoinList: function rechargeCoinList(state) {
    var coinList = state.wallet.rechargeCoinArr;
    var coinListCopy = [];
    var coinNameArr = [];
    var resCoinData = [];

    if (coinList.length) {
      coinList.forEach(function (data) {
        if (data.isin == 'enable') {
          coinListCopy.push(data);

          if (!coinNameArr.includes(data.coin)) {
            coinNameArr.push(data.coin);
          }
        }
      });
      coinNameArr.forEach(function (key) {
        var coinData = {
          name: key,
          coinArr: []
        };
        coinListCopy.forEach(function (data) {
          if (data.coin == key) {
            coinData.coinArr.push(Object.assign({}, data));
          }
        });
        resCoinData.push(coinData);
      });
      return resCoinData;
    }
  }
};
export default getters;