export default {
  lang1: 'Retrait',
  lang2: 'Détails du compte',
  lang3: 'Enregistrement',
  lang4: 'commandes',
  lang5: 'Niveau de membre',
  lang6: 'Montant requis',
  lang7: 'Commission par commande',
  lang8: 'Nombre de commandes',
  lang9: 'Rejoindre',
  lang10: 'Solde disponible',
  lang11: 'Solde gelé',
  lang12: 'En attente',
  lang13: 'Payé',
  lang14: 'Réglé',
  lang15: 'Pas encore de données',
  lang16: 'Total de la commande',
  lang17: 'commission',
  lang18: 'Soumettre la commande',
  lang19: 'Numéro de commande',
  lang20: 'Rendement du produit',
  lang21: 'Solde actuel',
  lang22: 'Formule de calcul',
  lang23: 'Profit de cette commande',
  lang24: 'soumettre',
  lang25: 'Saisir la commande maintenant',
  lang26: 'Obtenir la commission',
  lang27: "Aujourd'hui est Tâches",
  lang28: "Terminé aujourd'hui",
  lang29: 'Procédure',
  lang30: 'Cliquez sur le bouton "Démarrer la tâche" et suivez les instructions pour terminer la tâche.',
  lang31: 'Une fois la tâche terminée, vous pouvez régler la commission sur le solde',
  lang32: 'prix unitaire',
  lang33: 'Remise',
  lang34: 'Commande en cours',
  lang35: "Annuler la file d'attente",
  lang36: "En raison du grand nombre d'utilisateurs en concurrence pour les commandes au niveau actuel, le système travaille dur pour attribuer les commandes. Il est actuellement classé 11e. Veuillez patienter.",
  lang37: 'Conseil : La mise à niveau de votre niveau VIP peut vous aider à obtenir des commandes plus rapidement',
  lang38: "Si vous avez des questions ou besoin d'aide, veuillez contacter le service client en ligne",
  lang39: 'Cliquez ici pour contacter votre service client exclusif⭣⭣⭣',
  lang40: 'FAQ',
  lang41: 'Comment recharger et quels sont les modes de paiement ?',
  lang42: 'Ce système est ouvert à plusieurs pays. Nous fournissons des méthodes de paiement internationales et prenons en charge les devises locales pour différents pays.',
  lang43: 'Comment puis-je investir et gagner des revenus ?',
  lang44: 'Vous pouvez saisir des commandes dans le système et obtenir des revenus automatiques stables de placement de commandes et des intérêts chaque jour. Tous les gains peuvent être retirés sur votre carte bancaire.',
  lang45: "Comment s'inscrire en tant que membre ?",
  lang46: 'Les nouveaux membres sont inscrits sur invitation. Afin de garantir les avantages des membres inscrits et d\'éviter que l\'enregistrement excessif ne réduise les avantages, les nouveaux membres ne peuvent s\'inscrire que sur invitation des membres existants.',
  lang47: "Y a-t-il une limite sur le montant d'achat ?",
  lang48: "Oui, afin de permettre à plus de membres d'avoir la possibilité d'obtenir des avantages, chaque compte du système a une limite minimale d'achat.",
  lang49: "Que dois-je faire si je rencontre des problèmes pendant l'opération ?",
  lang50: 'Vous pouvez contacter le service client en ligne à tout moment pour vous aider à effectuer toutes les opérations.',
  lang51: 'Contacter le service client',
  lang52: 'bienvenue',
  lang53: 'recharger',
  lang54: 'Registre des dépôts et retraits',
  lang55: 'Mon équipe',
  lang56: 'Inviter des amis',
  lang57: 'Carte bancaire de retrait',
  lang58: 'Centre de sécurité',
  lang59: 'configurer',
  lang60: 'Nom de la banque',
  lang61: 'aide',
  lang62: 'mon',
  lang63: 'Historique des commandes',
  lang64: 'Service client',
  lang65: "Lowe's",
  lang66: 'Activité de couple',
  lang67: 'Lier un couple',
  lang68: 'A atteint le montant de recharge',
  lang69: 'B atteint le montant de recharge',
  lang70: 'Peut être réclamé',
  lang71: 'Participer',
  lang72: "Règles de l'activité",
  lang73: "Les couples doivent fournir l'ID de l'autre pour la liaison. Après une liaison réussie, les deux parties peuvent réclamer les récompenses correspondantes sur leurs propres comptes. Chaque compte ne peut être lié qu'une seule fois et une fois lié, il ne peut plus être lié. Grâce à l'activité de liaison de couple, vous pouvez profiter des activités de récompense avec votre partenaire et approfondir votre relation mutuelle. Nous vous souhaitons une expérience agréable dans l'activité !",
  lang74: 'Description du processus',
  lang75: "L'utilisateur A saisit l'ID de l'utilisateur B, obtient le consentement, le système vérifie les informations de liaison et vérifie l'état de liaison des deux utilisateurs, après l'accord des deux parties, la liaison est réussie.",
  lang76: "Veuillez saisir l'ID du couple",
  lang77: 'Annuler',
  lang78: 'Confirmer',
  lang79: 'Pour toujours',
  lang80: 'Pour l\'éternité',
  lang81: 'Je n\'aime que toi',
  lang82: 'Jamais séparés',
  lang83: 'Pour longtemps',
  lang84: 'Détails des actifs',
  lang85: 'Règles de commande',
  lang86: 'Présentation de la plateforme',
  lang87: 'Détails des actifs',
  lang88: 'Détails des actifs',
  lang89: "Vous n'avez pas encore de compte ?",
  lang90: 'Nombre de commandes saisies',
  lang91: 'Compte',
  lang92: "Résultats d'aujourd'hui",
  lang93: 'Commission',
  lang94: 'Règles de fonctionnement de la plateforme',
  lang95: '1. Cette plateforme utilise un système d\'IA pour un service plus pratique et rapide pour les marchands et les clients de commande ;',
  lang96: '2. Cette plateforme dessert plus de 100 000 commerçants e-commerce dans le monde qui coopèrent avec FLANNELS. Pour augmenter l\'exposition des produits, les marchands affichent leurs produits à vendre sur cette plateforme. Après avoir terminé la commande, les marchands remboursent immédiatement le montant de la commande et la commission. La plateforme associe aléatoirement les clients de commande aux commandes des marchands. La commission est liée à la taille de la commande et au niveau VIP ;',
  lang97: 'Le nombre de commandes dépend du niveau VIP. Plus le niveau VIP est élevé, plus il y a de commandes et de profits ;',
  lang98: '3. Le système de la plateforme détermine le niveau VIP en fonction du solde du compte et met à niveau/rétrograde les clients de commande ;',
  lang99: '4. Les clients de commande ne peuvent effectuer qu\'une seule fois par jour la quantité de commandes correspondant à leur niveau VIP. Les clients de commande ne peuvent retirer de l\'argent qu\'après avoir complété la quantité de commandes correspondant à leur niveau VIP. Après avoir terminé la commande quotidienne, si les utilisateurs souhaitent continuer à gagner des commissions, ils peuvent soit augmenter leur niveau VIP, soit attendre la réinitialisation du système des commandes le lendemain ;',
  lang100: '5. Pour motiver les clients de commande, la plateforme propose des commandes chanceuses qui sont associées aléatoirement ;',
  lang101: 'Chaque client a la chance de recevoir trois commandes de récompense, la récompense étant de 20 % à 35 % du montant de la commande',
  lang102: '① La première commande chanceuse récompense 20 % du montant de la commande.',
  lang103: '② La deuxième commande chanceuse récompense 30 % du montant de la commande.',
  lang104: '③ La troisième commande chanceuse récompense 35 % du montant de la commande.',
  lang105: '6. Politique de confidentialité : La plateforme FLANNELS respecte la vie privée de chaque client de commande et met particulièrement l\'accent sur la protection de la vie privée des clients de commande ;',
  lang106: 'La plateforme FLANNELS se réserve le droit d\'interprétation finale, les utilisateurs sont priés de se conformer aux règles',
  lang107: 'FLANNELS a été fondée en 1976 dans le nord de l\'Angleterre et est maintenant l\'un des acteurs les plus excitants de l\'industrie. La marque possède maintenant plus de 50 magasins au Royaume-Uni et propose du prêt-à-porter, des accessoires, des chaussures et des collections jeunesse et beauté nouvellement lancées. FLANNELS Beauty a été lancé en 2021 et s\'engage à bouleverser le commerce de détail de la beauté avec son approche "le consommateur d\'abord".',
  lang108: 'Des FLANNELS Beauty Bars aux FLANNELS Beauty Changing Rooms, elle apporte la beauté de luxe dans les régions avec des magasins à la pointe de la technologie et des expériences de première classe. Soutenu par la prochaine génération de leaders du commerce de détail, le nouveau PDG Michael Murray fait passer FLANNELS à un nouveau niveau et apporte son offre inégalée aux villes régionales. En tant que responsable de l\'élévation du groupe, Murray a secoué l\'industrie - "Il y a une demande sans précédent pour le type de luxe que nous faisons le mieux.',
  lang109: 'FLANNELS continue de perturber et de repenser le commerce de détail, démontrant de manière impressionnante notre vision et fournissant des destinations shopping de classe mondiale pour les principales villes britanniques." FLANNELS est un détaillant super inclusif et multi-catégories avec des marques établies et émergentes dans la mode et la beauté de luxe, engagé à faire revivre l\'expérience en magasin et à rendre le luxe accessible à tous.'
};