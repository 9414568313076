export default {
  lang1: 'Casa',
  lang2: 'IEO nuova sottoscrizione di valuta',
  lang3: 'I gettoni della raccolta verranno rilasciati quotidianamente',
  lang4: 'Segreto',
  lang5: 'Non aperto',
  lang6: 'Finito',
  lang7: 'Prezzo',
  lang8: 'Ora di inizio',
  lang9: 'Totale emesso',
  lang10: 'Ora di fine',
  lang11: 'Importo eccedente',
  lang12: 'Periodo di blocco',
  lang13: 'D',
  lang14: 'Compra {moneta}',
  lang15: 'moneta di pagamento',
  lang15a: 'Paga',
  lang16: 'equilibrio',
  lang17: 'Usa tutto',
  lang18: 'Inserisci la quantità di acquisto',
  lang19: 'Paga:',
  lang20: 'Regalo:',
  lang21: "Registrazione dell'ordine",
  lang22: 'Rilascio',
  lang23: 'IEO si impegna',
  lang24: 'Sconto',
  lang25: 'Inserisci la quantità di acquisto corretta',
  lang26: 'Il totale residuo è insufficiente',
  lang27: 'Sei sicuro di scambiare {numero}{moneta}?',
  lang28: 'Avviso',
  lang29: 'Nessun avviso ancora',
  lang30: 'Dettagli avviso',
  lang31: 'Inserisci il token per la ricerca',
  lang32: 'Mining ogni giorno',
  lang33: "Maggiore è la forza, maggiore è l'estrazione mineraria",
  lang34: 'Affare',
  lang35: 'In aumento',
  lang36: 'Rifiuto',
  lang37: 'Punto',
  lang38: 'Futuri',
  lang39: 'Ciclo',
  lang40: 'Preferiti',
  lang41: 'Tutti',
  lang42: 'Mian',
  lang43: 'Avanti',
  lang44: 'Vol',
  lang45: 'Prezzo',
  lang46: 'Cambia%',
  lang47: 'Estrazione bloccata',
  lang48: 'Fondi gestiti',
  lang49: 'Guadagno di oggi',
  lang50: 'Reddito cumulativo',
  lang51: 'Ordine gestito',
  lang52: 'Rendimento giornaliero',
  lang53: 'giorno',
  lang54: 'Conferma ordine',
  lang55: 'Utile',
  lang56: 'Ciclo',
  lang57: 'limite singolo',
  lang58: 'Tempo dividendo',
  lang59: 'Quotidiano',
  lang60: 'Importo di deposito',
  lang61: 'Reso scaduto',
  lang62: 'Redenzione anticipata',
  lang63: "Inserisci l'importo dell'escrow",
  lang64: 'Fatturato stimato',
  lang65: "Inserisci l'importo di deposito a garanzia corretto",
  lang66: 'Saldo conto insufficiente',
  lang67: "L'importo non rientra nel limite unico",
  lang68: 'Blocco record',
  lang69: 'Correre',
  lang70: 'Completo',
  lang71: 'Redento',
  lang72: 'Reddito cumulativo',
  lang73: 'Tempo di gestione',
  lang74: 'Tempo di scadenza',
  lang75: 'Determinare il riscatto anticipato della custodia, in caso di riscatto anticipato verrà detratta la relativa commissione di gestione'
};