export default {
  lang1: 'Cancelar',
  lang2: 'Enviar',
  lang3: 'Confirmar',
  lang4: 'No hay datos todavía',
  lang5: 'Sin registro aún',
  lang6: 'Copiar',
  lang7: 'Copiar correctamente',
  lang8: 'Copia fallida',
  lang9: 'Confirmar',
  lang10: 'Punto',
  lang11: 'Activos',
  lang12: '¡Solicitud fallida! Verifique la conexión de red',
  lang13: 'Ciclo',
  lang14: 'Comercio',
  lang15: 'Mercado',
  lang16: '¡Póngase en contacto con el servicio de atención al cliente!',
  "theme": "Temas skins"
};