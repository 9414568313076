import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import i18n from '@/i18n';
export default {
  /**
   * 时间戳转字符串时间格式
   * @param {string} time 时间戳
   * @param {string} fmt 类似 yyyy-MM-dd hh:mm:ss
   */
  formatDate: function formatDate(time, fmt) {
    if (!time) {
      return;
    } // let localTime = time + (new Date().getTimezoneOffset()*60000) + 180*60000


    var date = new Date(time + (480 + new Date().getTimezoneOffset()) * 60000);

    function padLeftZero(str) {
      return ('00' + str).substr(str.length);
    }

    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }

    var o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    };

    for (var k in o) {
      if (new RegExp("(".concat(k, ")")).test(fmt)) {
        var str = o[k] + '';
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
      }
    }

    return fmt;
  },

  /**
   * 数字处理
   * @param {*} number 需要处理的数字
   * @param {number} digit 需要保留的小数位数
   */
  mathFloor: function mathFloor(number, digit) {
    if (isNaN(Number(number)) || isNaN(Number(digit))) {
      return 0;
    }

    return (Math.floor(number * Math.pow(10, digit) + 1 / Math.pow(10, 9 - digit)) / Math.pow(10, Number(digit))).toFixed(Number(digit));
  },
  mathFloor1: function mathFloor1(number, digit) {
    if (isNaN(Number(number)) || isNaN(Number(digit))) {
      return 0;
    }

    var calcNum = (Math.floor(number * Math.pow(10, digit) + 1 / Math.pow(10, 9 - digit)) / Math.pow(10, Number(digit))).toFixed(Number(digit));
    return parseFloat(calcNum);
  },

  /**
   * 手机号隐藏中间4位
   * @param {string} phone 手机号
   */
  hidePhone: function hidePhone(phone) {
    if (phone) {
      return phone.replace(/^(\d{3})\d{4}(\d{4})$/, '$1****$2');
    }
  },
  // 资产记录状态
  assetsState: function assetsState(val) {
    switch (val) {
      case 'sending':
        return i18n.t('tradeLang.lang55');

      case 'completed':
        return i18n.t('tradeLang.lang56');

      case 'dismissed':
        return i18n.t('tradeLang.lang57');

      case 'revoke':
        return i18n.t('tradeLang.lang111');

      default:
        return '';
    }
  },
  entrustState: function entrustState(val) {
    switch (val) {
      case 'unfilled':
        return i18n.t('tradeLang.lang118');

      case 'section':
        return i18n.t('tradeLang.lang119');

      case 'all':
        return i18n.t('tradeLang.lang120');

      case 'cancel':
        return i18n.t('tradeLang.lang121');

      case 'uncancel':
        return i18n.t('tradeLang.lang122');

      default:
        return '';
    }
  }
};