// import {} from '@api/wallet'
var state = {
  marketSocketData: {},
  coinSocketData: {},
  coinTopData: [],
  contractSocketData: {},
  contractTopData: [],
  cycleSocketData: {},
  cycleTopData: []
};
var mutations = {
  MARKET_DATA: function MARKET_DATA(state, val) {
    state.marketSocketData = val;
  },
  EXCHANGE_COIN_DATA: function EXCHANGE_COIN_DATA(state, val) {
    state.coinSocketData = val;
  },
  COIN_TOP_DATA: function COIN_TOP_DATA(state, val) {
    state.coinTopData = val;
  },
  CONTRACT_COIN_DATA: function CONTRACT_COIN_DATA(state, val) {
    state.contractSocketData = val;
  },
  CONTRACT_TOP_DATA: function CONTRACT_TOP_DATA(state, val) {
    state.contractTopData = val;
  },
  CYCLE_COIN_DATA: function CYCLE_COIN_DATA(state, val) {
    state.cycleSocketData = val;
  },
  CYCLE_TOP_DATA: function CYCLE_TOP_DATA(state, val) {
    state.cycleTopData = val;
  }
};
var actions = {
  setMarketData: function setMarketData(_ref, data) {
    var commit = _ref.commit;
    commit('MARKET_DATA', data);
  },
  setExchangeCoinData: function setExchangeCoinData(_ref2, data) {
    var commit = _ref2.commit;
    commit('EXCHANGE_COIN_DATA', data);
  },
  setCoinTopData: function setCoinTopData(_ref3, data) {
    var commit = _ref3.commit;
    commit('COIN_TOP_DATA', data);
  },
  setContractCoinData: function setContractCoinData(_ref4, data) {
    var commit = _ref4.commit;
    commit('CONTRACT_COIN_DATA', data);
  },
  setConTopData: function setConTopData(_ref5, data) {
    var commit = _ref5.commit;
    commit('CONTRACT_TOP_DATA', data);
  },
  setCycleCoinData: function setCycleCoinData(_ref6, data) {
    var commit = _ref6.commit;
    commit('CYCLE_COIN_DATA', data);
  },
  setCycleTopData: function setCycleTopData(_ref7, data) {
    var commit = _ref7.commit;
    commit('CYCLE_TOP_DATA', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};