export default {
  lang1: "出金",
  lang2: "アカウント詳細",
  lang3: "記録",
  lang4: "注文",
  lang5: "メンバーシップレベル",
  lang6: "必要金額",
  lang7: "注文ごとの手数料",
  lang8: "注文数",
  lang9: "参加する",
  lang10: "利用可能残高",
  lang11: "凍結残高",
  lang12: "保留中",
  lang13: "支払済み",
  lang14: "決済済み",
  lang15: "まだデータがありません",
  lang16: "注文合計",
  lang17: "手数料",
  lang18: "注文を送信",
  lang19: "注文番号",
  lang20: "商品の利回り",
  lang21: "現在の残高",
  lang22: "計算式",
  lang23: "この注文からの利益",
  lang24: "送信",
  lang25: "今すぐ注文を取る",
  lang26: "手数料を取得",
  lang27: "今日のタスク",
  lang28: "今日完了",
  lang29: "手順",
  lang30: "「タスクを開始」ボタンをクリックし、指示に従ってタスクを完了してください。",
  lang31: "タスクを完了すると、手数料を残高に決済できます",
  lang32: "単価",
  lang33: "��ベート",
  lang34: "進行中の注文",
  lang35: "キャンセル待ち",
  lang36: "現在のレベルで注文を競争するユーザーが多いため、システムは注文の割り当てに懸命に取り組んでいます。現在11位にランクされています。しばらくお待ちください。",
  lang37: "ヒント：VIPレベルをアップグレードすると、より早く注文を取得できます",
  lang38: "ご質問やサポートが必要な場合は、オンラインカスタマーサービスにお問い合わせください",
  lang39: "ここをクリックして専属カスタマーサービスに連絡⭣⭣⭣",
  lang40: "FAQ",
  lang41: "チャージ方法と支払い方法は何ですか？",
  lang42: "このシステムは複数の国に開放されています。国際的な支払い方法を提供し、各国の現地通貨をサポートしています。",
  lang43: "投資して収入を得るにはどうすればよいですか？",
  lang44: "システムで注文を取得し、毎日安定した自動注文配置収入と利息を得ることができます。すべての収益は銀行カードに引き出すことができます。",
  lang45: "メンバーとして登録するにはどうすればよいですか？",
  lang46: "新規メンバーは招待に��り登録されます。登録済みメンバーの利益を確保し、過剰な登録による利益の減少を防ぐため、新規メンバーは既存メンバーの招待の下でのみ登録できます。",
  lang47: "購入金額に制限はありますか？",
  lang48: "はい、より多くのメンバーが利益を得る機会を持てるように、システム内の各アカウントには最小購入金額の制限があります。",
  lang49: "操作中に問題が発生した場合はどうすればよいですか？",
  lang50: "すべての操作を完了するのを支援するために、いつでもオンラインカスタマーサービスに連絡できます。",
  lang51: "カスタマーサービスに連絡",
  lang52: "ようこそ",
  lang53: "チャージ",
  lang54: "入出金記録",
  lang55: "マイチーム",
  lang56: "友達を招待",
  lang57: "出金用銀行カード",
  lang58: "セキュリティセンター",
  lang59: "設定",
  lang60: "銀行名",
  lang61: "ヘルプ",
  lang62: "マイ",
  lang63: "注文履歴",
  lang64: "カスタマーサービス",
  lang65: "Lowe's",
  lang66: 'カップルアクティビティ',
  lang67: 'カップルをバインド',
  lang68: 'Aがチャージ額に達する',
  lang69: 'Bがチャージ額に達���る',
  lang70: '請求可能',
  lang71: '参加する',
  lang72: 'アクティビティルール',
  lang73: 'カップルはバインドのために互いのIDを提供する必要があります。バインドが成功すると、両者は自分のアカウントで対応する報酬を請求できます。各アカウントは一度だけバインドでき、バインド後は再度バインドできません。カップルバインドアクティビティを通じて、パートナーと報酬アクティビティを楽しみ、お互いの関係を深めることができます。アクティビティで楽しい経験をお願いします！',
  lang74: 'プロセスの説明',
  lang75: 'ユーザーAがユーザーBのIDを入力し、同意を得て、システムがバインド情報を確認し、両ユーザーのバインド状態を確認し、両者が同意した後、バインドが成功します。',
  lang76: 'カップルIDを入力してください',
  lang77: 'キャンセル',
  lang78: '確認',
  lang79: '永遠に一緒に',
  lang80: '永遠の愛',
  lang81: 'あなただけを愛してる',
  lang82: '決して離れない',
  lang83: 'いつまでも一緒に',
  lang84: '資産明細',
  lang85: '注文ルール',
  lang86: 'プラットフォーム紹介',
  lang87: '資産明細',
  lang88: '資産明細',
  lang89: 'まだアカウントをお持ちでない方',
  lang90: '獲得した注文数',
  lang91: 'アカウント',
  lang92: '本日の結果',
  lang93: '手数料',
  lang94: 'プラットフォーム運営規則',
  lang95: '1. このプラットフォームは、加盟店と注文顧客により便利で迅速なサービスを提供するためにAIシステムを使用しています。',
  lang96: '2. このプラットフォームは、FLANNELSと提携する世界中の10万以上のeコマース加盟店にサービスを提供しています。商品の露出を増やすため、加盟店はこのプラットフォームで商品を販売しています。注文完了後、加盟店は注文金額と手数料を即時に返金します。プラットフォームは注文顧客と加盟店の注文をランダムにマッチングします。手数料は注文サイズとVIPレベルに関連しています。',
  lang97: '注文数はVIPレベルによって異なります。VIPレベルが高いほど、注文数と利益が増加します。',
  lang98: '3. プラットフォームシステムは、アカウント残高に基づいてVIPレベルを決定し、注文顧客をアップグレード/ダウングレードします。',
  lang99: '4. 注文顧客は、対応するVIPレベルの注文数を1日1回のみ完了できます。注文顧客は、対応するVIPレベルの注文数を完了した後でのみ出金できます。日次注文を完了した後、ユーザーが手数料を継続して獲得したい場合は、VIPレベルを上げるか、翌日の注文システムのリセットを待つことができます。',
  lang100: '5. 注文顧客のモチベーションを高めるため、プラットフォームはランダムにマッチングされるラッキー注文を提供しています。',
  lang101: '各顧客は3つの報酬注文を受け取るチャンスがあり、報酬は注文金額の20%-35%です',
  lang102: '① 最初のラッキー注文は注文金額の20%の報酬です。',
  lang103: '② 2番目のラッキー注文は注文金額の30%の報酬です。',
  lang104: '③ 3番目のラッキー注文は注文金額の35%の報酬です。',
  lang105: '6. プライバシーポリシー：FLANNELSプラットフォームは、すべての注文顧客のプライバシーを尊重し、注文顧客のプライバシー保護に特に重点を置いています。',
  lang106: 'FLANNELSプラットフォームは最終解釈権を留保し、ユーザーはルールを遵守するよう要請されます',
  lang107: 'FLANNELSは1976年にイギリス北部で設立され、現在は業界で最も刺激的なプレーヤーの1つとなっています。ブランドは現在、イギリスに50以上の店舗を持ち、既製服、アクセサリー、フットウェア、そして新しく立ち上げたユース＆ビューティーコレクションを展開しています。FLANNELS Beautyは2021年に立ち上げられ、"消費者第一"のアプローチでビューティー小売を革新することを約束しています。',
  lang108: 'FLANNELS Beauty BarsからFLANNELS Beauty Changing Roomsまで、最先端の店舗とファーストクラスの体験を備えた地域に高級ビューティーをもたらしています。次世代の小売リーダーに支えられ、新任CEOのMichael Murrayは、FLANNELSを新しいレベルに引き上げ、その比類のない提供を地方都市にもたらしています。グループエレベーション責任者として、Murrayは業界に衝撃を与えました - "私たちが最も得意とする種類の高級品に対する前例のない需要があります。',
  lang109: 'FLANNELSは小売を継続的に破壊し、再考し、私たちのビジョンを印象的に実証し、イギリスの主要都市に世界クラスのショッピング目的地を提供しています。" FLANNELSは、高級ファッションとビューティーの確立されたブランドと新興ブランドを持つスーパーインクルーシブなマルチカテゴリー小売業者であり、店舗内体験を復活させ、高級品をすべての人にアクセス可能にすることを約束しています。'
};