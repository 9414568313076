export default {
  lang1: "OK",
  lang2: "Không",
  lang3: "Bạn có muốn tải gói cài đặt mới không?",
  lang4: "Đang tải xuống",
  lang5: "Không thể tải xuống gói cập nhật",
  lang6: "Vui lòng mở lại ứng dụng sau khi cài đặt!",
  lang7: "Không thể cập nhật tài nguyên",
  lang8: "Hiện không có dịch vụ mạng, vui lòng kiểm tra kết nối mạng!",
  lang9: "Nhấn lại để thoát chương trình",
  lang10: "Cập nhật phiên bản APP",
  lang11: "1. Tối ưu hóa trải nghiệm người dùng; 2. Sửa một số lỗi đã biết.",
  lang12: "Cập nhật ngay",
  lang13: "Không thể tải xuống gói cài đặt!"
};