export default {
  lang1: 'Intercambio',
  lang2: 'Futuros',
  lang3: 'Nombre',
  lang4: 'Último precio',
  lang5: 'Cambiar%',
  lang6: 'Número',
  lang7: 'Precio',
  lang8: 'Tiempo',
  lang9: '1min',
  lang9a: '5min',
  lang10: '15min',
  lang10a: '30min',
  lang11: '1h',
  lang12: '4h',
  lang13: '1D',
  lang13a: '1W',
  lang14: 'Índice',
  lang14a: 'Más',
  lang15: 'Alto',
  lang16: 'Bajo',
  lang17: '24H',
  lang18: 'Mapa',
  lang19: 'Trato',
  lang20: 'Introducción',
  lang21: 'Tiempo',
  lang22: 'Dirección',
  lang23: 'Comprar',
  lang24: 'Vender',
  lang25: 'Total emitido',
  lang26: 'Sitio web oficial',
  lang27: 'Explorador de bloques',
  lang28: 'Comprar/largo',
  lang29: 'Comprar/corto',
  lang30: 'Token de búsqueda',
  lang31: 'Visto recientemente',
  lang32: 'No hay moneda relacionada',
  lang33: 'Esta moneda aún no está abierta para el comercio',
  lang34: 'Conversión de activos totales',
  lang35: 'Depósito',
  lang36: 'Retirar',
  lang37: 'Transferir',
  lang38: 'Intercambio',
  lang39: 'Conversión de activos de billetera',
  lang40: 'Ocultar moneda pequeña',
  lang41: 'Buscar',
  lang42: 'Disponible',
  lang43: 'Bloqueado',
  lang44: 'Estimado',
  lang45: 'Cantidad retenida',
  lang46: 'Margen de posición',
  lang47: 'P/L',
  lang48: 'Margen confiado',
  lang49: 'Activos totales',
  lang50: 'Depósito',
  lang51: 'Retirar',
  lang52: 'Otro',
  lang53: 'Estado:',
  lang54: 'Dirección:',
  lang55: 'Esperando',
  lang56: 'Completado',
  lang57: 'rechazado',
  lang58: 'Cambiar token',
  lang59: 'Nombre de la cadena',
  lang60: 'Dirección de depósito',
  lang61: 'Por favor, compruebe y rellene la etiqueta de la dirección con cuidado, si se omite la etiqueta, los fondos se perderán',
  lang62: 'No deposite activos que no sean {monedas} en la dirección anterior, de lo contrario, los activos no se recuperarán',
  lang63: 'Cantidad mínima de depósito {low}{coin}, no se acreditará un depósito inferior a la cantidad mínima de depósito',
  lang64: 'Dirección de la billetera',
  lang65: 'Ingrese o pegue la dirección de la billetera',
  lang66: 'Ingrese el monto del retiro',
  lang67: 'Todos',
  lang68: 'Tarifa',
  lang69: 'Cantidad a la cuenta',
  lang70: 'Nota: el retiro máximo es {oneoutheight} una vez, el retiro mínimo es {low} y el retiro máximo es {height} por día',
  lang71: 'Ingrese la contraseña del fondo',
  lang72: 'Contraseña de fondos',
  lang73: 'Dirección de la billetera',
  lang74: 'No ha agregado la dirección de la billetera de esta moneda',
  lang75: 'Ir a agregar',
  lang76: 'Autenticación',
  lang77: 'Por favor complete primero la autenticación de identidad',
  lang78: 'autenticación',
  lang79: 'Primero establezca la contraseña del fondo de la cuenta',
  lang80: 'configuración',
  lang81: '¡Saldo insuficiente!',
  lang82: 'Utilice el escaneo en la APLICACIÓN',
  lang83: 'Escanear',
  lang84: 'a',
  lang85: 'Moneda',
  lang86: 'Ingrese el monto de la transferencia',
  lang87: 'Solo se pueden negociar los activos mediante la transferencia de los activos a la cuenta correspondiente',
  lang88: 'moneda francesa',
  lang89: 'Saldo de cuenta insuficiente',
  lang90: 'Valoración:',
  lang91: 'Nota: el tipo de cambio mínimo es {low} y el tipo de cambio máximo es {height}.',
  lang92: 'Registro de intercambio',
  lang93: 'a',
  lang94: 'consumo',
  lang95: 'a la cuenta',
  lang96: 'Ingrese el monto de cambio correcto',
  lang97: 'Dirección de retiro',
  lang98: 'La dirección de retiro no ha sido agregada~',
  lang99: 'Agregar dirección de retiro',
  lang100: 'Dirección de retiro',
  lang101: 'Por favor ingrese la dirección',
  lang102: 'Observaciones',
  lang103: 'Por favor ingrese una nota',
  lang104: '¿Está seguro de eliminar la dirección?',
  lang105: 'Intercambio',
  lang106: 'Comercio al mejor precio',
  lang107: 'Total',
  lang108: 'Orden actual',
  lang108a: 'Más',
  lang109: 'Límite',
  lang110: 'Mercado',
  lang111: 'Cerrar',
  lang112: 'Precio del pedido',
  lang113: 'Tiempo',
  lang114: 'Cantidad',
  lang115: 'No comercializado',
  lang116: 'Total',
  lang117: 'Estado',
  lang118: 'No comercializado',
  lang119: 'Trato parcial',
  lang120: 'todos',
  lang121: 'Cancelar todo',
  lang122: 'Deshacer parcial',
  lang123: 'Por favor ingrese el precio',
  lang124: 'Por favor ingrese el precio correcto',
  lang125: 'Ingrese un precio mayor que 0',
  lang126: 'Por favor ingrese la cantidad',
  lang127: 'Por favor ingrese la cantidad correcta',
  lang128: 'El monto mínimo de compra es {min}',
  lang129: 'Cantidad de retención excedida',
  lang130: 'Confirmar para comprar {moneda}',
  lang131: 'La cantidad mínima de venta es {min}',
  lang132: 'Confirmar para vender {moneda}',
  lang133: '¿Confirmar para cerrar el pedido?',
  lang134: 'Orden histórico',
  lang135: 'Abrir',
  lang136: 'Posición',
  lang137: 'Cerrar todas las posiciones',
  lang138: 'Comprar/largo',
  lang139: 'comprar/cortar',
  lang140: 'Compartir',
  lang141: 'Tomar precio de beneficio',
  lang142: 'Precio Stop Loss',
  lang143: 'Margen',
  lang144: 'Orden actual',
  lang145: 'Largo',
  lang146: 'Corto',
  lang147: 'Aislar',
  lang148: 'Cruz',
  lang149: 'Precio',
  lang150: 'Precio',
  lang151: 'Número',
  lang152: 'No comercializado',
  lang153: 'Tipo',
  lang154: 'Estimado',
  lang155: 'P/L',
  lang155a: 'Interés',
  lang156: 'Valor de posición',
  lang157: 'Detener P/L',
  lang158: 'Cierre rápido',
  lang159: 'Cerrar',
  lang160: 'Disponible',
  lang161: 'Margen de posición',
  lang162: 'Número de posiciones cerradas',
  lang163: 'Ingrese el monto de cierre',
  lang164: 'Intercambiar',
  lang165: 'Cerrar todo',
  lang166: 'Cerrar largo',
  lang167: 'Cierre corto',
  lang168: 'Por favor ingrese el precio',
  lang169: 'Ingrese el precio correcto',
  lang170: 'Por favor ingrese la cantidad',
  lang171: 'Ingrese un número entero',
  lang172: 'El monto máximo de compra es {max}',
  lang173: 'La cantidad máxima de venta es {max}',
  lang174: 'El precio de toma de ganancias no puede ser inferior al precio de apertura de la posición larga',
  lang175: 'El precio de stop loss no puede ser más alto que el precio de la posición larga abierta',
  lang176: 'El precio de toma de ganancias no puede ser mayor que el precio de la posición corta abierta',
  lang177: 'El precio de stop loss no puede ser inferior al precio de apertura corto',
  lang178: 'Ya sea para abrir largas {tradeNum} {tradeCoin}, el margen es de aproximadamente {bondNum}{mainCoin}',
  lang179: 'Ya sea para abrir corto {tradeNum} {tradeCoin}, el margen es de aproximadamente {bondNum}{mainCoin}',
  lang180: '¿Confirmar cierre rápido?',
  lang181: 'Grabar',
  lang182: 'Posición',
  lang183: 'Posición histórica',
  lang184: 'Orden actual',
  lang185: 'Orden de la historia',
  lang186: 'Precio cerrado',
  lang187: 'Hora de cierre',
  lang188: '¿Confirmar el cierre con un clic?',
  lang189: 'Nombre',
  lang190: 'Precio',
  lang191: 'Precio',
  lang192: 'Dirección',
  lang193: 'Cerrar número',
  lang194: 'Tomar precio de beneficio',
  lang195: 'Ingresos:',
  lang196: 'Precio Stop Loss',
  lang197: 'Ingrese el precio de toma de ganancias',
  lang198: 'Ingrese el precio de límite de pérdidas',
  lang199: 'Grabar',
  lang200: 'Modificar',
  lang201: 'Ingrese la cantidad correcta para cerrar la posición',
  lang202: 'Ingrese el precio de toma de ganancias correcto',
  lang203: 'El precio de toma de ganancias debe ser más alto que el precio de apertura',
  lang204: 'El precio de toma de ganancias debe ser más bajo que el precio de apertura',
  lang205: 'Ingrese el precio de stop loss correcto',
  lang206: 'El precio de stop loss debe ser más alto que el precio de apertura',
  lang207: 'El precio de parada debe ser más bajo que el precio de apertura',
  lang208: 'Ingrese el precio de toma de ganancias o el precio de detención de pérdidas',
  lang209: 'Tú ganas',
  lang210: 'Tú pierdes',
  lang211: 'Rendimiento',
  lang212: 'Intercambiar',
  lang213: 'Precio cerrado',
  lang214: 'Ponte al día',
  lang215: 'Más allá',
  lang216: 'Líder',
  lang217: 'Guardar',
  lang218: 'Guardar la imagen con éxito',
  lang219: 'Error al guardar la imagen',
  lang220: 'Cantidad de compra',
  lang221: 'Ingrese la cantidad de compra',
  lang222: 'Método de pago',
  lang223: 'Estimado: ',
  lang224: 'Nota: el monto mínimo de compra es {low}{coin} y el monto máximo de compra es {high}{coin}',
  lang225: 'ir a pagar',
  lang226: 'Registro de compra',
  lang227: 'A pagar',
  lang228: 'Completado',
  lang229: 'Cancelado',
  lang230: 'Ingrese la cantidad de compra correcta',
  lang231: 'La cantidad de compra no puede ser inferior a la cantidad mínima de compra',
  lang232: 'La cantidad de compra no puede ser superior a la cantidad máxima de compra',
  lang233: 'Ingresos',
  lang234: 'Rendimiento',
  lang235: 'Comprar/largo',
  lang236: 'Comprar/corto',
  lang237: 'Orden de posición',
  lang238: 'Precio',
  lang239: 'Precio',
  lang240: 'Cantidad total',
  lang241: 'P/L',
  lang242: 'Ciclo',
  lang243: 'Tarifa',
  lang244: 'Orden histórico',
  lang245: 'Precio',
  lang246: 'Hora de cierre',
  lang247: 'Orden de ganancias',
  lang248: 'Tiempo',
  lang249: 'Dirección',
  lang250: 'ingreso',
  lang251: 'Precio',
  lang252: 'Contrato de ciclo',
  lang253: 'Activos',
  lang254: 'Disponible',
  lang255: 'Número',
  lang256: 'Mínimo {min}{coin}',
  lang257: 'Esperado:',
  lang258: 'Esperado:',
  lang259: 'Ingrese la cantidad de la transacción',
  lang260: 'Por favor ingrese la cantidad correcta',
  lang261: 'Saldo insuficiente',
  lang262: 'El número debe ser mayor que {min}',
  lang263: 'El número debe ser menor que {max}',
  lang264: 'Los datos push de websocket son anormales, ¡inténtalo de nuevo más tarde!',
  lang265: 'La conexión websocket no es normal, ¡inténtalo de nuevo más tarde!',
  lang266: 'Beneficio',
  lang267: 'Pérdida',
  lang268: 'Precio de apertura:',
  lang269: 'Cantidad del pedido:',
  lang270: 'Cuenta regresiva:',
  lang271: 'Todas las posiciones históricas',
  lang272: 'Gestión de pedidos',
  lang273: 'Registro de trato',
  lang274: 'Vol',
  lang275: 'Cantidad del depósito',
  lang276: 'Por favor ingrese el monto del depósito',
  lang277: 'Orden de depósito',
  lang278: 'Por favor ingrese una orden de depósito',
  lang279: 'Ganancias de hoy',
  lang280: 'Los ingresos totales',
  lang281: 'Titular de la tarjeta'
};