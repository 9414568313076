var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view", {
        staticClass: "child-view",
        class: _vm.transitionName
      }),
      _c("nav-footer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showFooter,
            expression: "showFooter"
          }
        ]
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showLoading,
              expression: "showLoading"
            }
          ],
          staticClass: "loading loading_ajax"
        },
        [_c("van-loading")],
        1
      ),
      _c("appUpdate")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }