export default {
  lang1: 'Exchange',
  lang2: 'Futures',
  lang3: 'Name',
  lang4: 'Last Price',
  lang5: 'Change%',
  lang6: 'Number',
  lang7: 'Price',
  lang8: 'Time',
  lang9: '1min',
  lang9a: '5min',
  lang10: '15min',
  lang10a: '30min',
  lang11: '1h',
  lang12: '4h',
  lang13: '1D',
  lang13a: '1W',
  lang14: 'Index',
  lang14a: 'More',
  lang15: 'High',
  lang16: 'Low',
  lang17: '24H',
  lang18: 'Map',
  lang19: 'Deal',
  lang20: 'Introduction',
  lang21: 'Time',
  lang22: 'Direction',
  lang23: 'Buy',
  lang24: 'Sell',
  lang25: 'Total Issued',
  lang26: 'Official website',
  lang27: 'Block Explorer',
  lang28: 'Buy',
  lang29: 'Sell',
  lang30: 'Search Token',
  lang31: 'Recently viewed',
  lang32: 'There is no related currency',
  lang33: 'This currency is not open for trading yet',
  lang34: 'Total asset',
  lang35: 'Deposit',
  lang36: 'Withdraw',
  lang37: 'Transfer',
  lang38: 'Exchange',
  lang39: 'Wallet asset conversion',
  lang40: 'Hide Small Currency',
  lang41: 'Search',
  lang42: 'Available',
  lang43: 'Locked',
  lang44: 'Estimated',
  lang45: 'Hold Amount',
  lang46: 'Position margin',
  lang47: 'P/L',
  lang48: 'Entrusted margin',
  lang49: 'Total Assets',
  lang50: 'Deposit',
  lang51: 'Withdraw',
  lang52: 'Other',
  lang53: 'Status:',
  lang54: 'Address:',
  lang55: 'Waiting',
  lang56: 'Completed',
  lang57: 'rejected',
  lang58: 'Switch Token',
  lang59: 'Chain name',
  lang60: 'Deposit address',
  lang61: 'Please check and fill in the address label carefully, if the label is omitted, the funds will be lost',
  lang62: 'Do not deposit any non-{coin} assets to the above address, otherwise the assets will not be recovered',
  lang63: 'Minimum deposit amount {low}{coin}, deposit less than the minimum deposit amount will not be credited',
  lang64: 'Wallet address',
  lang65: 'Please enter or paste the wallet address',
  lang66: 'Please enter the withdrawal amount',
  lang67: 'All',
  lang68: 'Fee',
  lang69: 'Amount to account',
  lang70: 'Note: The maximum withdrawal is {oneoutheight} once, the minimum withdrawal is {low}, and the maximum withdrawal is {height} per day',
  lang71: 'Please enter the fund password',
  lang72: 'Funding Password',
  lang73: 'Wallet address',
  lang74: 'You have not added the wallet address of this currency',
  lang75: 'Go to add',
  lang76: 'Authentication',
  lang77: 'Please complete the identity authentication first',
  lang78: 'authentication',
  lang79: 'Please set the account fund password first',
  lang80: 'settings',
  lang81: 'Insufficient balance!',
  lang82: 'Please use the scan in the APP',
  lang83: 'Scan',
  lang84: 'to',
  lang85: 'Currency',
  lang86: 'Please enter the transfer amount',
  lang87: 'Only the assets can be traded by transferring the assets to the corresponding account',
  lang88: 'French currency',
  lang89: 'Insufficient account balance',
  lang90: 'Rate:',
  lang91: 'Note: The minimum exchange rate is {low}, and the maximum exchange rate is {height}.',
  lang92: 'Exchange record',
  lang93: 'to',
  lang94: 'consumption',
  lang95: 'to the account',
  lang96: 'Please enter the correct exchange amount',
  lang97: 'Withdrawal address',
  lang98: 'The withdrawal address has not been added~',
  lang99: 'Add withdrawal address',
  lang100: 'Withdrawal address',
  lang101: 'Please enter the address',
  lang102: 'Remarks',
  lang103: 'Please enter a note',
  lang104: 'Are you sure to delete the address?',
  lang105: 'Exchange',
  lang106: 'Trade at best price',
  lang107: 'Total',
  lang108: 'Current Order',
  lang108a: 'More',
  lang109: 'Limit',
  lang110: 'Market',
  lang111: 'Close',
  lang112: 'Order Price',
  lang113: 'Time',
  lang114: 'Amount',
  lang115: 'Untraded',
  lang116: 'Total',
  lang117: 'Status',
  lang118: 'Not traded',
  lang119: 'Partial deal',
  lang120: 'All transactions',
  lang121: 'Cancel all',
  lang122: 'Partial undo',
  lang123: 'Please enter the price',
  lang124: 'Please enter the correct price',
  lang125: 'Please enter a price greater than 0',
  lang126: 'Please enter the quantity',
  lang127: 'Please enter the correct quantity',
  lang128: 'The minimum purchase amount is {min}',
  lang129: 'Exceeded holding quantity',
  lang130: 'Confirm to buy {coin}',
  lang131: 'The minimum selling amount is {min}',
  lang132: 'Confirm to sell {coin}',
  lang133: 'Confirm to close the order?',
  lang134: 'Historical Order',
  lang135: 'Open',
  lang136: 'Position',
  lang137: 'Close all position',
  lang138: 'Buy',
  lang139: 'Sell',
  lang140: 'Share',
  lang141: 'Take Profit Price',
  lang142: 'Stop Loss Price',
  lang143: 'Margin',
  lang144: 'Current Order',
  lang145: 'Long',
  lang146: 'Short',
  lang147: 'Isolate',
  lang148: 'Cross',
  lang149: 'Price',
  lang150: 'Open price',
  lang151: 'Number',
  lang152: 'Untraded',
  lang153: 'Type',
  lang154: 'Estimated',
  lang155: 'P/L',
  lang155a: 'Interest',
  lang156: 'Position value',
  lang157: 'Stop P/L',
  lang158: 'Fast close',
  lang159: 'Close',
  lang160: 'Available',
  lang161: 'Position margin',
  lang162: 'Number of closed positions',
  lang163: 'Please enter the closing amount',
  lang164: 'Swap',
  lang165: 'Close all',
  lang166: 'Close long',
  lang167: 'Close short',
  lang168: 'Please enter the price',
  lang169: 'Please enter the correct price',
  lang170: 'Please enter the quantity',
  lang171: 'Please enter an integer number',
  lang172: 'The maximum purchase amount is {max}',
  lang173: 'Maximum selling amount is {max}',
  lang174: 'The take profit price cannot be lower than the open long position price',
  lang175: 'The stop loss price cannot be higher than the open long position price',
  lang176: 'The take profit price cannot be higher than the open short position price',
  lang177: 'The stop loss price cannot be lower than the short opening price',
  lang178: 'Whether to open long {tradeNum} {tradeCoin}, the margin is about {bondNum}{mainCoin}',
  lang179: 'Whether to open short {tradeNum} {tradeCoin}, the margin is about {bondNum}{mainCoin}',
  lang180: 'Confirm fast closing?',
  lang181: 'Record',
  lang182: 'Position',
  lang183: 'History position',
  lang184: 'Current Order',
  lang185: 'History Order',
  lang186: 'Closed price',
  lang187: 'Closed time',
  lang188: 'Confirm one-click closing?',
  lang189: 'Name',
  lang190: 'Price',
  lang191: 'Open price',
  lang192: 'Direction',
  lang193: 'Close number',
  lang194: 'Take Profit Price',
  lang195: 'Revenue:',
  lang196: 'Stop Loss Price',
  lang197: 'Please enter the take profit price',
  lang198: 'Please enter the stop loss price',
  lang199: 'Record',
  lang200: 'Modify',
  lang201: 'Please enter the correct amount to close the position',
  lang202: 'Please enter the correct take profit price',
  lang203: 'The take profit price must be higher than the opening price',
  lang204: 'The take profit price must be lower than the opening price',
  lang205: 'Please enter the correct stop loss price',
  lang206: 'The stop loss price must be higher than the opening price',
  lang207: 'The stop price must be lower than the opening price',
  lang208: 'Please enter the take profit price or stop loss price',
  lang209: 'You win',
  lang210: 'You loss',
  lang211: 'Yield',
  lang212: 'Swap',
  lang213: 'Closed price',
  lang214: 'Catch up',
  lang215: 'Beyond',
  lang216: 'Leading',
  lang217: 'Save',
  lang218: 'Save the picture successfully',
  lang219: 'Failed to save image',
  lang220: 'Purchase Quantity',
  lang221: 'Please enter the purchase quantity',
  lang222: 'Payment method',
  lang223: 'Estimated: ',
  lang224: 'Note: The minimum purchase amount is {low}{coin}, and the maximum purchase amount is {high}{coin}',
  lang225: 'go to pay',
  lang226: 'Purchase record',
  lang227: 'To be paid',
  lang228: 'Completed',
  lang229: 'Cancelled',
  lang230: 'Please enter the correct purchase quantity',
  lang231: 'The purchase quantity cannot be lower than the minimum purchase quantity',
  lang232: 'The purchase quantity cannot be higher than the maximum purchase quantity',
  lang233: 'Income',
  lang234: 'Yield',
  lang235: 'Buy',
  lang236: 'Sell',
  lang237: 'Position order',
  lang238: 'Open price',
  lang239: 'Price',
  lang240: 'Total amount',
  lang241: 'P/L',
  lang242: 'Cycle',
  lang243: 'Fee',
  lang244: 'Historical Order',
  lang245: 'Close price',
  lang246: 'Close time',
  lang247: 'Profit order',
  lang248: 'Time',
  lang249: 'Direction',
  lang250: 'Profit',
  lang251: 'Close price',
  lang252: 'Cycle Contract',
  lang253: 'Assets',
  lang254: 'Available',
  lang255: 'Number',
  lang256: 'Minimum {min}{coin}',
  lang257: 'Expected:',
  lang258: 'Expected:',
  lang259: 'Please enter the transaction quantity',
  lang260: 'Please enter the correct quantity',
  lang261: 'Insufficient balance',
  lang262: 'Number must be higher than {min}',
  lang263: 'Number must be less than {max}',
  lang264: 'The websocket push data is abnormal, please try again later!',
  lang265: 'The websocket connection is abnormal, please try again later!',
  lang266: 'Profit',
  lang267: 'Loss',
  lang268: 'Open price:',
  lang269: 'Order Amount:',
  lang270: 'Countdown:',
  lang271: 'All historical positions',
  lang272: 'Order Management',
  lang273: 'Deal record',
  lang274: 'Vol',
  lang275: 'Deposit amount',
  lang276: 'Please enter the deposit amount',
  lang277: 'Deposit order',
  lang278: 'Please enter a deposit order',
  lang279: "Today's profit",
  lang280: "Total profit",
  lang281: "cardholder"
};