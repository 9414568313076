var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-popup",
    {
      staticClass: "update_box",
      attrs: { "close-on-click-overlay": false },
      model: {
        value: _vm.isAppUpdate,
        callback: function($$v) {
          _vm.isAppUpdate = $$v
        },
        expression: "isAppUpdate"
      }
    },
    [
      _c("div", { staticClass: "update_top_img" }, [
        _c("img", {
          attrs: {
            src: require("@img/" +
              _vm.$store.state.common.theme +
              "/update_top_img.png"),
            alt: ""
          }
        }),
        _c("p", [_vm._v("V1." + _vm._s(_vm.versionData.code))])
      ]),
      _c("div", { staticClass: "update_bottom_box" }, [
        _c("h3", [_vm._v(_vm._s(_vm.$t("plusLang.lang10")))]),
        _c("p", { staticClass: "update_text" }, [
          _vm._v(_vm._s(_vm.versionData.info || _vm.$t("plusLang.lang11")))
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showProgress,
                expression: "!showProgress"
              }
            ],
            staticClass: "update_btn"
          },
          [
            _c(
              "van-button",
              {
                attrs: { type: "primary", size: "large" },
                on: {
                  click: function($event) {
                    return _vm.createDownloadTask()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("plusLang.lang12")))]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showProgress,
                expression: "showProgress"
              }
            ],
            staticClass: "download_progress_box"
          },
          [
            _c("h4", [
              _vm._v(
                _vm._s(_vm.$t("plusLang.lang4")) +
                  " (" +
                  _vm._s(Math.floor(_vm.downloadProgress * 100)) +
                  "%)"
              )
            ]),
            _c("progress", { domProps: { value: _vm.downloadProgress } })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }