//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    goodsList: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      isPopupVisible: false
    };
  },
  methods: {
    getImgUrl: function getImgUrl(url) {
      return "/api/res/preview/".concat(url);
    },
    handleSubmit: function handleSubmit(data) {
      this.$router.push({
        name: 'orderSubmit',
        query: data
      });
    }
  }
};