var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "van-collapse",
        {
          attrs: { accordion: "" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "van-collapse-item",
            { attrs: { title: _vm.$t("addLang.lang41"), name: "1" } },
            [_vm._v(" " + _vm._s(_vm.$t("addLang.lang42")) + " ")]
          ),
          _c(
            "van-collapse-item",
            { attrs: { title: _vm.$t("addLang.lang43"), name: "2" } },
            [_vm._v(" " + _vm._s(_vm.$t("addLang.lang44")) + " ")]
          ),
          _c(
            "van-collapse-item",
            { attrs: { title: _vm.$t("addLang.lang45"), name: "3" } },
            [_vm._v(" " + _vm._s(_vm.$t("addLang.lang46")) + " ")]
          ),
          _c(
            "van-collapse-item",
            { attrs: { title: _vm.$t("addLang.lang47"), name: "4" } },
            [_vm._v(" " + _vm._s(_vm.$t("addLang.lang48")) + " ")]
          ),
          _c(
            "van-collapse-item",
            { attrs: { title: _vm.$t("addLang.lang49"), name: "5" } },
            [_vm._v(" " + _vm._s(_vm.$t("addLang.lang50")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }