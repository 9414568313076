export default {
  lang1: "Penarikan",
  lang2: "Detail Akun",
  lang3: "Catatan",
  lang4: "pesanan",
  lang5: "Level Keanggotaan",
  lang6: "Jumlah yang Diperlukan",
  lang7: "Komisi per pesanan",
  lang8: "Jumlah pesanan",
  lang9: "Bergabung",
  lang10: "Saldo tersedia",
  lang11: "Saldo Beku",
  lang12: "Tertunda",
  lang13: "Dibayar",
  lang14: "Diselesaikan",
  lang15: "Belum ada data",
  lang16: "Total Pesanan",
  lang17: "komisi",
  lang18: "Kirim Pesanan",
  lang19: "Nomor Pesanan",
  lang20: "Hasil produk",
  lang21: "Saldo Saat Ini",
  lang22: "Rumus perhitungan",
  lang23: "Keuntungan dari pesanan ini",
  lang24: "kirim",
  lang25: "Ambil pesanan sekarang",
  lang26: "Dapatkan komisi",
  lang27: "Tugas Hari Ini",
  lang28: "Selesai hari ini",
  lang29: "Prosedur",
  lang30: "Klik tombol \"Mulai Tugas\" dan ikuti petunjuk untuk menyelesaikan tugas.",
  lang31: "Setelah menyelesaikan tugas, Anda dapat menyelesaikan komisi ke saldo",
  lang32: "harga satuan",
  lang33: "Potongan harga",
  lang34: "Pesanan dalam proses",
  lang35: "Batalkan antrian",
  lang36: "Karena banyaknya pengguna yang bersaing untuk pesanan di level saat ini, sistem sedang bekerja keras untuk menetapkan pesanan. Saat ini berada di peringkat 11. Harap bersabar.",
  lang37: "Tips: Meningkatkan level VIP Anda dapat membantu Anda mendapatkan pesanan lebih cepat",
  lang38: "Jika Anda memiliki pertanyaan atau membutuhkan bantuan, silakan hubungi layanan pelanggan online",
  lang39: "Klik di sini untuk menghubungi layanan pelanggan eksklusif Anda⭣⭣⭣",
  lang40: "FAQ",
  lang41: "Bagaimana cara isi ulang dan apa metode pembayarannya?",
  lang42: "Sistem ini terbuka untuk beberapa negara. Kami menyediakan metode pembayaran internasional dan mendukung mata uang lokal untuk berbagai negara.",
  lang43: "Bagaimana cara berinvestasi dan mendapatkan penghasilan?",
  lang44: "Anda dapat mengambil pesanan dalam sistem dan mendapatkan pendapatan penempatan pesanan otomatis yang stabil dan bunga setiap hari. Semua penghasilan dapat ditarik ke kartu bank Anda.",
  lang45: "Bagaimana cara mendaftar sebagai anggota?",
  lang46: "Anggota baru didaftarkan melalui undangan. Untuk memastikan manfaat anggota terdaftar dan mencegah pendaftaran berlebihan mengurangi manfaat, anggota baru hanya dapat mendaftar di bawah undangan anggota yang ada.",
  lang47: "Apakah ada batas jumlah pembelian?",
  lang48: "Ya, agar lebih banyak anggota memiliki kesempatan untuk mendapatkan manfaat, setiap akun dalam sistem memiliki batas jumlah pembelian minimum.",
  lang49: "Apa yang harus saya lakukan jika saya mengalami masalah selama operasi?",
  lang50: "Anda dapat menghubungi layanan pelanggan online kapan saja untuk membantu menyelesaikan semua operasi.",
  lang51: "Hubungi Layanan Pelanggan",
  lang52: "selamat datang",
  lang53: "isi ulang",
  lang54: "Catatan Setoran dan Penarikan",
  lang55: "Tim Saya",
  lang56: "Undang teman",
  lang57: "Kartu bank penarikan",
  lang58: "Pusat Keamanan",
  lang59: "pengaturan",
  lang60: "Nama Bank",
  lang61: "bantuan",
  lang62: "saya",
  lang63: "Riwayat Pesanan",
  lang64: "Layanan Pelanggan",
  lang65: "Lowe's",
  lang66: 'Aktivitas Pasangan',
  lang67: 'Hubungkan Pasangan',
  lang68: 'A mencapai jumlah isi ulang',
  lang69: 'B mencapai jumlah isi ulang',
  lang70: 'Dapat diklaim',
  lang71: 'Berpartisipasi',
  lang72: 'Aturan Aktivitas',
  lang73: 'Pasangan perlu memberikan ID satu sama lain untuk menghubungkan. Setelah berhasil terhubung, kedua belah pihak dapat mengklaim hadiah yang sesuai ke akun mereka sendiri. Setiap akun hanya dapat terhubung sekali dan setelah terhubung tidak dapat terhubung lagi. Melalui aktivitas menghubungkan pasangan, Anda dapat menikmati aktivitas hadiah dengan pasangan Anda dan memperdalam hubungan satu sama lain. Kami berharap Anda memiliki pengalaman yang menyenangkan dalam aktivitas!',
  lang74: 'Deskripsi Proses',
  lang75: 'Pengguna A memasukkan ID pengguna B, mendapatkan persetujuan, sistem memverifikasi informasi tautan dan memeriksa status tautan kedua pengguna, setelah kedua belah pihak setuju, tautan berhasil.',
  lang76: 'Silakan masukkan ID pasangan',
  lang77: 'Batal',
  lang78: 'Konfirmasi',
  lang79: 'Selamanya',
  lang80: 'Cinta abadi',
  lang81: 'Hanya mencintaimu',
  lang82: 'Tak pernah berpisah',
  lang83: 'Bersama selamanya',
  lang84: 'Detail Aset',
  lang85: 'Aturan Pesanan',
  lang86: 'Pengenalan Platform',
  lang87: 'Detail Aset',
  lang88: 'Detail Aset',
  lang89: 'Belum punya akun?',
  lang90: 'Jumlah Pesanan yang Diambil',
  lang91: 'Akun',
  lang92: 'Hasil Hari Ini',
  lang93: 'Komisi',
  lang94: 'Aturan Operasi Platform',
  lang95: '1. Platform ini menggunakan sistem AI untuk layanan yang lebih nyaman dan cepat bagi pedagang dan pelanggan pesanan;',
  lang96: '2. Platform ini melayani lebih dari 100.000 pedagang e-commerce di seluruh dunia yang bekerja sama dengan FLANNELS. Untuk meningkatkan eksposur produk, pedagang menampilkan produk mereka untuk dijual di platform ini. Setelah menyelesaikan pesanan, pedagang segera mengembalikan jumlah pesanan dan komisi. Platform secara acak mencocokkan pelanggan pesanan dengan pesanan pedagang. Komisi terkait dengan ukuran pesanan dan level VIP;',
  lang97: 'Jumlah pesanan tergantung pada level VIP. Semakin tinggi level VIP, semakin banyak pesanan dan keuntungan yang lebih tinggi;',
  lang98: '3. Sistem platform menentukan level VIP berdasarkan saldo akun dan meningkatkan/menurunkan pelanggan pesanan;',
  lang99: '4. Pelanggan pesanan hanya dapat menyelesaikan jumlah pesanan level VIP yang sesuai sekali per hari. Pelanggan pesanan dapat menarik uang hanya setelah menyelesaikan jumlah pesanan level VIP yang sesuai. Setelah menyelesaikan pesanan harian, jika pengguna ingin terus mendapatkan komisi, mereka dapat meningkatkan level VIP mereka atau menunggu reset sistem pesanan keesokan harinya;',
  lang100: '5. Untuk memotivasi pelanggan pesanan, platform menawarkan pesanan beruntung yang dicocokkan secara acak;',
  lang101: 'Setiap pelanggan memiliki kesempatan untuk menerima tiga pesanan hadiah, dengan hadiah sebesar 20%-35% dari jumlah pesanan',
  lang102: '① Pesanan beruntung pertama memberikan hadiah 20% dari jumlah pesanan.',
  lang103: '② Pesanan beruntung kedua memberikan hadiah 30% dari jumlah pesanan.',
  lang104: '③ Pesanan beruntung ketiga memberikan hadiah 35% dari jumlah pesanan.',
  lang105: '6. Kebijakan Privasi: Platform FLANNELS menghormati privasi setiap pelanggan pesanan dan memberikan penekanan khusus pada perlindungan privasi pelanggan pesanan;',
  lang106: 'Platform FLANNELS berhak atas interpretasi akhir, pengguna diminta untuk mematuhi aturan',
  lang107: 'FLANNELS didirikan pada tahun 1976 di Inggris Utara dan sekarang menjadi salah satu pemain paling menarik di industri ini. Merek ini sekarang memiliki lebih dari 50 toko di Inggris dan menjual pakaian siap pakai, aksesori, alas kaki, dan koleksi remaja dan kecantikan yang baru diluncurkan. FLANNELS Beauty diluncurkan pada tahun 2021 dan berkomitmen untuk mengganggu ritel kecantikan dengan pendekatan "konsumen pertama".',
  lang108: 'Dari FLANNELS Beauty Bars hingga FLANNELS Beauty Changing Rooms, ini membawa kecantikan mewah ke daerah dengan toko-toko canggih dan pengalaman kelas satu. Didukung oleh generasi berikutnya dari pemimpin ritel, CEO yang akan datang Michael Murray membawa FLANNELS ke level baru dan membawa penawarannya yang tak tertandingi ke kota-kota regional. Sebagai Group Head of Elevation, Murray telah mengguncang industri - "Ada permintaan yang belum pernah terjadi sebelumnya untuk jenis kemewahan yang kami lakukan dengan baik.',
  lang109: 'FLANNELS terus mengganggu dan memikirkan kembali ritel, menunjukkan visi kami secara mengesankan dan menyediakan tujuan belanja kelas dunia untuk kota-kota besar Inggris." FLANNELS adalah pengecer multi-kategori yang super inklusif dengan merek-merek mapan dan baru dalam mode dan kecantikan mewah, berkomitmen untuk menghidupkan kembali pengalaman di toko dan membuat kemewahan dapat diakses oleh semua orang.'
};