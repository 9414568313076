export default {
  lang1: "Retirada",
  lang2: "Detalhes da conta",
  lang3: "Registro",
  lang4: "pedidos",
  lang5: "Nível de associação",
  lang6: "Quantidade necessária",
  lang7: "Comissão por pedido",
  lang8: "Número de pedidos",
  lang9: "Participar",
  lang10: "Saldo disponível",
  lang11: "Saldo congelado",
  lang12: "Pendente",
  lang13: "Pago",
  lang14: "Liquidado",
  lang15: "Sem dados ainda",
  lang16: "Total do pedido",
  lang17: "comissão",
  lang18: "Enviar pedido",
  lang19: "Número do pedido",
  lang20: "Rendimento do produto",
  lang21: "Saldo atual",
  lang22: "Fórmula de cálculo",
  lang23: "Lucro deste pedido",
  lang24: "enviar",
  lang25: "Pegar o pedido agora",
  lang26: "Obter comissão",
  lang27: "Tarefas de hoje",
  lang28: "Concluído hoje",
  lang29: "Procedimento",
  lang30: "Clique no botão \"Iniciar tarefa\" e siga as instruções para concluir a tarefa.",
  lang31: "Após concluir a tarefa, você pode liquidar a comissão no saldo",
  lang32: "preço unitário",
  lang33: "Reembolso",
  lang34: "Pedido em andamento",
  lang35: "Cancelar fila",
  lang36: "Devido ao grande número de usuários competindo por pedidos no nível atual, o sistema está trabalhando arduamente para atribuir pedidos. Atualmente está classificado em 11º. Por favor, aguarde pacientemente.",
  lang37: "Dica: Atualizar seu nível VIP pode ajudá-lo a obter pedidos mais rapidamente",
  lang38: "Se você tiver alguma dúvida ou precisar de ajuda, entre em contato com o atendimento ao cliente online",
  lang39: "Clique aqui para entrar em contato com seu atendimento ao cliente exclusivo⭣⭣⭣",
  lang40: "FAQ",
  lang41: "Como recarregar e quais são os métodos de pagamento?",
  lang42: "Este sistema está aberto a vários países. Fornecemos métodos de pagamento internacionais e suportamos moedas locais para diferentes países.",
  lang43: "Como posso investir e ganhar renda?",
  lang44: "Você pode pegar pedidos no sistema e obter renda estável automática de colocação de pedidos e juros todos os dias. Todos os ganhos podem ser retirados para seu cartão bancário.",
  lang45: "Como se registrar como membro?",
  lang46: "Novos membros são registrados por convite. Para garantir os benefícios dos membros registrados e evitar que o registro excessivo reduza os benefícios, os novos membros só podem se registrar sob convite dos membros existentes.",
  lang47: "Existe um limite no valor da compra?",
  lang48: "Sim, para permitir que mais membros tenham a oportunidade de obter benefícios, cada conta no sistema tem um limite mínimo de valor de compra.",
  lang49: "O que devo fazer se encontrar problemas durante a operação?",
  lang50: "Você pode entrar em contato com o atendimento ao cliente online a qualquer momento para ajudar a concluir todas as operações.",
  lang51: "Contatar o atendimento ao cliente",
  lang52: "bem-vindo",
  lang53: "recarregar",
  lang54: "Registros de depósito e retirada",
  lang55: "Minha equipe",
  lang56: "Convidar amigos",
  lang57: "Cartão bancário de retirada",
  lang58: "Centro de segurança",
  lang59: "configurar",
  lang60: "Nome do banco",
  lang61: "ajuda",
  lang62: "meu",
  lang63: "Histórico de pedidos",
  lang64: "Atendimento ao Cliente",
  lang65: "Lowe's",
  lang66: 'Atividade de casal',
  lang67: 'Vincular casal',
  lang68: 'A atinge o valor de recarga',
  lang69: 'B atinge o valor de recarga',
  lang70: 'Pode ser reivindicado',
  lang71: 'Participar',
  lang72: 'Regras da atividade',
  lang73: 'Os casais precisam fornecer o ID um do outro para vinculação. Após uma vinculação bem-sucedida, ambas as partes podem reivindicar as recompensas correspondentes em suas próprias contas. Cada conta só pode ser vinculada uma vez e, uma vez vinculada, não pode ser vinculada novamente. Através da atividade de vinculação de casais, você pode desfrutar de atividades de recompensa com seu parceiro e aprofundar seu relacionamento mútuo. Desejamos a vocês uma experiência agradável na atividade!',
  lang74: 'Descrição do processo',
  lang75: 'O usuário A insere o ID do usuário B, obtém consentimento, o sistema verifica as informações de vinculação e verifica o status de vinculação de ambos os usuários, após ambas as partes concordarem, a vinculação é bem-sucedida.',
  lang76: 'Por favor, insira o ID do casal',
  lang77: 'Cancelar',
  lang78: 'Confirmar',
  lang79: 'Para sempre',
  lang80: 'Amor eterno',
  lang81: 'Só amo você',
  lang82: 'Nunca separados',
  lang83: 'Juntos para sempre',
  lang84: 'Detalhes do ativo',
  lang85: 'Regras de Pedidos',
  lang86: 'Introdução à Plataforma',
  lang87: 'Detalhes do Ativo',
  lang88: 'Detalhes do Ativo',
  lang89: 'Ainda não tem uma conta?',
  lang90: 'Número de Pedidos Capturados',
  lang91: 'Conta',
  lang92: 'Resultados de Hoje',
  lang93: 'Comissão',
  lang94: 'Regras de Operação da Plataforma',
  lang95: '1. Esta plataforma usa um sistema de IA para um serviço mais conveniente e rápido para comerciantes e clientes de pedidos;',
  lang96: '2. Esta plataforma atende mais de 100.000 comerciantes de e-commerce em todo o mundo que cooperam com a FLANNELS. Para aumentar a exposição do produto, os comerciantes exibem seus produtos para venda nesta plataforma. Após completar o pedido, os comerciantes reembolsam imediatamente o valor do pedido e a comissão. A plataforma combina aleatoriamente os clientes de pedidos com os pedidos dos comerciantes. A comissão está relacionada ao tamanho do pedido e ao nível VIP;',
  lang97: 'O número de pedidos depende do nível VIP. Quanto maior o nível VIP, mais pedidos e lucros mais altos;',
  lang98: '3. O sistema da plataforma determina o nível VIP com base no saldo da conta e atualiza/rebaixa os clientes de pedidos;',
  lang99: '4. Os clientes de pedidos só podem completar a quantidade de pedidos do nível VIP correspondente uma vez por dia. Os clientes de pedidos só podem sacar dinheiro após completar a quantidade de pedidos do nível VIP correspondente. Após completar o pedido diário, se os usuários quiserem continuar ganhando comissões, eles podem aumentar seu nível VIP ou esperar pelo reset do sistema de pedidos no dia seguinte;',
  lang100: '5. Para motivar os clientes de pedidos, a plataforma oferece pedidos da sorte que são combinados aleatoriamente;',
  lang101: 'Cada cliente tem a chance de receber três pedidos de recompensa, com a recompensa sendo 20%-35% do valor do pedido',
  lang102: '① O primeiro pedido da sorte recompensa 20% do valor do pedido.',
  lang103: '② O segundo pedido da sorte recompensa 30% do valor do pedido.',
  lang104: '③ O terceiro pedido da sorte recompensa 35% do valor do pedido.',
  lang105: '6. Política de Privacidade: A plataforma FLANNELS respeita a privacidade de cada cliente de pedidos e coloca ênfase especial na proteção da privacidade dos clientes de pedidos;',
  lang106: 'A plataforma FLANNELS se reserva o direito de interpretação final, os usuários são solicitados a cumprir as regras',
  lang107: 'FLANNELS foi fundada em 1976 no norte da Inglaterra e agora é um dos players mais empolgantes do setor. A marca agora tem mais de 50 lojas no Reino Unido e vende roupas prontas para usar, acessórios, calçados e coleções de jovens e beleza recém-lançadas. FLANNELS Beauty foi lançada em 2021 e está comprometida em revolucionar o varejo de beleza com sua abordagem "consumidor em primeiro lugar".',
  lang108: 'Dos FLANNELS Beauty Bars aos FLANNELS Beauty Changing Rooms, traz beleza de luxo para regiões com lojas de última geração e experiências de primeira classe. Apoiado pela próxima geração de líderes do varejo, o CEO entrante Michael Murray está levando a FLANNELS a um novo nível e trazendo sua oferta incomparável para cidades regionais. Como Group Head of Elevation, Murray abalou o setor - "Há uma demanda sem precedentes pelo tipo de luxo que fazemos melhor.',
  lang109: 'FLANNELS continua a revolucionar e repensar o varejo, demonstrando impressionantemente nossa visão e fornecendo destinos de compras de classe mundial para as principais cidades britânicas." FLANNELS é um varejista super inclusivo e multicategoria com marcas estabelecidas e emergentes em moda e beleza de luxo, comprometido em reviver a experiência na loja e tornar o luxo acessível a todos.'
};