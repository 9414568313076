export default {
  lang1: "登入",
  lang2: "您還可以使用",
  lang3: "郵箱登錄",
  lang4: "手機登入",
  lang5: "手機號碼",
  lang6: "請輸入手機號碼",
  lang7: "郵箱帳號",
  lang8: "請輸入郵箱帳號",
  lang9: "登入密碼",
  lang9a: "請輸入登入密碼",
  lang10: "忘記密碼",
  lang11: "注册",
  lang12: "蒐索國家/地區",
  lang13: "常用地區",
  lang14: "郵箱註冊",
  lang15: "手機註冊",
  lang16: "設定登入密碼",
  lang17: "6-20比特字元，字母和數位組成",
  lang18: "確認登入密碼",
  lang19: "請再次輸入密碼",
  lang20: "驗證碼",
  lang21: "請輸入驗證碼",
  lang22: "獲取驗證碼",
  lang23: "邀請碼",
  lang23a: "請輸入邀請碼",
  lang24: "我已閱讀並同意",
  lang25: "使用者協定",
  lang26: "隱私政策",
  lang27: "請填寫正確的郵箱地址",
  lang28: "請填寫6-20比特數位和字母組成的登入密碼",
  lang29: "兩次輸入密碼不一致",
  lang30: "Android下載",
  lang31: "iOS下載",
  lang32: "找回密碼後，24小時內無法提幣",
  lang33: "重置密碼",
  lang34: "郵箱找回",
  lang35: "手機找回",
  lang36: "點擊去登入",
  lang37: "歡迎來到FLASHOP",
  lang38: "歡迎，",
  lang39: "身份認證",
  lang40: "已認證",
  lang41: "去認證",
  lang42: "認證稽核中",
  lang43: "認證失敗，重新認證",
  lang44: "身份資訊",
  lang45: "姓名",
  lang46: "請輸入姓名",
  lang47: "證件號碼",
  lang48: "請輸入證件號碼",
  lang49: "上傳證件圖片（正反面）",
  lang50: "認證成功",
  lang51: "請上傳證件圖片",
  lang52: "安全中心",
  lang53: "登入密碼",
  lang54: "資金密碼",
  lang55: "修改",
  lang56: "設定",
  lang57: "修改密碼後，24小時內禁止提幣和法幣入金",
  lang58: "舊密碼",
  lang59: "請輸入舊密碼",
  lang60: "新密碼",
  lang61: "請輸入新密碼",
  lang62: "確認密碼",
  lang63: "請輸入確認密碼",
  lang64: "密碼必須是6-20個數位加字母組成",
  lang65: "忘記密碼？",
  lang66: "請填寫6-20比特數位和字母組成的舊密碼！",
  lang67: "請填寫6-20比特數位和字母組成的新密碼！",
  lang68: "郵箱驗證",
  lang69: "簡訊驗證",
  lang70: "請輸入資金密碼",
  lang71: "確認資金密碼",
  lang72: "請填寫6-20比特數位和字母組成的資金密碼！",
  lang73: "綁定",
  lang74: "更換手機號碼",
  lang75: "綁定手機號碼",
  lang76: "設定手機號碼",
  lang77: "請輸入您希望綁定的手機號碼，並驗證該號碼",
  lang78: "為了保護帳號安全，需要驗證您的帳號",
  lang79: "向{tel}發送驗證碼，進行安全驗證",
  lang80: "帳號安全驗證",
  lang81: "下一步",
  lang82: "更換郵箱",
  lang83: "綁定郵箱",
  lang84: "設定帳號綁定郵箱",
  lang85: "請輸入您希望綁定的郵箱，並驗證該郵箱",
  lang86: "我的團隊",
  lang87: "團隊人數",
  lang88: "直推人數",
  lang89: "團隊清單",
  lang90: "用戶ID",
  lang91: "邀請時間",
  lang92: "時間",
  lang93: "收益",
  lang94: "收益明細",
  lang95: "邀請好友",
  lang96: "推薦好友來FLASHOP",
  lang97: "最高可獲得10%交易手續費返傭",
  lang98: "快邀請好友一起來注册吧",
  lang99: "發送邀請",
  lang100: "給好友",
  lang101: "好友完成注册",
  lang102: "進行交易",
  lang103: "獲得對應",
  lang104: "比例返傭",
  lang105: "我的邀請碼",
  lang106: "我的邀請連結",
  lang107: "查看邀請海報",
  lang108: "獎勵規則",
  lang109: "1、用戶通過分享推廣碼或推廣連結完成注册，好友每產生一筆真實幣幣交易、ETF交易以及合約交易，分享人即可獲得相應比例的手續費獎勵。 對於2021年12月18日後通過分享人邀請開戶的好友，好友還可單獨享受10%的手續費獎勵。",
  lang110: "2、獎勵比例：幣幣、ETF、合約交易獎勵比例：獎勵比例與分享人當日/日均的CTT Token持倉量有關，具體規則如下：",
  lang111: "（1）CTT Token小於500，獎勵比例為10%",
  lang112: "（2）持有500-5000 CTT Token，獎勵比例為20%",
  lang113: "（3）持有5000以上CTT Token，獎勵比例為30%",
  lang114: "（4）FLASHOP全球合夥人，獲取更高獎勵請聯系平臺工作人員。",
  lang115: "3、獎勵形式：分享人實際獲得的獎勵將以好友用於結算交易手續費的實際幣種返還。 例如：好友使用ETH結算交易手續費時，分享人實際獲得的獎勵將以ETH返還。 好友的交易手續費使用CTT抵扣結算時，分享人和好友實際獲得的獎勵將以CTT返還。",
  lang116: "保存圖片",
  lang117: "保存圖片成功",
  lang118: "保存圖片失敗",
  lang119: "幫助與迴響",
  lang120: "FLASHOP產品團隊",
  lang121: "歡迎和我們交流，您的每條迴響都會被重視",
  lang122: "問題詳情",
  lang123: "提交工單",
  lang124: "工單標題",
  lang125: "請輸入工單標題",
  lang126: "工單內容",
  lang127: "請具體描述您的問題，這有助於我們更有效的幫助您！",
  lang128: "工單清單",
  lang129: "回復：",
  lang130: "正在處理，請耐心等待…",
  lang131: "暫無工單",
  lang132: "待回復",
  lang133: "已回復",
  lang134: "已關閉",
  lang135: "圖片大小不能超過5MB",
  lang136: "請輸入工單內容",
  lang137: "工單內容字數在10-200之間",
  lang138: "關於我們",
  lang139: "FLASHOP交易平臺",
  lang140: "客服電話",
  lang141: "官方推特",
  lang142: "官方電報",
  lang143: "公司郵箱",
  lang144: "設定",
  lang145: "語言設定",
  lang146: "版本號",
  lang147: "退出登录",
  lang148: "確定退出帳號？",
  lang149: "全球領先的",
  lang150: "數位資產交易平臺",
  lang151: "歡迎您下載FLASHOP",
  lang152: "註冊帳號",
  lang153: "多功能和民主的加密貨幣交易所。 作為市場上的第一家，我們為活躍用戶提供經濟利益。 FLASHOP是一個整體項目，這意味著在幾個業務層上實施有關加密貨幣解決方案的解決方案，以使我們的用戶更容易使用。",
  lang154: "我們的願景",
  lang155: "我們確信在不久的將來區塊鏈科技的廣泛應用和加密貨幣的好處。 通過引入全球解決方案，不僅由於技術創新，而且由於教育，加密世界生態系統變得對每個人都更加友好。",
  lang156: "下載APP",
  lang157: "白皮書",
  lang158: "信譽"
};