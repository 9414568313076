var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("div", { staticClass: "my_content" }, [
        _vm._m(0),
        _c("div", { staticClass: "site-header" }, [
          _c("h1", { staticClass: "site-name" }, [
            _vm._v(_vm._s(_vm.$t("addLang.lang65")))
          ])
        ]),
        _c("div", { staticClass: "user-info" }, [
          _c("div", { staticClass: "info-item" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("addLang.lang91")) + ":")
            ]),
            _c("span", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.userInfo.tel))
            ])
          ]),
          _c("div", { staticClass: "info-item" }, [
            _c("span", { staticClass: "label" }, [_vm._v("ID:")]),
            _c("span", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.userInfo.member))
            ])
          ]),
          _c("div", { staticClass: "info-item" }, [
            _c("span", { staticClass: "vip-tag" }, [
              _vm._v("VIP" + _vm._s(_vm.userInfo.minlevel || 1))
            ])
          ])
        ]),
        _c("div", { staticClass: "stats-section" }, [
          _c("div", { staticClass: "section-header" }, [
            _c("div", { staticClass: "header-item" }, [
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("addLang.lang4")))
              ]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.viplevInfo.purchaseLimit))
              ])
            ]),
            _c("div", { staticClass: "header-item" }, [
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("addLang.lang92")))
              ]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.earningsInfo.orderCount))
              ])
            ]),
            _c("div", { staticClass: "header-item" }, [
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.$t("addLang.lang93")))
              ]),
              _c("span", { staticClass: "value" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("mathFloor")(_vm.earningsInfo.rebateDayAmount, 2)
                  ) + " USD"
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "order-progress" }, [
          _c("div", { staticClass: "progress-header" }, [
            _c("span", { staticClass: "progress-text" }, [
              _vm._v(_vm._s(_vm.$t("addLang.lang90")))
            ]),
            _c("span", { staticClass: "progress-num" }, [
              _vm._v(
                _vm._s(_vm.earningsInfo.orderCount) +
                  "/" +
                  _vm._s(_vm.viplevInfo.purchaseLimit)
              )
            ])
          ]),
          _c("div", { staticClass: "progress-bar" }, [
            _c("div", {
              staticClass: "progress",
              style: {
                width:
                  (_vm.earningsInfo.orderCount / _vm.viplevInfo.purchaseLimit) *
                    100 +
                  "%"
              }
            })
          ])
        ]),
        _c("div", { staticClass: "balance-container" }, [
          _c("div", { staticClass: "balance-wrapper" }, [
            _c("p", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.$t("addLang.lang10")))
            ]),
            _c("p", { staticClass: "amount" }, [
              _vm._v(
                _vm._s(_vm._f("mathFloor")(_vm.exchangeCoinList.usable, 2)) +
                  " USD"
              )
            ])
          ]),
          _c("div", { staticClass: "balance-wrapper" }, [
            _c("div", { staticClass: "action-btns" }, [
              _c(
                "div",
                {
                  staticClass: "action-btn",
                  on: { click: _vm.showOnlineService }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("tradeLang.lang50")) + " >")
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "action-btn",
                  on: {
                    click: function($event) {
                      return _vm.pushPath("/coinWithdraw")
                    }
                  }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("tradeLang.lang51")) + " >")
                  ])
                ]
              )
            ])
          ])
        ]),
        _c("ol", { staticClass: "my_menu" }, [
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/balanceRecord")
                }
              }
            },
            [
              _c("i", [
                _c("svg", { staticClass: "svg_icon" }, [
                  _c("use", { attrs: { "xlink:href": "#chongti-record" } })
                ])
              ]),
              _c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang54")))]),
              _c("i", { staticClass: "iconfont icon-arrow-right" })
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/balanceRecord")
                }
              }
            },
            [
              _c("i", [
                _c(
                  "svg",
                  {
                    staticClass: "icon",
                    attrs: {
                      t: "1699431750739",
                      viewBox: "0 0 1024 1024",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "p-id": "16634",
                      width: "20",
                      height: "20"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M736.474207 329.038297c-122.887941 0-222.86608 100.31583-222.86608 223.620257 0 123.306474 99.977115 223.62128 222.86608 223.62128 122.869521 0 222.828217-100.314807 222.828217-223.62128C959.303448 429.354127 859.343728 329.038297 736.474207 329.038297zM736.474207 735.620769c-100.554261 0-182.346184-82.069239-182.346184-182.962215 0-100.890929 81.792946-182.963238 182.346184-182.963238 100.534818 0 182.327764 82.072309 182.327764 182.963238C918.801971 653.55153 837.009025 735.620769 736.474207 735.620769zM712.96884 815.867498l0 21.399401c0 33.630992-27.256811 60.988087-60.769099 60.988087l-486.214193 0c-33.510242 0-60.787519-27.356072-60.787519-60.988087L105.198029 186.733102c0-33.630992 27.278301-60.988087 60.787519-60.988087l486.214193 0c33.512288 0 60.769099 27.356072 60.769099 60.988087l0 102.719579c7.027051-0.557702 14.056148-1.073449 21.202926-1.073449 6.510281 0 12.904927 0.515747 19.336413 0.991585l0-102.637714c0-56.04449-45.46247-101.64613-101.309461-101.64613l-486.214193 0c-55.865411 0-101.288995 45.60164-101.288995 101.64613l0 650.533797c0 56.045513 45.423584 101.64613 101.288995 101.64613l486.214193 0c55.846992 0 101.309461-45.60164 101.309461-101.64613l0-21.321629c-6.432509 0.477884-12.826133 0.993631-19.336413 0.993631C727.024988 816.9389 719.995891 816.423154 712.96884 815.867498zM186.911157 268.05021l365.945919 0 0 41.015177-365.945919 0 0-41.015177ZM186.911157 389.846282l284.629834 0 0 41.0162-284.629834 0 0-41.0162ZM186.911157 511.643377l203.312725 0 0 41.015177-203.312725 0 0-41.015177Z",
                        "p-id": "16635"
                      }
                    })
                  ]
                )
              ]),
              _c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang84")))]),
              _c("i", { staticClass: "iconfont icon-arrow-right" })
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/coinAddress")
                }
              }
            },
            [
              _c("i", [
                _c(
                  "svg",
                  {
                    staticClass: "icon",
                    attrs: {
                      t: "1688478052245",
                      viewBox: "0 0 1024 1024",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "p-id": "21062"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M917.376 896 106.624 896C47.744 896 0 845.44 0 782.976L0 240.96C0 178.56 47.744 128 106.624 128l810.752 0C976.256 128 1024 178.56 1024 240.96l0 67.712L1024 444.16l0 338.816C1024 845.44 976.256 896 917.376 896L917.376 896zM981.376 272.896c0-55.104-42.112-99.712-93.952-99.712L136.576 173.184c-51.84 0-93.888 44.608-93.888 99.712l0 35.84 938.688 0L981.376 272.896 981.376 272.896zM42.688 444.16l0 306.944c0 55.04 42.048 99.712 93.888 99.712l750.848 0c51.84 0 93.952-44.608 93.952-99.712L981.376 444.16 42.688 444.16 42.688 444.16zM661.312 782.976l64-135.488 213.376 0-64 135.488L661.312 782.976 661.312 782.976z",
                        "p-id": "21063"
                      }
                    })
                  ]
                )
              ]),
              _c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang57")))]),
              _c("i", { staticClass: "iconfont icon-arrow-right" })
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.pushPath("/securityCenter")
                }
              }
            },
            [
              _c("i", [
                _c(
                  "svg",
                  {
                    staticClass: "icon",
                    attrs: {
                      t: "1688476685820",
                      viewBox: "0 0 1024 1024",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "p-id": "13350"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M512 85.333333c72.533333 0 139.808 8.362667 200.202667 22.325334 21.258667 4.906667 40.192 10.208 56.586666 15.52 9.301333 3.018667 16.106667 5.472 20.245334 7.114666C831.733333 144.906667 864 192.106667 864 238.624v385.034667c0 43.402667-25.130667 95.52-58.837333 121.109333l-227.626667 172.810667c-36.992 28.085333-94.005333 28.149333-131.072 0l-227.626667-172.810667C185.28 719.285333 160 666.912 160 623.658667V238.624c0-46.826667 32.170667-94.218667 75.157333-108.405333 4.16-1.642667 10.901333-4.074667 20.053334-7.04a684.437333 684.437333 0 0 1 56.586666-15.52C372.192 93.685333 439.466667 85.333333 512 85.333333z m0 64c-67.456 0-129.930667 7.765333-185.781333 20.682667-19.424 4.48-36.586667 9.28-51.274667 14.048-5.056 1.632-9.418667 3.146667-13.056 4.48-2.058667 0.746667-3.349333 1.248-3.84 1.450667a32 32 0 0 1-2.485333 0.885333c-16.074667 5.034667-31.562667 27.626667-31.562667 47.744v385.034667c0 23.498667 16.128 56.917333 33.536 70.133333l227.626667 172.810667c14.176 10.773333 39.530667 10.741333 53.674666 0l227.626667-172.810667C784 680.469333 800 647.306667 800 623.658667V238.624c0-19.84-15.626667-42.506667-32-47.882667l-2.048-0.746666a143.829333 143.829333 0 0 0-3.84-1.450667c-3.637333-1.333333-8-2.848-13.056-4.48a620.778667 620.778667 0 0 0-51.274667-14.048C641.930667 157.098667 579.456 149.333333 512 149.333333z m137.354667 190.72a32 32 0 0 1 45.290666 45.226667l-213.013333 213.333333a32 32 0 0 1-45.226667 0.064l-106.986666-106.549333a32 32 0 1 1 45.162666-45.344l84.341334 83.989333z",
                        "p-id": "13351"
                      }
                    })
                  ]
                )
              ]),
              _c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang58")))]),
              _c("i", { staticClass: "iconfont icon-arrow-right" })
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  _vm.langSheet = true
                }
              }
            },
            [
              _c("i", [
                _c("svg", { staticClass: "svg_icon" }, [
                  _c("use", { attrs: { "xlink:href": "#language" } })
                ])
              ]),
              _c("p", [_vm._v(_vm._s(_vm.$t("accountLang.lang145")))]),
              _c("i", { staticClass: "iconfont icon-arrow-right" })
            ]
          ),
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  _vm.exitPopup = true
                }
              }
            },
            [
              _c("i", [
                _c("svg", { staticClass: "svg_icon" }, [
                  _c("use", { attrs: { "xlink:href": "#logout" } })
                ])
              ]),
              _c("p", [_vm._v(_vm._s(_vm.$t("accountLang.lang147")))]),
              _c("i", { staticClass: "iconfont icon-arrow-right" })
            ]
          )
        ])
      ]),
      _c("van-action-sheet", {
        attrs: {
          actions: _vm.langList,
          "cancel-text": _vm.$t("commonLang.lang1")
        },
        on: { select: _vm.onSelectLang },
        model: {
          value: _vm.langSheet,
          callback: function($$v) {
            _vm.langSheet = $$v
          },
          expression: "langSheet"
        }
      }),
      _c(
        "van-popup",
        {
          staticClass: "model_popup",
          attrs: { position: "bottom", "close-on-popstate": "" },
          model: {
            value: _vm.exitPopup,
            callback: function($$v) {
              _vm.exitPopup = $$v
            },
            expression: "exitPopup"
          }
        },
        [
          _c("div", { staticClass: "model_popup_top" }, [
            _c("i", {
              on: {
                click: function($event) {
                  _vm.exitPopup = false
                }
              }
            })
          ]),
          _c("div", { staticClass: "exit_popup_box" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("accountLang.lang147")))]),
            _c("p", [_vm._v(_vm._s(_vm.$t("accountLang.lang148")))]),
            _c(
              "div",
              { staticClass: "model_popup_btn flex" },
              [
                _c(
                  "van-button",
                  {
                    on: {
                      click: function($event) {
                        _vm.exitPopup = false
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("commonLang.lang1")))]
                ),
                _c(
                  "van-button",
                  {
                    on: {
                      click: function($event) {
                        return _vm.exit()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("commonLang.lang3")))]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "site-logo" }, [_c("span", [_vm._v("F")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }