export default {
  "lang1": "local",
  "lang2": "contrato",
  "lang3": "nome",
  "lang4": "Preço mais recente",
  "lang5": "Aumento",
  "lang6": "contar",
  "lang7": "Preço",
  "lang8": "Partilha do tempo",
  "lang9": "1 minuto",
  "lang9a": "5 minutos",
  "lang10": "15 minutos",
  "lang10a": "30 Minutos",
  "lang11": "1 hora",
  "lang12": "4 horas",
  "lang13": "Linha diária",
  "lang13a": "Perímetro",
  "lang14": "índice",
  "lang14a": "mais",
  "lang15": "alta",
  "lang16": "baixo",
  "lang17": "24H",
  "lang18": "Mapa",
  "lang19": "deal",
  "lang20": "detalhes",
  "lang21": "tempo",
  "lang22": "direcção",
  "lang23": "compra",
  "lang24": "esgotar",
  "lang25": "Total da emissão",
  "lang26": "Sítio Web oficial",
  "lang27": "Bloquear o Navegador",
  "lang28": "Kaiduo",
  "lang29": "Abrir vazio",
  "lang30": "Moeda da Pesquisa",
  "lang31": "Vista Recente",
  "lang32": "Sem moeda relevante",
  "lang33": "Esta moeda não está aberta para negociação",
  "lang34": "Conversão total de activos da conta",
  "lang35": "dourada",
  "lang36": "Vira agora",
  "lang37": "trocar",
  "lang38": "troca",
  "lang39": "Conversão de activos de carteira",
  "lang40": "Esconder a Moeda Pequena",
  "lang41": "pesquisa",
  "lang42": "disponível",
  "lang43": "congelados",
  "lang44": "Equivalente",
  "lang45": "Número detido",
  "lang46": "Margem de posição",
  "lang47": "Resultados realizados",
  "lang48": "Depósito confiado",
  "lang49": "total dos activos",
  "lang50": "Recarregar",
  "lang51": "Retirada",
  "lang52": "outros",
  "lang53": "Estado:",
  "lang54": "Endereço:",
  "lang55": "Em análise",
  "lang56": "Concluído",
  "lang57": "Rejeitado",
  "lang58": "Mudar Moeda",
  "lang59": "Nome da Cadeia",
  "lang60": "Endereço de cobrança",
  "lang61": "Por favor, verifique cuidadosamente e preencha este rótulo de endereço.",
  "lang62": "Não recarregue quaisquer ativos que não sejam {coin} para o endereço acima, caso contrário, os ativos não serão recuperados",
  "lang63": "Quantidade mínima de recarga {low} {coin}, recarga menor que a quantidade mínima de recarga não será gravada",
  "lang64": "Endereço da carteira",
  "lang65": "Indique ou cole o endereço da carteira",
  "lang66": "Indique o valor da retirada",
  "lang67": "inteiro",
  "lang68": "Taxa de serviço",
  "lang69": "Quantidade recebida",
  "lang70": "Nota: a maior retirada {oneoutheight}, a menor retirada {low}, e a maior retirada {height} todos os dias",
  "lang71": "Indique por favor a senha do fundo",
  "lang72": "Senha do fundo",
  "lang73": "Endereço da carteira",
  "lang74": "Você não adicionou o endereço da carteira desta moeda",
  "lang75": "Para adicionar",
  "lang76": "autenticação de identidade",
  "lang77": "Complete por favor a autenticação de identidade primeiro",
  "lang78": "De certificação",
  "lang79": "Por favor, indique a senha principal da conta primeiro",
  "lang80": "Para definir",
  "lang81": "Desculpa, o teu crédito está a acabar!",
  "lang82": "Por favor, use a digitalização no APP",
  "lang83": "scan",
  "lang84": "Acidente vascular cerebral",
  "lang85": "moeda",
  "lang86": "Indique a quantidade de transferência",
  "lang87": "Apenas transferir os ativos para a conta correspondente pode ser transacionado",
  "lang88": "Moeda legal",
  "lang89": "Saldo insuficiente da conta",
  "lang90": "Taxa de câmbio:",
  "lang91": "Nota: A taxa de câmbio mínima é {low}, e a taxa de câmbio máxima é {height}.",
  "lang92": "Trocar registos",
  "lang93": "troca",
  "lang94": "consumir",
  "lang95": "Recibo",
  "lang96": "Introduza a quantidade de troca correcta",
  "lang97": "Endereço de retirada",
  "lang98": "Nenhum endereço de retirada de moeda foi adicionado~",
  "lang99": "Adicionar o endereço de retirada da moeda",
  "lang100": "Endereço de retirada",
  "lang101": "Indique por favor o endereço",
  "lang102": "observações",
  "lang103": "Indique os comentários",
  "lang104": "Tem a certeza que apaga o endereço?",
  "lang105": "Transacção cambial",
  "lang106": "Melhor preço",
  "lang107": "Quantidade",
  "lang108": "Delegação actual",
  "lang108a": "Ver mais",
  "lang109": "preço fixo",
  "lang110": "preço de mercado",
  "lang111": "revogar",
  "lang112": "Preço confiado",
  "lang113": "Tempo de entrega",
  "lang114": "Quantidade confiada",
  "lang115": "Volume não processado",
  "lang116": "delegado",
  "lang117": "estado",
  "lang118": "Unsettled",
  "lang119": "Transacção parcial",
  "lang120": "Transacção completa",
  "lang121": "Desfazer Tudo",
  "lang122": "Revogação parcial",
  "lang123": "Indique o preço unitário",
  "lang124": "Introduza o preço unitário correcto",
  "lang125": "Introduza um preço unitário superior a 0",
  "lang126": "Indique a quantidade",
  "lang127": "Introduza a quantidade correcta",
  "lang128": "O volume mínimo de compra é {min}",
  "lang129": "Exceder a quantidade da exploração",
  "lang130": "Confirmar para comprar {coin}",
  "lang131": "O volume mínimo de venda é {min}",
  "lang132": "Confirmar para vender {coin}",
  "lang133": "Tem a certeza de revogar a delegação?",
  "lang134": "Atribuição histórica",
  "lang135": "lançamento",
  "lang136": "Posição",
  "lang137": "Uk total",
  "lang138": "Kaiduo",
  "lang139": "Abrir vazio",
  "lang140": "mão",
  "lang141": "Preço stop profit",
  "lang142": "Preço stop loss",
  "lang143": "ligação",
  "lang144": "Delegação actual",
  "lang145": "Armazém múltiplo",
  "lang146": "Posição vazia",
  "lang147": "Armazém por armazém",
  "lang148": "Armazém completo",
  "lang149": "Preço actual",
  "lang150": "Preço do edifício",
  "lang151": "Posição",
  "lang152": "Não encerrado",
  "lang153": "tipo",
  "lang154": "Paridade Forte Estimativa",
  "lang155": "Lucros e perdas",
  "lang155a": "Juros de posição",
  "lang156": "Valor da posição",
  "lang157": "Preço de perda",
  "lang158": "Liquidação rápida",
  "lang159": "interesse",
  "lang160": "Planável",
  "lang161": "Margem de posição",
  "lang162": "Quantidade de encerramento",
  "lang163": "Indique a quantidade de encerramento",
  "lang164": "Contrato permanente",
  "lang165": "Total fixo",
  "lang166": "Multi posição plana",
  "lang167": "Liquidar uma posição vazia",
  "lang168": "Indique o preço unitário",
  "lang169": "Introduza o preço unitário correcto",
  "lang170": "Indique a quantidade",
  "lang171": "Indique por favor uma quantidade inteira",
  "lang172": "O volume máximo de compra é {max}",
  "lang173": "O volume máximo de venda é {max}",
  "lang174": "O preço de fechamento não pode ser inferior ao preço de abertura",
  "lang175": "O preço de stop loss não pode ser maior do que o preço de abertura",
  "lang176": "O preço de fechamento não pode ser maior do que o preço de abertura",
  "lang177": "O preço de stop loss não pode ser inferior ao preço de abertura",
  "lang178": "Se deve abrir {tradeNum} {tradeCoin}, a margem é de cerca de {bondNum} {mainCoin}",
  "lang179": "Se deve abrir {tradeNum} {tradeCoin}, a margem é de cerca de {bondNum} {mainCoin}",
  "lang180": "Confirmar o fechamento rápido?",
  "lang181": "Registo do contrato",
  "lang182": "Ordem aberta",
  "lang183": "Posição histórica",
  "lang184": "Delegação actual",
  "lang185": "Atribuição histórica",
  "lang186": "Preço de encerramento efectivo",
  "lang187": "Hora de encerramento",
  "lang188": "Confirmar um clique de posição a fechar?",
  "lang189": "Designação do contrato",
  "lang190": "Preço actual",
  "lang191": "Preço médio de abertura",
  "lang192": "Direcção da posição",
  "lang193": "Quantidade de encerramento",
  "lang194": "Preço stop profit",
  "lang195": "Rendimento estimado:",
  "lang196": "preço de paragem",
  "lang197": "Indique por favor o preço stop profit",
  "lang198": "Indique por favor o preço stop loss",
  "lang199": "Lista de cessação de lucros e perdas",
  "lang200": "modificar",
  "lang201": "Introduza a quantidade de fecho correcta",
  "lang202": "Introduza por favor o preço correcto do stop profit",
  "lang203": "O preço de fechamento deve ser maior do que o preço de abertura",
  "lang204": "O preço de fechamento deve ser menor do que o preço de abertura",
  "lang205": "Indique por favor o preço de stop loss correcto",
  "lang206": "O preço de stop loss deve ser maior do que o preço de abertura",
  "lang207": "O preço de stop loss deve ser inferior ao preço de abertura",
  "lang208": "Por favor, insira o preço stop profit ou stop loss preço",
  "lang209": "Eu sou o deus do círculo monetário",
  "lang210": "O fracasso é a mãe do sucesso",
  "lang211": "Rendimento",
  "lang212": "sustentável",
  "lang213": "Preço de encerramento",
  "lang214": "Perseguir",
  "lang215": "transcender",
  "lang216": "chumbo",
  "lang217": "Gravar Imagem",
  "lang218": "Gravar com sucesso a imagem",
  "lang219": "Não foi possível gravar a imagem",
  "lang220": "Quantidade de compra",
  "lang221": "Indique por favor a quantidade de compra",
  "lang222": "método de pagamento",
  "lang223": "Pagamento previsto:",
  "lang224": "Nota: A quantidade mínima de compra única é {low} {coin}, e a quantidade máxima de compra única é {high} {coin}",
  "lang225": "Para pagar",
  "lang226": "Registo de compra",
  "lang227": "A pagar",
  "lang228": "Concluído",
  "lang229": "Cancelado",
  "lang230": "Introduza a quantidade de compra correcta",
  "lang231": "A quantidade de compra não pode ser inferior à quantidade mínima de compra",
  "lang232": "A quantidade de compra não pode ser superior à quantidade máxima de compra",
  "lang233": "lucro",
  "lang234": "Rendimento",
  "lang235": "Compra",
  "lang236": "comprar",
  "lang237": "Ordem aberta",
  "lang238": "Preço do edifício",
  "lang239": "Preço actual",
  "lang240": "Montante total",
  "lang241": "Lucros e perdas",
  "lang242": "Ciclo de transacção",
  "lang243": "Taxa de serviço",
  "lang244": "Ordens Históricas",
  "lang245": "Preço de encerramento efectivo",
  "lang246": "Hora de encerramento",
  "lang247": "Ordem lucrativa",
  "lang248": "tempo",
  "lang249": "direcção",
  "lang250": "colher lucro",
  "lang251": "Preço de encerramento",
  "lang252": "Contrato periódico",
  "lang253": "Activos de negociação",
  "lang254": "Saldo disponível",
  "lang255": "Quantidade da transacção",
  "lang256": "Limite mínimo {min}{coin}",
  "lang257": "rendimento:",
  "lang258": "Rendimento esperado:",
  "lang259": "Indique por favor a quantidade da transacção",
  "lang260": "Introduza a quantidade correcta",
  "lang261": "Desculpa, o teu crédito está a acabar.",
  "lang262": "A quantidade deve ser superior a {min}",
  "lang263": "A quantidade deve ser inferior a {max}",
  "lang264": "websocket push exceção de dados, tente novamente mais tarde!",
  "lang265": "Exceção de conexão Websocket, tente novamente mais tarde!",
  "lang266": "Excedente",
  "lang267": "Perda",
  "lang268": "Preço do edifício de armazém:",
  "lang269": "Montante da encomenda:",
  "lang270": "contagem decrescente:",
  "lang271": "Todas as posições históricas",
  "lang272": "Gestão de encomendas",
  "lang273": "Registo da transacção",
  "lang274": "Volume de negócios",
  "lang275": "Número de moedas cobradas",
  "lang276": "Indique por favor o número de moedas",
  "lang277": "Ordem de Reembolso de Moeda",
  "lang278": "Por favor insira a ordem para cobrar a moeda",
  "lang279": "Ganhos de hoje",
  "lang280": "Total dos rendimentos",
  "lang281": "Titular do cartão"
};