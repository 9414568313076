var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "graborders" }, [
    _c(
      "div",
      { staticClass: "main_content help_center" },
      [
        _c(
          "div",
          {
            staticClass: "season-header",
            on: {
              click: function($event) {
                return _vm.pushPath("/graborders")
              }
            }
          },
          [
            _c("h1", [_vm._v("STEP OUT IN STYLE THIS SEASON:")]),
            _c("h2", [_vm._v("DISCOVER OVER 150 NEW SEASON STYLES")])
          ]
        ),
        _vm._m(0),
        _c(
          "van-swipe",
          {
            staticClass: "banner_box",
            attrs: { autoplay: 5000, duration: 1000 }
          },
          _vm._l(_vm.banner1List, function(image, index) {
            return _c("van-swipe-item", { key: index }, [
              _c("div", { staticClass: "banner_img" }, [
                _c("img", { attrs: { src: image.banner.default } })
              ])
            ])
          }),
          1
        ),
        _c("div", { staticClass: "shadowBox container" }, [
          _c(
            "div",
            { staticClass: "orderBtn" },
            [
              _c(
                "van-button",
                {
                  staticClass: "grab-button",
                  attrs: { type: "primary", size: "large" },
                  on: { click: _vm.showDialog }
                },
                [_vm._v(_vm._s(_vm.$t("addLang.lang25")))]
              )
            ],
            1
          ),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "card-body-list" }, [
              _c("div", { staticClass: "card-body-title" }, [
                _vm._v(_vm._s(_vm.$t("addLang.lang26")))
              ]),
              _c("div", { staticClass: "card-body-desc" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("mathFloor")(_vm.earningsInfo.rebateDayAmount, 2)
                  ) + "$"
                )
              ])
            ]),
            _c("div", { staticClass: "card-body-list" }, [
              _c("div", { staticClass: "card-body-title" }, [
                _vm._v(_vm._s(_vm.$t("addLang.lang10")))
              ]),
              _c("div", { staticClass: "card-body-desc" }, [
                _vm._v(
                  _vm._s(_vm._f("mathFloor")(_vm.exchangeCoinList.usable, 2)) +
                    "$"
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              {
                staticClass: "card-body-list",
                on: {
                  click: function($event) {
                    return _vm.pushPath("/ordersrecord")
                  }
                }
              },
              [
                _c("div", { staticClass: "card-body-title" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang27")))
                ]),
                _c("div", { staticClass: "card-body-desc" }, [
                  _vm._v(
                    _vm._s(_vm._f("mathFloor")(_vm.viplevInfo.purchaseLimit, 2))
                  )
                ])
              ]
            ),
            _c("div", { staticClass: "card-body-list" }, [
              _c("div", { staticClass: "card-body-title" }, [
                _vm._v(_vm._s(_vm.$t("addLang.lang28")))
              ]),
              _c("div", { staticClass: "card-body-desc" }, [
                _vm._v(_vm._s(_vm.earningsInfo.orderCount))
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "order-progress" }, [
          _c("div", { staticClass: "progress-header" }, [
            _c("span", { staticClass: "progress-text" }, [
              _vm._v(_vm._s(_vm.$t("addLang.lang90")))
            ]),
            _c("span", { staticClass: "progress-num" }, [
              _vm._v(
                _vm._s(_vm.earningsInfo.orderCount) +
                  "/" +
                  _vm._s(_vm.viplevInfo.purchaseLimit)
              )
            ])
          ]),
          _c("div", { staticClass: "progress-bar" }, [
            _c("div", {
              staticClass: "progress",
              style: {
                width:
                  (_vm.earningsInfo.orderCount / _vm.viplevInfo.purchaseLimit) *
                    100 +
                  "%"
              }
            })
          ])
        ]),
        _c("div", [
          _c("img", {
            attrs: {
              src: require("@img/home/graborders_banner_4.jpg").default,
              alt: ""
            }
          })
        ]),
        _c("div", { staticClass: "marquee-container" }, [
          _c("div", { staticClass: "marquee-content" }, [
            _c(
              "div",
              { staticClass: "marquee-items" },
              _vm._l(_vm.goodsList, function(item, index) {
                return _c("div", { key: index, staticClass: "marquee-item" }, [
                  _c("img", { attrs: { src: item.imgUrl, alt: "" } })
                ])
              }),
              0
            )
          ])
        ]),
        _c(
          "van-dialog",
          {
            attrs: {
              "confirm-button-text": _vm.$t("addLang.lang35"),
              title: _vm.$t("addLang.lang34"),
              "show-cancel": true
            },
            on: { confirm: _vm.onConfirm, cancel: _vm.onCancel },
            model: {
              value: _vm.dialogVisible,
              callback: function($$v) {
                _vm.dialogVisible = $$v
              },
              expression: "dialogVisible"
            }
          },
          [
            _c("div", { staticClass: "befor-order" }, [
              _c("div", { staticClass: "van-circle" }, [
                _c("svg", { attrs: { viewBox: "0 0 1040 1040" } }, [
                  _c("path", {
                    staticClass: "van-circle__layer",
                    staticStyle: { fill: "none", "stroke-width": "40px" },
                    attrs: {
                      d:
                        "M 520 520 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000"
                    }
                  }),
                  _c("path", {
                    staticClass: "van-circle__hover",
                    style: _vm.hoverPathStyle,
                    attrs: {
                      d:
                        "M 520 520 m 0, -500 a 500, 500 0 1, 1 0, 1000 a 500, 500 0 1, 1 0, -1000"
                    }
                  })
                ]),
                _c("div", { staticClass: "van-circle__text" }, [
                  _vm._v(_vm._s(_vm.progress) + "%")
                ])
              ]),
              _c("div", { staticClass: "befor-order-info" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("addLang.lang36")))]),
                _c("span", { staticClass: "tips" }, [
                  _vm._v(_vm._s(_vm.$t("addLang.lang37")))
                ])
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flannels-section" }, [
      _c("h2", [_vm._v("FLANNELS")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }