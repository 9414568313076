var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ordersrecord" },
    [
      _c("nav-header", { attrs: { title: _vm.$t("addLang.lang63") } }),
      _c("div", { staticClass: "main_content", staticStyle: { top: "46px" } }, [
        _c(
          "div",
          { staticClass: "order-box" },
          [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("addLang.lang10")) +
                  "：" +
                  _vm._s(_vm._f("mathFloor")(_vm.exchangeCoinList.usable, 2)) +
                  "$"
              )
            ]),
            _c("h3", [
              _vm._v(
                _vm._s(_vm.$t("addLang.lang11")) +
                  "：" +
                  _vm._s(_vm._f("mathFloor")(_vm.exchangeCoinList.disable, 2)) +
                  "$"
              )
            ]),
            _c(
              "van-tabs",
              {
                on: { click: _vm.onClick },
                model: {
                  value: _vm.activeName,
                  callback: function($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName"
                }
              },
              [
                _c(
                  "van-tab",
                  {
                    attrs: { name: "waitpay", title: _vm.$t("addLang.lang12") }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "order-item-box" },
                      [
                        _vm.goodsList.length != 0
                          ? _c("pending", {
                              attrs: {
                                goodsList: _vm.goodsList,
                                state: _vm.params.state
                              }
                            })
                          : _c("div", { staticClass: "no_data" }, [
                              _c("p", { staticClass: "icon_no_data" }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("addLang.lang15")))
                              ])
                            ])
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "van-tab",
                  { attrs: { name: "payed", title: _vm.$t("addLang.lang13") } },
                  [
                    _c(
                      "div",
                      { staticClass: "order-item-box" },
                      [
                        _vm.goodsList.length != 0
                          ? _c("paid", {
                              attrs: {
                                goodsList: _vm.goodsList,
                                state: _vm.params.state
                              }
                            })
                          : _c("div", { staticClass: "no_data" }, [
                              _c("p", { staticClass: "icon_no_data" }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("addLang.lang15")))
                              ])
                            ])
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "van-tab",
                  {
                    attrs: { name: "rebated", title: _vm.$t("addLang.lang14") }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "order-item-box" },
                      [
                        _vm.goodsList.length != 0
                          ? _c("settled", {
                              attrs: {
                                goodsList: _vm.goodsList,
                                state: _vm.params.state
                              }
                            })
                          : _c("div", { staticClass: "no_data" }, [
                              _c("p", { staticClass: "icon_no_data" }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("addLang.lang15")))
                              ])
                            ])
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }