import { minerInfo } from '@api/miner';
var state = {
  minerCount: {}
};
var mutations = {
  MINER_COUNT: function MINER_COUNT(state, val) {
    state.minerCount = val;
  }
};
var actions = {
  getMinerInfo: function getMinerInfo(_ref) {
    var commit = _ref.commit;
    minerInfo().then(function (res) {
      commit('MINER_COUNT', res.data);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};