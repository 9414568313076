export default {
  lang1: 'ホーム',
  lang2: 'IEO新通貨サブスクリプション',
  lang3: 'コレクショントークンは毎日リリースされます',
  lang4: 'Covert',
  lang5: '開いていません',
  lang6: '終了',
  lang7: '価格',
  lang8: '開始時間',
  lang9: '発行総数',
  lang10: '終了時間',
  lang11: '余剰額',
  lang12: 'ロック期間',
  lang13: '日',
  lang14: '{コイン}を購入',
  lang15: '支払いコイン',
  lang15a: '支払い',
  lang16: 'バランス',
  lang17: 'すべて使用',
  lang18: '購入数量を入力してください',
  lang19: '支払い: ',
  lang20: 'ギフト: ',
  lang21: '注文記録',
  lang22: 'リリース',
  lang23: 'IEOの誓約',
  lang24: 'リベート',
  lang25: '正しい購入数量を入力してください',
  lang26: '残りの合計が不十分です',
  lang27: '{number} {coin}を交換してもよろしいですか？',
  lang28: '通知',
  lang29: 'まだ通知はありません',
  lang30: '通知の詳細',
  lang31: '検索する通貨を入力してください',
  lang32: '毎日のマイニング',
  lang33: '力が強いほど,より多くの採掘が行われます',
  lang34: 'ディールリスト',
  lang35: 'ライジングリスト',
  lang36: '拒否リスト',
  lang37: 'スポット',
  lang38: '契約',
  lang39: '定期契約',
  lang40: 'お気に入り',
  lang41: 'すべて',
  lang42: 'メイン',
  lang43: '新しい通貨',
  lang44: 'ボリューム',
  lang45: '最新価格',
  lang46: '24H増加',
  lang47: 'マネージドマイニング',
  lang48: '管理された資金',
  lang49: '今日の推定収入',
  lang50: '累積収入',
  lang51: '管理された注文',
  lang52: '毎日の利回り',
  lang53: '日',
  lang54: '注文の確認',
  lang55: '最近の日数（1日の収益率）',
  lang56: '管理期間',
  lang57: 'シングルリミット',
  lang58: '配当時間',
  lang59: '毎日',
  lang60: 'エスクロー金額',
  lang61: '期限切れになったときに戻る',
  lang62: '早期償還',
  lang63: 'エスクロー金額を入力してください',
  lang64: '推定収益',
  lang65: '正しいエスクロー金額を入力してください',
  lang66: 'アカウントの残高が不足しています',
  lang67: '金額が単一の制限内にありません',
  lang68: 'レコードのロック',
  lang69: '実行中',
  lang70: '完了',
  lang71: '償還',
  lang72: '累積収入',
  lang73: '管理時間',
  lang74: '有効期限',
  lang75: '保管の早期償還を決定します。対応する手数料は早期償還のために差し引かれます'
};