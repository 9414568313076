import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import pending from './components/pending.vue';
import paid from './components/paid.vue';
import settled from './components/settled.vue';
import { getWaitpayList } from '@api/ordersrecord';
import { mapState } from 'vuex';
export default {
  components: {
    pending: pending,
    paid: paid,
    settled: settled
  },
  data: function data() {
    return {
      activeName: 'waitpay',
      goodsList: [],
      params: {
        page: 1,
        limit: 20,
        state: 'waitpay'
      }
    };
  },
  computed: _objectSpread({}, mapState({
    exchangeAmount: function exchangeAmount(state) {
      return state.wallet.exchangeAmount;
    },
    exchangeCoinList: function exchangeCoinList(state) {
      return state.wallet.exchangeCoinList[0] || {};
    }
  })),
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 1);
    this.$store.dispatch('wallet/getExchangeAssets');
    this.getWaitpay();
  },
  mounted: function mounted() {},
  methods: {
    getWaitpay: function getWaitpay() {
      var _this = this;

      getWaitpayList(this.params).then(function (res) {
        _this.goodsList = res.data || [];
      });
    },
    onClick: function onClick(name, title) {
      this.params.state = name;
      this.getWaitpay();
    }
  }
};