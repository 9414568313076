export default {
  lang1: 'Retirada',
  lang2: 'Detalles de la cuenta',
  lang3: 'Registro',
  lang4: 'pedidos',
  lang5: 'Nivel de membresía',
  lang6: 'Cantidad requerida',
  lang7: 'Comisión por pedido',
  lang8: 'Número de pedidos',
  lang9: 'Unirse',
  lang10: 'Saldo disponible',
  lang11: 'Congelar saldo',
  lang12: 'Pendiente',
  lang13: 'Pagado',
  lang14: 'Liquidado',
  lang15: 'No hay datos todavía',
  lang16: 'Total del pedido',
  lang17: 'comisión',
  lang18: 'Enviar pedido',
  lang19: 'Número de pedido',
  lang20: 'Rendimiento del producto',
  lang21: 'Saldo actual',
  lang22: 'Fórmula de cálculo',
  lang23: 'Beneficio de este pedido',
  lang24: 'enviar',
  lang25: 'Tomar el pedido ahora',
  lang26: 'Obtener comisión',
  lang27: 'Hoy son tareas',
  lang28: 'Completado hoy',
  lang29: 'Procedimiento',
  lang30: 'Haga clic en el botón "Iniciar tarea" y siga las indicaciones para completar la tarea.',
  lang31: 'Después de completar la tarea, puede liquidar la comisión al saldo',
  lang32: 'precio unitario',
  lang33: 'Reembolso',
  lang34: 'Pedido en curso',
  lang35: 'Cancelar cola',
  lang36: 'Debido a la gran cantidad de usuarios que compiten por pedidos en el nivel actual, el sistema está trabajando arduamente para asignar pedidos. Actualmente ocupa el puesto 11. Por favor espere pacientemente.',
  lang37: 'Consejo: Actualizar su nivel VIP puede ayudarlo a obtener pedidos más rápido',
  lang38: 'Si tiene alguna pregunta o necesita ayuda, comuníquese con el servicio al cliente en línea',
  lang39: 'Haga clic aquí para contactar a su servicio al cliente exclusivo⭣⭣⭣',
  lang40: 'Preguntas frecuentes',
  lang41: '¿Cómo recargar y qué métodos de pago hay?',
  lang42: 'Este sistema está abierto a varios países. Proporcionamos métodos de pago internacionales y admitimos monedas locales para diferentes países.',
  lang43: '¿Cómo puedo invertir y ganar ingresos?',
  lang44: 'Puede tomar pedidos en el sistema y obtener ingresos estables automáticos por colocación de pedidos e intereses todos los días. Todas las ganancias se pueden retirar a su tarjeta bancaria.',
  lang45: '¿Cómo registrarse como miembro?',
  lang46: 'Los nuevos miembros se registran por invitación. Para garantizar los beneficios de los miembros registrados y evitar que el registro excesivo reduzca los beneficios, los nuevos miembros solo pueden registrarse bajo la invitación de los miembros existentes.',
  lang47: '¿Hay un límite en el monto de compra?',
  lang48: 'Sí, para permitir que más miembros tengan la oportunidad de obtener beneficios, cada cuenta en el sistema tiene un límite mínimo de monto de compra.',
  lang49: '¿Qué debo hacer si encuentro problemas durante la operación?',
  lang50: 'Puede contactar al servicio al cliente en línea en cualquier momento para ayudar a completar todas las operaciones.',
  lang51: 'Contactar con el servicio de atención al cliente',
  lang52: 'bienvenido',
  lang53: 'recargar',
  lang54: 'Registros de depósitos y retiros',
  lang55: 'Mi equipo',
  lang56: 'Invitar amigos',
  lang57: 'Tarjeta bancaria de retiro',
  lang58: 'Centro de seguridad',
  lang59: 'configurar',
  lang60: 'Nombre del banco',
  lang61: 'ayuda',
  lang62: 'mi',
  lang63: 'Historial de pedidos',
  lang64: 'Servicio al Cliente',
  lang65: "Lowe's",
  lang66: 'Actividad de pareja',
  lang67: 'Vincular pareja',
  lang68: 'A alcanza el monto de recarga',
  lang69: 'B alcanza el monto de recarga',
  lang70: 'Se puede reclamar',
  lang71: 'Participar',
  lang72: 'Reglas de la actividad',
  lang73: 'Las parejas necesitan proporcionar el ID del otro para vincularse. Después de una vinculación exitosa, ambas partes pueden reclamar las recompensas correspondientes en sus propias cuentas. Cada cuenta solo puede vincularse una vez y una vez vinculada no se puede volver a vincular. A través de la actividad de vinculación de parejas, puede disfrutar de actividades de recompensa con su pareja y profundizar su relación entre sí. ¡Les deseamos una experiencia agradable en la actividad!',
  lang74: 'Descripción del proceso',
  lang75: 'El usuario A ingresa el ID del usuario B, obtiene el consentimiento, el sistema verifica la información de vinculación y verifica el estado de vinculación de ambos usuarios, después de que ambas partes estén de acuerdo, la vinculación es exitosa.',
  lang76: 'Por favor ingrese el ID de la pareja',
  lang77: 'Cancelar',
  lang78: 'Confirmar',
  lang79: 'Para toda la vida',
  lang80: 'Por siempre jamás',
  lang81: 'Solo te amo a ti',
  lang82: 'Nunca separados',
  lang83: 'Para siempre juntos',
  lang84: 'Detalles de activos',
  lang85: 'Reglas de pedidos',
  lang86: 'Introducción a la plataforma',
  lang87: 'Detalles de activos',
  lang88: 'Detalles de activos',
  lang89: '¿Aún no tienes una cuenta?',
  lang90: 'Número de pedidos tomados',
  lang91: 'Cuenta',
  lang92: 'Resultados de hoy',
  lang93: 'Comisión',
  lang94: 'Reglas de operación de la plataforma',
  lang95: '1. Esta plataforma utiliza un sistema de IA para un servicio más conveniente y rápido para comerciantes y clientes de pedidos;',
  lang96: '2. Esta plataforma atiende a más de 100,000 comerciantes de comercio electrónico en todo el mundo que cooperan con FLANNELS. Para aumentar la exposición del producto, los comerciantes muestran sus productos para la venta en esta plataforma. Después de completar el pedido, los comerciantes reembolsan inmediatamente el monto del pedido y la comisión. La plataforma empareja aleatoriamente a los clientes de pedidos con pedidos de comerciantes. La comisión está relacionada con el tamaño del pedido y el nivel VIP;',
  lang97: 'El número de pedidos depende del nivel VIP. Cuanto más alto sea el nivel VIP, más pedidos y mayores ganancias;',
  lang98: '3. El sistema de la plataforma determina el nivel VIP según el saldo de la cuenta y actualiza/degrada a los clientes de pedidos;',
  lang99: '4. Los clientes de pedidos solo pueden completar la cantidad de pedidos del nivel VIP correspondiente una vez por día. Los clientes de pedidos pueden retirar dinero solo después de completar la cantidad de pedidos del nivel VIP correspondiente. Después de completar el pedido diario, si los usuarios desean continuar ganando comisiones, pueden aumentar su nivel VIP o esperar al reinicio del sistema de pedidos al día siguiente;',
  lang100: '5. Para motivar a los clientes de pedidos, la plataforma ofrece pedidos afortunados que se emparejan aleatoriamente;',
  lang101: 'Cada cliente tiene la oportunidad de recibir tres pedidos de recompensa, siendo la recompensa del 20%-35% del monto del pedido',
  lang102: '① El primer pedido afortunado recompensa el 20% del monto del pedido.',
  lang103: '② El segundo pedido afortunado recompensa el 30% del monto del pedido.',
  lang104: '③ El tercer pedido afortunado recompensa el 35% del monto del pedido.',
  lang105: '6. Política de privacidad: La plataforma FLANNELS respeta la privacidad de cada cliente de pedidos y hace especial énfasis en proteger la privacidad de los clientes de pedidos;',
  lang106: 'La plataforma FLANNELS se reserva el derecho de interpretación final, se solicita a los usuarios que cumplan con las reglas',
  lang107: 'FLANNELS se fundó en 1976 en el norte de Inglaterra y ahora es uno de los actores más emocionantes de la industria. La marca ahora tiene más de 50 tiendas en el Reino Unido y ofrece ropa lista para usar, accesorios, calzado y colecciones de juventud y belleza recientemente lanzadas. FLANNELS Beauty se lanzó en 2021 y está comprometida a revolucionar el comercio minorista de belleza con su enfoque "el consumidor primero".',
  lang108: 'Desde FLANNELS Beauty Bars hasta FLANNELS Beauty Changing Rooms, lleva la belleza de lujo a regiones con tiendas de última generación y experiencias de primera clase. Respaldado por la próxima generación de líderes minoristas, el CEO entrante Michael Murray está llevando a FLANNELS a un nuevo nivel y llevando su oferta sin igual a ciudades regionales. Como Director de Elevación del Grupo, Murray ha sacudido la industria - "Hay una demanda sin precedentes por el tipo de lujo que mejor hacemos.',
  lang109: 'FLANNELS continúa interrumpiendo y repensando el comercio minorista, demostrando impresionantemente nuestra visión y proporcionando destinos de compras de clase mundial para las principales ciudades de Gran Bretaña". FLANNELS es un minorista súper inclusivo y de múltiples categorías con marcas establecidas y emergentes en moda y belleza de lujo, comprometido con revivir la experiencia en la tienda y hacer que el lujo sea accesible para todos.'
};