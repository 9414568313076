export default {
  lang1: "batal",
  lang2: "kirim",
  lang3: "konfirmasi",
  lang4: "Belum ada data",
  lang5: "Belum ada catatan",
  lang6: "salin",
  lang7: "Salin berhasil",
  lang8: "Gagal dikopi",
  lang9: "konfirmasi",
  lang10: "Spot",
  lang11: "aset",
  lang12: "Permintaan gagal! Tolong periksa koneksi jaringan",
  lang13: "daur",
  lang14: "perdagangan",
  lang15: "pasanga",
  lang16: "Hubungi layanan pelanggan!",
  "theme": "Kulit tematik"
};