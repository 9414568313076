export default {
  lang1: '동전',
  lang2: '계약',
  lang3: '이름',
  lang4: '최신 가격',
  lang5: '증가',
  lang6: '수량',
  lang7: '가격',
  lang8: '시분할',
  lang9: '1분',
  lang9a: '5분',
  lang10: '15분',
  lang10a: '30분',
  lang11: '1시간',
  lang12: '4시간',
  lang13: '선라인',
  lang13a: '둘레',
  lang14: '표시기',
  lang14a: '더',
  lang15: '높음',
  lang16: '낮음',
  lang17: '24시간 금액',
  lang18: '스탠드 맵',
  lang19: '거래',
  lang20: '소개',
  lang21: '시간',
  lang22: '방향',
  lang23: '구매',
  lang24: '판매',
  lang25: '총 발행량',
  lang26: '공식 웹사이트',
  lang27: '블록 탐색기',
  lang28: '더 열기',
  lang29: '비어 있음',
  lang30: '통화 검색',
  lang31: '최근에 본 항목',
  lang32: '관련 통화가 없습니다',
  lang33: '이 통화는 아직 거래할 수 없습니다.',
  lang34: '총 계정 자산 전환',
  lang35: '요금 청구',
  lang36: '철회',
  lang37: '전송',
  lang38: '교환',
  lang39: '지갑 자산 변환',
  lang40: '소액 통화 숨기기',
  lang41: '검색',
  lang42: '사용 가능',
  lang43: '동결',
  lang44: '변환',
  lang45: '수량 보류',
  lang46: '위치 여백',
  lang47: '실현된 손익',
  lang48: '위탁된 마진',
  lang49: '총 자산',
  lang50: '충전',
  lang51: '철회',
  lang52: '기타',
  lang53: '상태:',
  lang54: '주소:',
  lang55: '대기 중',
  lang56: '완료',
  lang57: '거부됨',
  lang58: '통화 전환',
  lang59: '체인 이름',
  lang60: '입금 주소',
  lang61: '주소 라벨을 주의 깊게 확인하고 기입하십시오. 라벨이 생략되면 자금이 손실됩니다.',
  lang62: '비{coin} 자산을 위 주소로 입금하지 마십시오. 그렇지 않으면 자산이 복구되지 않습니다.',
  lang63: '최소 입금액 {low}{coin}, 최소 입금액 미만의 입금액은 적립되지 않습니다.',
  lang64: '지갑 주소',
  lang65: '지갑 주소를 입력하거나 붙여넣으세요',
  lang66: '출금 금액을 입력하세요',
  lang67: '모두',
  lang68: '취급 수수료',
  lang69: '계정 금액',
  lang70: '참고: 최대 인출은 {oneoutheight} 1회, 최소 인출은 {low}, 최대 인출은 하루 {height}',
  lang71: '펀드 비밀번호를 입력하세요',
  lang72: '펀딩 비밀번호',
  lang73: '지갑 주소',
  lang74: '이 통화의 지갑 주소를 추가하지 않았습니다.',
  lang75: '추가로 이동',
  lang76: '인증',
  lang77: '먼저 본인인증을 완료해주세요',
  lang78: '인증으로 이동',
  lang79: '먼저 계좌 펀드 비밀번호를 설정하세요',
  lang80: '설정으로 이동',
  lang81: '균형이 부족합니다!',
  lang82: '앱에서 스캔을 사용하세요',
  lang83: '스캔 및 스캔',
  lang84: '전송',
  lang85: '통화',
  lang86: '이체 금액을 입력하세요',
  lang87: '자산을 해당 계좌로 이체하여 자산만 거래할 수 있습니다.',
  lang88: '프랑스 통화',
  lang89: '계정 잔액 부족',
  lang90: '환율:',
  lang91: '참고: 최소 환율은 {low}이고 최대 환율은 {height}입니다.',
  lang92: '교환 기록',
  lang93: '교환',
  lang94: '소비',
  lang95: '계정으로',
  lang96: '정확한 환전 금액을 입력하세요',
  lang97: '출금 주소',
  lang98: '출금 주소가 추가되지 않았습니다~',
  lang99: '출금 주소 추가',
  lang100: '출금 주소',
  lang101: '주소를 입력하세요',
  lang102: '비고',
  lang103: '메모를 입력하세요',
  lang104: '주소를 삭제하시겠습니까?',
  lang105: '코인 거래',
  lang106: '현재 가장 좋은 가격으로 거래',
  lang107: '거래 금액',
  lang108: '현재 위임됨',
  lang108a: '더 보기',
  lang109: '제한 가격',
  lang110: '시장 가격',
  lang111: '취소',
  lang112: '주문 가격',
  lang113: '위임 시간',
  lang114: '위임 금액',
  lang115: '거래되지 않은 거래량',
  lang116: '총 위탁',
  lang117: '상태',
  lang118: '거래되지 않음',
  lang119: '부분 거래',
  lang120: '모든 거래',
  lang121: '모두 취소',
  lang122: '부분 실행 취소',
  lang123: '단가를 입력하세요',
  lang124: '정확한 단가를 입력하세요.',
  lang125: '0보다 큰 단가를 입력하십시오',
  lang126: '수량을 입력하세요',
  lang127: '정확한 수량을 입력하세요',
  lang128: '최소 구매 금액은 {min}입니다.',
  lang129: '보유 수량 초과',
  lang130: '{coin} 구매 확인',
  lang131: '최소 판매 금액은 {min}입니다.',
  lang132: '{coin} 판매 확인',
  lang133: '위임 취소를 확인하시겠습니까?',
  lang134: '역사 위원회',
  lang135: '열린 위치',
  lang136: '위치',
  lang137: '원클릭 닫기 위치',
  lang138: '더 열기',
  lang139: '비어 있음',
  lang140: '손',
  lang141: '이익을 취하십시오',
  lang142: '손절매 가격',
  lang143: '여백',
  lang144: '현재 위임됨',
  lang145: '여러 위치',
  lang146: '빈 위치',
  lang147: '격리된',
  lang148: '전체 창고',
  lang149: '현재 가격',
  lang150: '오픈 포지션 가격',
  lang151: '위치',
  lang152: '거래되지 않은 거래량',
  lang153: '유형',
  lang154: '예상 강제 패리티',
  lang155: '이익과 손실',
  lang155a: '창고 관심',
  lang156: '위치 값',
  lang157: '이익 및 손절매',
  lang158: '매우 빠른 닫기 위치',
  lang159: '닫기 위치',
  lang160: '유지',
  lang161: '위치 여백',
  lang162: '닫힌 위치의 수',
  lang163: '마감 금액을 입력하세요',
  lang164: '영구 계약',
  lang165: '전체 평면',
  lang166: '롱 포지션 닫기',
  lang167: '매도 포지션 닫기',
  lang168: '단가를 입력하세요',
  lang169: '정확한 단가를 입력하세요.',
  lang170: '수량을 입력하세요',
  lang171: '정수를 입력하세요',
  lang172: '최대 구매 금액은 {max}입니다.',
  lang173: '최대 판매 금액은 {max}입니다.',
  lang174: '이익실현 가격은 오픈 롱 포지션 가격보다 낮을 수 없습니다',
  lang175: '손절매 가격은 오픈 롱 포지션 가격보다 높을 수 없습니다',
  lang176: '이익실현 가격은 오픈 숏 포지션 가격보다 높을 수 없습니다',
  lang177: '손절매 가격은 매도 시가보다 낮을 수 없습니다',
  lang178: '장기 {tradeNum} {tradeCoin}을(를) 열 것인지 여부, 마진은 약 {bondNum}{mainCoin}',
  lang179: '짧은 {tradeNum} {tradeCoin} 개설 여부, 마진은 약 {bondNum}{mainCoin}',
  lang180: '빠른 닫기를 확인하시겠습니까?',
  lang181: '계약 기록',
  lang182: '위치 순서',
  lang183: '역사적 위치',
  lang184: '현재 위임됨',
  lang185: '역사 위원회',
  lang186: '실제 종가',
  lang187: '닫는 시간',
  lang188: '원클릭 닫기를 확인하시겠습니까?',
  lang189: '계약 이름',
  lang190: '현재 가격',
  lang191: '평균 시가',
  lang192: '위치 방향',
  lang193: '닫힌 위치의 수',
  lang194: '이익을 취하십시오',
  lang195: '예상 수익:',
  lang196: '손절매 가격',
  lang197: '이익실현 가격을 입력하세요',
  lang198: '손절매 가격을 입력하세요',
  lang199: '이익 및 손절매 목록',
  lang200: '수정',
  lang201: '포지션을 닫으려면 정확한 금액을 입력하세요.',
  lang202: '올바른 이익실현 가격을 입력하세요.',
  lang203: '이익실현 가격은 시가보다 높아야 합니다.',
  lang204: '이익실현 가격은 시가보다 낮아야 합니다.',
  lang205: '정확한 손절매 가격을 입력하세요',
  lang206: '손절매 가격은 시가보다 높아야 합니다',
  lang207: '스탑 가격은 시가보다 낮아야 합니다',
  lang208: '이익실현가 또는 손절가를 입력하세요',
  lang209: '나는 통화권의 신이다',
  lang210: '실패는 성공의 어머니',
  lang211: '수율',
  lang212: '지속 가능한',
  lang213: '종가',
  lang214: '따라잡다',
  lang215: '넘어',
  lang216: '리딩',
  lang217: '사진 저장',
  lang218: '사진 저장 성공',
  lang219: '이미지를 저장하지 못했습니다',
  lang220: '구매 수량',
  lang221: '구매 수량을 입력하세요',
  lang222: '결제 방법',
  lang223: '예상 지불액:',
  lang224: '참고: 최소 구매 금액은 {low}{coin}이고 최대 구매 금액은 {high}{coin}입니다.',
  lang225: '결제하러 가세요',
  lang226: '구매 기록',
  lang227: '지불',
  lang228: '완료',
  lang229: '취소됨',
  lang230: '정확한 구매 수량을 입력하세요.',
  lang231: '구매 수량은 최소 구매 수량보다 작을 수 없습니다.',
  lang232: '구매 수량은 최대 구매 수량보다 높을 수 없습니다.',
  lang233: '수익',
  lang234: '수율',
  lang235: '구매',
  lang236: '구매',
  lang237: '위치 순서',
  lang238: '오픈 포지션 가격',
  lang239: '현재 가격',
  lang240: '총 거래 금액',
  lang241: '이익과 손실',
  lang242: '트랜잭션 주기',
  lang243: '취급 수수료',
  lang244: '역사적 질서',
  lang245: '실제 종가',
  lang246: '닫는 시간',
  lang247: '이익 주문',
  lang248: '시간',
  lang249: '방향',
  lang250: '이익',
  lang251: '종가',
  lang252: '정기 계약',
  lang253: '자산 거래',
  lang254: '사용 가능한 잔액',
  lang255: '트랜잭션 수',
  lang256: '최소 {min}{coin}',
  lang257: '예상 반환:',
  lang258: '예상 반환:',
  lang259: '거래 수량을 입력하십시오',
  lang260: '정확한 수량을 입력하세요',
  lang261: '잔액 부족',
  lang262: '숫자는 {min}보다 커야 합니다.',
  lang263: '숫자는 {max}보다 작아야 합니다.',
  lang264: '웹 소켓 푸시 데이터가 비정상입니다. 나중에 다시 시도하십시오!',
  lang265: '웹 소켓 연결이 비정상입니다. 나중에 다시 시도하십시오!',
  lang266: '이익',
  lang267: '손실',
  lang268: '오픈 포지션 가격:',
  lang269: '주문 금액:',
  lang270: '카운트다운:',
  lang271: '모든 역사적 위치',
  lang272: '주문 관리',
  lang273: '거래 기록',
  lang274: '볼륨',
  lang275: '입금 금액',
  lang276: '입금액을 입력해주세요',
  lang277: '입금 주문',
  lang278: '입금 주문을 입력하세요',
  lang279: '오늘의 수입',
  lang280: '총 수익',
  lang281: '카드 소지자'
};