export default {
  lang1: "通貨取引",
  lang2: "契約する",
  lang3: "名前",
  lang4: "最終価格",
  lang5: "変化する％",
  lang6: "数量",
  lang7: "価格",
  lang8: "時間",
  lang9: "1分",
  lang9a: "5分",
  lang10: "15分",
  lang10a: "30分",
  lang11: "1h",
  lang12: "4h",
  lang13: "1D",
  lang13a: "1W",
  lang14: "索引",
  lang14a: "もっと",
  lang15: "高",
  lang16: "低",
  lang17: "24H",
  lang18: "マップ",
  lang19: "取引",
  lang20: "はじめに",
  lang21: "時間",
  lang22: "方向",
  lang23: "買う",
  lang24: "売る",
  lang25: "発行総数",
  lang26: "公式ウェブサイト",
  lang27: "ブロックエクスプローラー",
  lang28: "買う",
  lang29: "売る",
  lang30: "コインを検索",
  lang31: "最近表示された",
  lang32: "関連する通貨はありません",
  lang33: "この通貨はまだ取引可能ではありません",
  lang34: "総資産変換",
  lang35: "贮金",
  lang36: "現金",
  lang37: "転送",
  lang38: "両替",
  lang39: "資産価値評価",
  lang40: "小さな通貨を隠す",
  lang41: "検索",
  lang42: "利用可能",
  lang43: "ロックアップ",
  lang44: "価値評価",
  lang45: "保留金額",
  lang46: "ポジションマージン",
  lang47: "利益と損失",
  lang48: "委託証拠金",
  lang49: "総資産",
  lang50: "入金",
  lang51: "出金",
  lang52: "その他",
  lang53: "ステータス:",
  lang54: "アドレス:",
  lang55: "待っています",
  lang56: "完了",
  lang57: "拒否されました",
  lang58: "コインの切り替え",
  lang59: "チェーン名",
  lang60: "入金のアドレス",
  lang61: "住所ラベルを注意深く確認して記入してください。ラベルが省略されていると,資金が失われます",
  lang62: "上記のアドレスにトークンをチャージしないでください。{coin}資産でないと,資産を取り戻すことはできないです。",
  lang63: "最小チャージ金額:{low} {coin} 最小金額以下のチャージは入金されず,返金できません。",
  lang64: "ウォレットアドレス",
  lang65: "ウォレットアドレスを入力または貼り付けてください",
  lang66: "引き出し金額を入力してください",
  lang67: "すべて",
  lang68: "手数料",
  lang69: "アカウントへの金額",
  lang70: "注:最大引き出しは1回{oneoutheight},最小引き出しは{low},最大引き出しは1日あたり{height}です",
  lang71: "ファンドのパスワードを入力してください",
  lang72: "資金調達パスワード",
  lang73: "ウォレットアドレス",
  lang74: "この通貨のウォレットアドレスを追加していません",
  lang75: "追加する",
  lang76: "認証",
  lang77: "最初にID認証を完了してください",
  lang78: "認証",
  lang79: "最初に口座資金のパスワードを設定してください",
  lang80: "設定",
  lang81: "バランスが不十分です！",
  lang82: "APPでスキャンを使用してください",
  lang83: "スキャン",
  lang84: "に",
  lang85: "通貨",
  lang86: "送金金額を入力してください",
  lang87: "資産を対応するアカウントに転送することで取引できるのは資産のみです",
  lang88: "フランスの通貨",
  lang89: "アカウントの残高が不足しています",
  lang90: "レート:",
  lang91: "注:最小為替レートは{low}で,最大為替レートは{height}です。",
  lang92: "交換記録",
  lang93: "に",
  lang94: "消費",
  lang95: "アカウントへ",
  lang96: "正しい交換金額を入力してください",
  lang97: "引き出し先住所",
  lang98: "引き出しアドレスは追加されていません〜",
  lang99: "引き出し先住所を追加",
  lang100: "引き出し先住所",
  lang101: "住所を入力してください",
  lang102: "備考",
  lang103: "メモを入力してください",
  lang104: "アドレスを削除してもよろしいですか？",
  lang105: "コイン交換",
  lang106: "最高の価格で取引する",
  lang107: "合計",
  lang108: "現在の注文",
  lang108a: "もっと",
  lang109: "指值",
  lang110: "成行",
  lang111: "閉じる",
  lang112: "注文価格",
  lang113: "時間",
  lang114: "金額",
  lang115: "未取引",
  lang116: "合計",
  lang117: "ステータス",
  lang118: "取引されていません",
  lang119: "部分的な取引",
  lang120: "すべてのトランザクション",
  lang121: "すべてキャンセル",
  lang122: "部分的な取り消し",
  lang123: "価格を入力してください",
  lang124: "正しい価格を入力してください",
  lang125: "0より大きい価格を入力してください。",
  lang126: "数量を入力してください",
  lang127: "正しい数量を入力してください",
  lang128: "最小購入金額は{min}",
  lang129: "保持数量を超えました",
  lang130: "{コイン}を購入することを確認してください",
  lang131: "最小販売額は{min}です",
  lang132: "{コイン}の販売を確認",
  lang133: "注文を閉じることを確認しますか？",
  lang134: "履歴順序",
  lang135: "開く",
  lang136: "位置",
  lang137: "すべて閉じる",
  lang138: "購入/ロング",
  lang139: "購入/ショート",
  lang140: "手数",
  lang141: "利益価格を止める",
  lang142: "ストップロス価格",
  lang143: "マージン",
  lang144: "現在の注文",
  lang145: "買う",
  lang146: "売る",
  lang147: "分離",
  lang148: "クロス",
  lang149: "価格",
  lang150: "始値",
  lang151: "手数",
  lang152: "未取引",
  lang153: "タイプ",
  lang154: "推定強制パリティ",
  lang155: "利益と損失",
  lang155a: "興味",
  lang156: "位置の値",
  lang157: "価格を設定する",
  lang158: "ファストクローズ",
  lang159: "閉じる",
  lang160: "利用可能",
  lang161: "ポジションマージン",
  lang162: "クローズポジションの数",
  lang163: "決済手数を入力する",
  lang164: "スワップ",
  lang165: "すべて閉じる",
  lang166: "買う閉じる",
  lang167: "閉じる売る",
  lang168: "価格を入力してください",
  lang169: "正しい価格を入力してください",
  lang170: "数量を入力してください",
  lang171: "整数を入力してください",
  lang172: "最大購入額は{max}です",
  lang173: "最大販売額は{max}",
  lang174: "テイクプロフィット価格はオープンロングポジション価格より低くすることはできません",
  lang175: "ストップロス価格はオープンロングポジション価格より高くすることはできません",
  lang176: "テイクプロフィット価格はオープンショートポジション価格より高くすることはできません",
  lang177: "ストップロス価格はショートオープニング価格より低くすることはできません",
  lang178: "買う{tradeNum} {tradeCoin}を開くかどうかにかかわらず,マージンは約{bondNum} {mainCoin}",
  lang179: "売る{tradeNum} {tradeCoin}を開くかどうかにかかわらず,マージンは約{bondNum} {mainCoin}です",
  lang180: "高速クローズを確認しますか？",
  lang181: "レコード",
  lang182: "位置",
  lang183: "履歴位置",
  lang184: "現在の注文",
  lang185: "履歴順序",
  lang186: "終値",
  lang187: "注文終了時間",
  lang188: "ワンクリックで閉じることを確認しますか？",
  lang189: "名前",
  lang190: "価格",
  lang191: "始値",
  lang192: "方向",
  lang193: "クローズ番号",
  lang194: "利益価格を止める",
  lang195: "収益:",
  lang196: "ストップロス価格",
  lang197: "テイクプロフィット価格を入力してください",
  lang198: "ストップロス価格を入力してください",
  lang199: "レコード",
  lang200: "変更",
  lang201: "ポジションを閉じるには正しい金額を入力してください",
  lang202: "正しいテイクプロフィット価格を入力してください",
  lang203: "テイクプロフィット価格は始値より高くなければなりません",
  lang204: "テイクプロフィット価格は始値より低くなければなりません",
  lang205: "正しいストップロス価格を入力してください",
  lang206: "ストップロス価格は始値より高くなければなりません",
  lang207: "ストップ価格は始値より低くなければなりません",
  lang208: "テイクプロフィット価格またはストップロス価格を入力してください",
  lang209: "あなたが勝つ",
  lang210: "あなたは負ける",
  lang211: "収率",
  lang212: "スワップ",
  lang213: "終値",
  lang214: "追いつく",
  lang215: "超えて",
  lang216: "リーディング",
  lang217: "保存",
  lang218: "画像を正常に保存する",
  lang219: "画像の保存に失敗しました",
  lang220: "購入数量",
  lang221: "購入数量を入力してください",
  lang222: "お支払い方法",
  lang223: "推定支払い額:",
  lang224: "注:最小購入額は{low} {coin}で,最大購入額は{high} {coin}",
  lang225: "支払いに行く",
  lang226: "購入記録",
  lang227: "支払われる",
  lang228: "完了",
  lang229: "キャンセル済み",
  lang230: "正しい購入数量を入力してください",
  lang231: "購入数量を最小購入数量より少なくすることはできません",
  lang232: "購入数量は最大購入数量を超えることはできません",
  lang233: "収益",
  lang234: "利回り",
  lang235: "買う/ロング",
  lang236: "買う/ショート",
  lang237: "位置の順序",
  lang238: "ポジション始値",
  lang239: "現在の価格",
  lang240: "合計金額",
  lang241: "損益",
  lang242: "サイクル",
  lang243: "手数料",
  lang244: "歴史的注文",
  lang245: "実際の終値",
  lang246: "終了時間",
  lang247: "利益注文",
  lang248: "時間",
  lang249: "方向",
  lang250: "利益",
  lang251: "終値",
  lang252: "定期契約",
  lang253: "取引資産",
  lang254: "利用可能な残高",
  lang255: "トランザクション数",
  lang256: "最小{min}{coin}",
  lang257: "期待収益:",
  lang258: "期待収益:",
  lang259: "取引数量を入力してください",
  lang260: "正しい数量を入力してください",
  lang261: "バランスが不十分",
  lang262: "数値は{min}より大きくなければなりません",
  lang263: "数値は{max}未満である必要があります",
  lang264: "WebSocketプッシュデータが異常です。後でもう一度やり直してください！",
  lang265: "WebSocket接続が異常です。後でもう一度やり直してください！",
  lang266: "利益",
  lang267: "損失",
  lang268: "オープンポジション価格:",
  lang269: "注文金額:",
  lang270: "カウントダウン:",
  lang271: "すべての履歴位置",
  lang272: "注文管理",
  lang273: "取引記録",
  lang274: "ボリューム",
  lang275: "入金額",
  lang276: "入金額を入力してください",
  lang277: "入金注文",
  lang278: "入金注文を入力してください",
  lang279: "今日の収益",
  lang280: '総収入',
  lang281: "カード所有者"
};