import _objectSpread from "/Users/mumulei/ideaProject/datagopro/bc.rushorder.client/01_Code/h5/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { getGrabordersList, getRushpurchase, getEarnings as _getEarnings } from '@api/ordersrecord'; // import { Dialog } from 'vant';

export default {
  data: function data() {
    return {
      banner1List: [{
        banner: require('@img/home/graborders_banner_1.jpg')
      }, {
        banner: require('@img/home/graborders_banner_2.jpg')
      }, {
        banner: require('@img/home/graborders_banner_3.jpg')
      }],
      params: {
        page: 1,
        limit: 20,
        viplevel: 4
      },
      viplevParams: {
        page: 1,
        limit: 20
      },
      dialogVisible: false,
      progress: 0,
      timer: null,
      earningsInfo: {},
      goodsList: [{
        imgUrl: require('@img/home/graborders_goods_1.jpg')
      }, {
        imgUrl: require('@img/home/graborders_goods_2.jpg')
      }, {
        imgUrl: require('@img/home/graborders_goods_3.jpg')
      }, {
        imgUrl: require('@img/home/graborders_goods_4.jpg')
      }, {
        imgUrl: require('@img/home/graborders_goods_5.jpg')
      }, {
        imgUrl: require('@img/home/graborders_goods_6.jpg')
      }, {
        imgUrl: require('@img/home/graborders_goods_7.jpg')
      }, {
        imgUrl: require('@img/home/graborders_goods_8.jpg')
      }]
    };
  },
  computed: _objectSpread({}, mapState({
    viplevInfo: function viplevInfo(state) {
      return state.common.viplevInfo;
    },
    exchangeCoinList: function exchangeCoinList(state) {
      return state.wallet.exchangeCoinList[0] || {};
    }
  }), {
    hoverPathStyle: function hoverPathStyle() {
      var circumference = 3140; // 圆的周长

      var offset = circumference - this.progress / 100 * circumference;
      return {
        stroke: 'rgb(225, 159, 106)',
        strokeWidth: '41px',
        strokeDasharray: "".concat(circumference, "px, ").concat(circumference, "px"),
        strokeDashoffset: "".concat(offset, "px")
      };
    }
  }),
  created: function created() {
    this.$store.dispatch('common/showFooter', true);
    this.$store.dispatch('common/updateSelectedFooter', 2);
    this.$store.dispatch('common/getViplevList', this.viplevParams);
    this.$store.dispatch('wallet/getExchangeAssets');
    this.params.viplevel = JSON.parse(localStorage.getItem('userInfo')).minlevel;
    this.getEarnings();
  },
  beforeDestroy: function beforeDestroy() {
    // 组件销毁前清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getEarnings: function getEarnings() {
      var _this = this;

      _getEarnings().then(function (res) {
        _this.earningsInfo = res.data || {};
      });
    },
    showDialog: function showDialog() {
      var _this2 = this;

      this.dialogVisible = true;
      this.progress = 0; // 模拟加载过程

      this.timer = setInterval(function () {
        if (_this2.progress < 100) {
          _this2.progress += 1; // 每次增加1%，您可以根据需要调整这个值
        } else {
          clearInterval(_this2.timer);

          _this2.rushpurchase();
        }
      }, 20); // 每0.1秒更一次进度（100毫秒），您可以根据需要调整这个时间间隔
    },
    rushpurchase: function rushpurchase() {
      var _this3 = this;

      this.dialogVisible = false;
      getRushpurchase().then(function (res) {
        if (res.code == 200) {
          _this3.$router.push({
            name: 'ordersrecord'
          });
        }
      });
    },
    onConfirm: function onConfirm() {
      clearInterval(this.timer);
      this.dialogVisible = false;
    },
    onCancel: function onCancel() {
      this.dialogVisible = false; // 用户点击取消按钮后的逻辑

      clearInterval(this.timer); // 清除定时器，防止内存泄漏
    }
  }
};